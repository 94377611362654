import React, { useState, useEffect } from 'react';
import { Badge } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  generateShareToken,
  getShareLink,
  deleteShareToken,
} from '../../services/sharedSession';
import WundaIconButton from './WundaIconButton';
import PropTypes from 'prop-types';
import GenericSnackbar from '../snackbar/GenericSnackbar';
import WundaShareDialog from './WundaShareDialog';
import { analytics } from '../../firebase/firebase';
import { logEvent } from 'firebase/analytics';

const getRemainingDays = (expiresAt) => {
  if (!expiresAt) return null;
  const now = new Date();
  const expiry = new Date(expiresAt);
  const diffTime = expiry - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const isLinkExpired = (expiresAt) => {
  if (!expiresAt) return false;
  const now = new Date();
  const expiry = new Date(expiresAt);
  return now >= expiry;
};

const ShareSessionButton = ({
  sessionId,
  disabled = false,
  shareToken = null,
  onShareStatusChange = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [shareLink, setShareLink] = useState(
    shareToken?.token ? getShareLink(shareToken.token) : ''
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  useEffect(() => {
    if (shareToken?.token) {
      setShareLink(getShareLink(shareToken.token));
    } else {
      setShareLink('');
    }
  }, [shareToken]);

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleShare = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnshare = async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteShareToken(sessionId);
      setShareLink('');
      showSuccess('Session sharing disabled successfully');
      logEvent(analytics, 'unshare_session');
      onShareStatusChange();
      handleClose();
    } catch (error) {
      setError(error.message);
      console.error('Error disabling share:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewLink = async (expirationDays, shareOptions) => {
    setLoading(true);
    setError(null);
    try {
      const response = await generateShareToken({
        sessionId,
        expiresInDays: expirationDays,
        ...shareOptions,
      });
      const link = getShareLink(response.token);
      setShareLink(link);
      onShareStatusChange(response);
      logEvent(analytics, 'share_session', {
        method: 'regenerate_link',
        expirationDays,
        ...shareOptions,
      });
      setSnackbar({
        open: true,
        message: 'New share link generated successfully',
        severity: 'success',
      });
    } catch (error) {
      setError(error.message);
      console.error('Error generating share link:', error);
    } finally {
      setLoading(false);
    }
  };

  const linkShareInfo = [
    {
      icon: <LockIcon fontSize="small" />,
      primary: 'Secure Access',
      secondary: 'Only people with the link can access this session',
    },
    {
      icon: <AccessTimeIcon fontSize="small" />,
      primary: 'Limited Time',
      secondary: shareToken?.token
        ? shareToken.expiresAt
          ? `Link expires in ${getRemainingDays(shareToken.expiresAt)} days`
          : 'Link never expires'
        : 'Link expires after 7 days',
    },
  ];

  return (
    <>
      <span>
        <Badge
          color="primary"
          variant="dot"
          invisible={!shareToken?.token}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <WundaIconButton
            icon="eva:share-outline"
            onClick={handleShare}
            tooltip={
              disabled
                ? 'Share (Disabled)'
                : shareToken?.token
                  ? 'Session is currently shared'
                  : 'Share session'
            }
            tooltipPlacement="top"
            disabled={disabled}
            data-fs-event="share_session"
            data-fs-event-method="generate_link"
            data-fs-event-session-id={sessionId}
          />
        </Badge>
      </span>

      <WundaShareDialog
        open={open}
        onClose={handleClose}
        title="Share Session"
        showLinkShare={true}
        currentLink={shareLink}
        isLoading={loading}
        error={error}
        shareToken={shareToken}
        onGenerateLink={handleGenerateNewLink}
        showWarning="When sharing a session all reports that have been generated will be accessible to viewer!"
        linkShareProps={{
          onDisableShare: handleUnshare,
          showDisableShare: !!shareLink,
          infoList: linkShareInfo,
          generateNewLinkLabel: shareLink
            ? 'Generate New Link'
            : 'Generate Link',
          disableShareLabel: 'Disable Sharing',
          isExpired: isLinkExpired(shareToken?.expiresAt),
          expiredMessage:
            'This share link has expired. Please generate a new link.',
        }}
      />

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar({ open: false, message: '', severity: 'info' })
        }
      />
    </>
  );
};

ShareSessionButton.propTypes = {
  sessionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  shareToken: PropTypes.shape({
    token: PropTypes.string,
    expiresAt: PropTypes.string,
    chatEnabled: PropTypes.bool,
    isPermanent: PropTypes.bool,
  }),
  onShareStatusChange: PropTypes.func,
};

export default ShareSessionButton;
