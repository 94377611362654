import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { alpha } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import Label from '../label';
import { apiRequest } from '../../api/api';
import Iconify from '../iconify';

const StatusProgressLabel = ({
  status,
  url,
  onStatusChange,
  onReady,
  showError,
  pollingStatuses = [
    'PENDING',
    'PROCESSING',
    'TRANSCRIBING',
    'GENERATING_INSIGHTS',
    'IN_PROGRESS',
  ],
  pollingInterval = 30,
}) => {
  const [internalStatus, setInternalStatus] = useState(status);
  const [internalError, setInternalError] = useState(showError);
  const [internalQueue, setInternalQueue] = useState(0);
  const prevStatusRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    prevStatusRef.current = internalStatus; // update the ref with the current status after each render
  });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await apiRequest('GET', url);
        setInternalStatus(response.status);
        setInternalQueue(response.queue || 0);
        setInternalError(response.lastError || showError);

        if (onStatusChange) {
          onStatusChange(response.status); // update the status in the parent component if provided
        }

        // if the previous status was a polling status and the current status is 'READY', call the onReady callback
        if (
          pollingStatuses.includes(prevStatusRef.current) &&
          (response.status === 'READY' ||
            response.status === 'COMPLETED' ||
            response.status === 'FAILED')
        ) {
          if (onReady) {
            onReady();
          }
        }
      } catch (error) {
        console.error('Error fetching status:', error.message);
      }
    };

    const shouldFetchStatus = pollingStatuses.includes(internalStatus);
    if (shouldFetchStatus) {
      const timer = setInterval(fetchStatus, pollingInterval * 1000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [
    url,
    internalStatus,
    onStatusChange,
    pollingStatuses,
    pollingInterval,
    onReady,
    showError,
  ]);

  return (
    <Box gap={1}>
      <Tooltip
        title={
          internalStatus !== 'READY' && internalStatus !== 'FAILED'
            ? `Position in queue: ${internalQueue + 1}`
            : ''
        }
      >
        <Label
          style={{
            color: theme.palette.text.primary,
            backgroundColor:
              internalStatus === 'FAILED'
                ? theme.palette.background.PinkBG
                : internalStatus === 'READY' ||
                    internalStatus === 'COMPLETED' ||
                    internalStatus === 'UPLOADED'
                  ? alpha(theme.palette.secondary.main, 0.5)
                  : internalStatus === 'PENDING'
                    ? theme.palette.background.GreyBG02
                    : theme.palette.background.YellowBG,
          }}
        >
          {sentenceCase(internalStatus)}
          {internalStatus !== 'READY' &&
          internalStatus !== 'COMPLETED' &&
          internalStatus !== 'UPLOADED' &&
          internalStatus !== 'FAILED' ? (
            <Box ml={1}>
              <CircularProgress
                size={12}
                sx={{ color: theme.palette.primary.main }}
              />
            </Box>
          ) : null}
          {internalError &&
            process.env.REACT_APP_ERROR_INFO_ENABLED === 'true' && (
              <Tooltip title={internalError} placement="top">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ maxWidth: '200px', wordWrap: 'break-word' }}
                >
                  <Iconify icon={'mdi:info'} sx={{ ml: 0.2 }} />
                </Box>
              </Tooltip>
            )}
        </Label>
      </Tooltip>
    </Box>
  );
};

StatusProgressLabel.propTypes = {
  status: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func,
  onReady: PropTypes.func,
  showError: PropTypes.string,
  pollingStatuses: PropTypes.arrayOf(PropTypes.string),
  pollingInterval: PropTypes.number,
};

export default StatusProgressLabel;
