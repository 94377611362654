import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Stack,
  Typography,
  Box,
  Link,
  Alert,
  IconButton,
  MenuItem,
  Switch,
  FormControlLabel,
  Chip,
  Menu,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Icon as IconifyIcon } from '@iconify/react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import templateService from '../../services/templateService';
import themeService from '../../services/themeService';
import WundaButton from '../../components/wundamental/WundaButton';
import PromptDialog from '../../components/dialog/PromptDialog';
import TextEditorDialog from '../../components/common/TextEditorDialog';
import GenericSnackbar from '../../components/snackbar/GenericSnackbar';

export default function TemplatePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    themeID: '',
    name: '',
    headerTitle: '',
    nurturerRole: '',
    flourisherRole: '',
    targetedAt: 'nurturer',
    label: '',
    labelShort: '',
    ctaTitle: '',
    ctaBody: '',
    icon: '',
    disclaimerBody: '',
    templateUrl: '',
    systemPrompt: '',
    refinePrompt: '',
    format: 'text',
    public: false,
    published: false,
    active: true,
    partOfResearch: false,
    cohortAnalysis: false,
    chainAnalysis: false,
    JSONOutputMapping: {
      titleField: '',
      descriptionField: '',
      sectionsField: '',
      sectionTitleField: '',
      itemsMapping: [],
    },
  });
  const [themes, setThemes] = useState([]);
  const [templateSections, setTemplateSections] = useState([]);
  const [errors, setErrors] = useState({});
  const [iconError, setIconError] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSystemPromptEditor, setOpenSystemPromptEditor] = useState(false);
  const [openRefinePromptEditor, setOpenRefinePromptEditor] = useState(false);
  const [openUserPromptEditor, setOpenUserPromptEditor] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [error, setError] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteSectionDialog, setOpenDeleteSectionDialog] = useState(false);
  const [openNewSectionDialog, setOpenNewSectionDialog] = useState(false);
  const [newSectionData, setNewSectionData] = useState({
    heading: '',
    userPrompt: '',
    model: 'gpt-4',
    max_tokens: 200,
    temperature: 0.2,
  });

  const getErrorMessage = (error) => {
    if (error.response?.data?.error) {
      return error.response.data.error;
    }
    return error.message || 'An unexpected error occurred';
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
    setTimeout(handleSnackbarClose, 6000);
  };

  const showError = (message) => {
    setError(message);
  };

  const resetError = () => {
    setError('');
  };

  const handleIconError = () => {
    setIconError(true);
  };

  const handleInputChange = (field) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    if (field === 'icon') {
      setIconError(false);
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: '' }));
    }
    setHasChanges(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      resetError();
      try {
        const [themesResponse] = await Promise.all([
          themeService.getAllThemes(),
        ]);

        setThemes(themesResponse || []);

        if (id !== 'new') {
          const response = await templateService.getTemplateById(id);
          if (response) {
            const { template, templateSections = [] } = response;
            setTemplateSections(templateSections);
            if (template) {
              setFormData({
                themeID: template.themeID || '',
                name: template.name || '',
                headerTitle: template.headerTitle || '',
                nurturerRole: template.nurturerRole || '',
                flourisherRole: template.flourisherRole || '',
                targetedAt: template.targetedAt || 'nurturer',
                label: template.label || '',
                labelShort: template.labelShort || '',
                ctaTitle: template.ctaTitle || '',
                ctaBody: template.ctaBody || '',
                icon: template.icon || '',
                disclaimerBody: template.disclaimerBody || '',
                templateUrl: template.templateUrl || '',
                systemPrompt: template.systemPrompt || '',
                refinePrompt: template.refinePrompt || '',
                format: template.format || 'text',
                public: template.public || false,
                published: template.published || false,
                active: template.active ?? true,
                partOfResearch: template.partOfResearch || false,
                cohortAnalysis: template.cohortAnalysis || false,
                chainAnalysis: template.chainAnalysis || false,
                JSONOutputMapping: template.JSONOutputMapping || {
                  titleField: '',
                  descriptionField: '',
                  sectionsField: '',
                  sectionTitleField: '',
                  itemsMapping: [],
                },
              });
            }
          }
        }
      } catch (error) {
        showError(getErrorMessage(error));
      }
    };

    fetchData();
  }, [id]);

  const handleEditUserPrompt = (section) => {
    setSelectedSection(section);
    setOpenUserPromptEditor(true);
  };

  const handleSaveUserPrompt = async (newValue) => {
    if (!selectedSection) return;

    const updatedSections = templateSections.map((section) =>
      section._id === selectedSection._id
        ? { ...section, userPrompt: newValue }
        : section
    );

    try {
      await templateService.updateTemplate(id, formData, updatedSections);
      setTemplateSections(updatedSections);
      showSuccess('Template section prompt updated successfully');
      setHasChanges(false); // Reset changes since we've saved successfully
    } catch (error) {
      showError(getErrorMessage(error));
      setHasChanges(true); // Only set changes flag on error
    } finally {
      setSelectedSection(null);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.icon.trim()) newErrors.icon = 'Icon is required';
    if (!formData.headerTitle.trim())
      newErrors.headerTitle = 'Header title is required';
    if (!formData.nurturerRole.trim())
      newErrors.nurturerRole = 'Nurturer role is required';
    if (!formData.flourisherRole.trim())
      newErrors.flourisherRole = 'Flourisher role is required';
    if (!formData.label.trim()) newErrors.label = 'Label is required';
    if (!formData.labelShort.trim())
      newErrors.labelShort = 'Short label is required';
    if (formData.labelShort.length > 16)
      newErrors.labelShort = 'Short label must be 16 characters or less';
    if (!formData.ctaTitle.trim()) newErrors.ctaTitle = 'CTA title is required';
    if (!formData.ctaBody.trim()) newErrors.ctaBody = 'CTA body is required';
    if (!formData.systemPrompt.trim())
      newErrors.systemPrompt = 'System prompt is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const templateData = {
        ...formData,
      };

      if (id === 'new') {
        await templateService.createTemplate(templateData);
        navigate('/home/wundamental/templates', {
          state: {
            snackbar: {
              open: true,
              message: `Template "${templateData.name}" created successfully`,
              severity: 'success',
            },
          },
        });
      } else {
        await templateService.updateTemplate(
          id,
          templateData,
          templateSections
        );
        showSuccess(`Template "${formData.name}" updated successfully`);
        setHasChanges(false);
      }
    } catch (error) {
      showError(getErrorMessage(error));
    }
  };

  const handleDelete = async () => {
    try {
      await templateService.deleteTemplate(id);
      setOpenDeleteDialog(false);
      navigate('/home/wundamental/templates', {
        state: {
          snackbar: {
            open: true,
            message: 'Template deleted successfully',
            severity: 'success',
          },
        },
      });
    } catch (error) {
      showError(getErrorMessage(error));
      setOpenDeleteDialog(false);
    }
  };

  const handleSystemPromptSave = (content) => {
    setFormData((prev) => ({ ...prev, systemPrompt: content }));
    setOpenSystemPromptEditor(false);
    setHasChanges(true);
  };

  const handleRefinePromptSave = (content) => {
    setFormData((prev) => ({ ...prev, refinePrompt: content }));
    setOpenRefinePromptEditor(false);
    setHasChanges(true);
  };

  const handleStartEdit = (section, field, value) => {
    setEditingSection(section);
    setEditingField(field);
    setEditValue(value.toString());
  };

  const handleSectionFieldChange = async (newValue) => {
    if (!editingSection || !editingField) return;

    const value =
      editingField === 'temperature'
        ? parseFloat(newValue)
        : editingField === 'max_tokens'
          ? parseInt(newValue, 10)
          : newValue;

    const updatedSections = templateSections.map((section) =>
      section._id === editingSection._id
        ? { ...section, [editingField]: value }
        : section
    );

    try {
      await templateService.updateTemplate(id, formData, updatedSections);
      setTemplateSections(updatedSections);
      showSuccess(`Template section ${editingField} updated successfully`);
      setHasChanges(false); // Reset changes since we've saved successfully
    } catch (error) {
      showError(getErrorMessage(error));
      setHasChanges(true); // Only set changes flag on error
    } finally {
      setEditingField(null);
      setEditingSection(null);
    }
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.setData('text/plain', index);
    e.currentTarget.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    setDraggedIndex(null);
    setDragOverIndex(null);
    e.currentTarget.style.opacity = '1';
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleDrop = async (e, dropIndex) => {
    e.preventDefault();
    setDraggedIndex(null);
    setDragOverIndex(null);
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    if (dragIndex === dropIndex) return;

    const items = Array.from(templateSections);
    const [reorderedItem] = items.splice(dragIndex, 1);
    items.splice(dropIndex, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index,
    }));

    setTemplateSections(updatedItems);
    try {
      await templateService.updateTemplate(id, formData, updatedItems);
      showSuccess('Section order updated successfully');
    } catch (error) {
      showError(getErrorMessage(error));
      setTemplateSections(templateSections);
    }
  };

  const handleBackClick = () => {
    if (hasChanges) {
      setOpenUnsavedChangesDialog(true);
    } else {
      navigate('/home/wundamental/templates');
    }
  };

  const handleDiscardChanges = () => {
    setOpenUnsavedChangesDialog(false);
    navigate('/home/wundamental/templates');
  };

  const handleImportClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileImport = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          const safeJSONOutputMapping = {
            titleField: '',
            descriptionField: '',
            sectionsField: '',
            sectionTitleField: '',
            itemsMapping: [],
            additionalInfoMappings: [],
            ...jsonData.JSONOutputMapping,
          };
          const importedTemplate =
            await templateService.importTemplate(jsonData);

          // Update form data with default values for controlled inputs
          setFormData({
            ...importedTemplate,
            partOfResearch: importedTemplate.partOfResearch || false,
            cohortAnalysis: importedTemplate.cohortAnalysis || false,
            chainAnalysis: importedTemplate.chainAnalysis || false,
            public: importedTemplate.public || false,
            published: importedTemplate.published || false,
            active:
              importedTemplate.active !== undefined
                ? importedTemplate.active
                : true,
            JSONOutputMapping: safeJSONOutputMapping,
          });

          // Update template sections with proper IDs
          if (importedTemplate.templateSections) {
            setTemplateSections(
              importedTemplate.templateSections.map((section) => ({
                ...section,
                _id: section._id || `temp_${Date.now()}_${Math.random()}`,
              }))
            );
          }

          // Mark as having changes
          setHasChanges(true);

          setSnackbar({
            open: true,
            message: 'Template imported successfully',
            severity: 'success',
          });
        } catch (error) {
          console.error('Import error:', error);
          setSnackbar({
            open: true,
            message: error.message || 'Failed to import template',
            severity: 'error',
          });
        }
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('File read error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to read file',
        severity: 'error',
      });
    }
    // Reset file input
    event.target.value = '';
  };

  const handleMenuOpen = (event, section) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSection(section);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloneSection = async () => {
    handleMenuClose();
    if (!selectedSection) return;

    const sectionIndex = templateSections.findIndex(
      (s) => s._id === selectedSection._id
    );
    const newSection = {
      ...selectedSection,
      _id: `temp_${Date.now()}_${Math.random()}`,
      heading: `${selectedSection.heading} (Copy)`,
      order: selectedSection.order + 1,
    };

    const updatedSections = [...templateSections];
    updatedSections.splice(sectionIndex + 1, 0, newSection);

    // Update order for all sections after the cloned one
    for (let i = sectionIndex + 2; i < updatedSections.length; i++) {
      updatedSections[i].order = i;
    }

    try {
      await templateService.updateTemplate(id, formData, updatedSections);
      setTemplateSections(updatedSections);
      showSuccess('Template section cloned successfully');
    } catch (error) {
      showError(getErrorMessage(error));
    }
    setSelectedSection(null);
  };

  const handleDeleteSectionConfirm = async () => {
    if (!selectedSection) {
      setOpenDeleteSectionDialog(false);
      return;
    }

    const updatedSections = templateSections
      .filter((s) => s._id !== selectedSection._id)
      .map((section, index) => ({
        ...section,
        order: index,
      }));

    try {
      await templateService.updateTemplate(id, formData, updatedSections);
      setTemplateSections(updatedSections);
      setOpenDeleteSectionDialog(false);
      setSelectedSection(null);
      setHasChanges(false);
      showSuccess('Section deleted');
    } catch (error) {
      showError(getErrorMessage(error));
    }
  };

  const handleDeleteSectionClick = () => {
    handleMenuClose();
    setOpenDeleteSectionDialog(true);
  };

  const handleAddSection = () => {
    setNewSectionData({
      heading: '',
      userPrompt: '',
      model: 'gpt-4o',
      max_tokens: 200,
      temperature: 0.2,
    });
    setOpenNewSectionDialog(true);
  };

  const handleSaveNewSection = async () => {
    // Validate required fields
    if (!newSectionData.heading || !newSectionData.userPrompt) {
      showError('Heading and User Prompt are required');
      return;
    }

    const newSection = {
      _id: `temp_${Date.now()}_${Math.random()}`,
      heading: newSectionData.heading,
      userPrompt: newSectionData.userPrompt,
      model: newSectionData.model,
      max_tokens: newSectionData.max_tokens,
      temperature: newSectionData.temperature,
      order: templateSections.length,
    };

    const updatedSections = [...templateSections, newSection];

    try {
      await templateService.updateTemplate(id, formData, updatedSections);
      setTemplateSections(updatedSections);
      setOpenNewSectionDialog(false);
      showSuccess('New section added successfully');
    } catch (error) {
      showError(getErrorMessage(error));
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 5 }}>
        <Stack direction="row" alignItems="center">
          <Link
            component="button"
            variant="subtitle2"
            onClick={handleBackClick}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <NavigateNextIcon sx={{ transform: 'rotate(180deg)' }} />
            Back to Templates
          </Link>
        </Stack>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".json"
        onChange={handleFileImport}
      />

      <Grid container spacing={3}>
        {/* Left side - Form */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent sx={{ pt: 3, pb: 3 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                {id === 'new' && (
                  <Tooltip title="Import Template">
                    <IconButton onClick={handleImportClick}>
                      <IconifyIcon
                        icon="mdi:file-import"
                        width={24}
                        height={24}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>

              <Grid container spacing={3}>
                {formData.icon && (
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconifyIcon
                      icon={formData.icon}
                      width={64}
                      height={64}
                      onError={handleIconError}
                    />
                  </Grid>
                )}
                <Grid item xs={formData.icon ? 10 : 12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={formData.name}
                        onChange={handleInputChange('name')}
                        error={!!errors.name}
                        helperText={errors.name}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Iconify Key"
                        value={formData.icon}
                        onChange={handleInputChange('icon')}
                        error={!!errors.icon || iconError}
                        helperText={
                          errors.icon ||
                          (iconError ? 'Invalid Iconify icon key' : '')
                        }
                        required
                        placeholder="e.g., mdi:theme-light-dark"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Theme"
                    value={formData.themeID}
                    onChange={handleInputChange('themeID')}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {themes.map((theme) => (
                      <MenuItem key={theme._id} value={theme._id}>
                        {theme.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Header Title"
                    value={formData.headerTitle}
                    onChange={handleInputChange('headerTitle')}
                    error={!!errors.headerTitle}
                    helperText={errors.headerTitle}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nurturer Role"
                    value={formData.nurturerRole}
                    onChange={handleInputChange('nurturerRole')}
                    error={!!errors.nurturerRole}
                    helperText={errors.nurturerRole}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Flourisher Role"
                    value={formData.flourisherRole}
                    onChange={handleInputChange('flourisherRole')}
                    error={!!errors.flourisherRole}
                    helperText={errors.flourisherRole}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Targeted At"
                    value={formData.targetedAt}
                    onChange={handleInputChange('targetedAt')}
                    error={!!errors.targetedAt}
                    helperText={errors.targetedAt}
                  >
                    <MenuItem value="nurturer">Nurturer</MenuItem>
                    <MenuItem value="flourisher">Flourisher</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Format"
                    value={formData.format}
                    onChange={handleInputChange('format')}
                  >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="json">JSON</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: 1,
                      borderColor: 'divider',
                      p: 2,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      UI Labels
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Label"
                          value={formData.label}
                          onChange={handleInputChange('label')}
                          error={!!errors.label}
                          helperText={errors.label}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Short Label"
                          value={formData.labelShort}
                          onChange={handleInputChange('labelShort')}
                          error={!!errors.labelShort}
                          helperText={errors.labelShort}
                          required
                          inputProps={{ maxLength: 16 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="CTA Title"
                          value={formData.ctaTitle}
                          onChange={handleInputChange('ctaTitle')}
                          error={!!errors.ctaTitle}
                          helperText={errors.ctaTitle}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="CTA Body"
                          value={formData.ctaBody}
                          onChange={handleInputChange('ctaBody')}
                          error={!!errors.ctaBody}
                          helperText={errors.ctaBody}
                          required
                          multiline
                          rows={3}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="System Prompt"
                    value={formData.systemPrompt}
                    onChange={handleInputChange('systemPrompt')}
                    onClick={() => setOpenSystemPromptEditor(true)}
                    error={!!errors.systemPrompt}
                    helperText={errors.systemPrompt}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Refine Prompt"
                    value={formData.refinePrompt}
                    onChange={handleInputChange('refinePrompt')}
                    onClick={() => setOpenRefinePromptEditor(true)}
                    error={!!errors.refinePrompt}
                    helperText={errors.refinePrompt}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Disclaimer Body"
                    value={formData.disclaimerBody}
                    onChange={handleInputChange('disclaimerBody')}
                  />
                </Grid>

                {formData.format === 'json' && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      JSON Output Mapping
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Title Field"
                          value={formData.JSONOutputMapping.titleField}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              JSONOutputMapping: {
                                ...prev.JSONOutputMapping,
                                titleField: e.target.value,
                              },
                            }));
                            setHasChanges(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Description Field"
                          value={formData.JSONOutputMapping.descriptionField}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              JSONOutputMapping: {
                                ...prev.JSONOutputMapping,
                                descriptionField: e.target.value,
                              },
                            }));
                            setHasChanges(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Sections Field"
                          value={formData.JSONOutputMapping.sectionsField}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              JSONOutputMapping: {
                                ...prev.JSONOutputMapping,
                                sectionsField: e.target.value,
                              },
                            }));
                            setHasChanges(true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Section Title Field"
                          value={formData.JSONOutputMapping.sectionTitleField}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              JSONOutputMapping: {
                                ...prev.JSONOutputMapping,
                                sectionTitleField: e.target.value,
                              },
                            }));
                            setHasChanges(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.public}
                          onChange={handleInputChange('public')}
                        />
                      }
                      label="Public Template"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.published}
                          onChange={handleInputChange('published')}
                        />
                      }
                      label="Published"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.active}
                          onChange={handleInputChange('active')}
                        />
                      }
                      label="Active"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.partOfResearch}
                          onChange={handleInputChange('partOfResearch')}
                        />
                      }
                      label="Part of Research"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.cohortAnalysis}
                          onChange={handleInputChange('cohortAnalysis')}
                        />
                      }
                      label="Cohort Analysis"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.chainAnalysis}
                          onChange={handleInputChange('chainAnalysis')}
                        />
                      }
                      label="Chain Analysis"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
            <Box
              sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}
            >
              {id !== 'new' && (
                <WundaButton
                  onClick={() => setOpenDeleteDialog(true)}
                  color="error"
                  variant="outlined"
                >
                  Delete Template
                </WundaButton>
              )}
              <Box
                sx={{
                  flex: '1 1 auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {hasChanges && id !== 'new' && (
                    <Chip
                      label="Unsaved changes"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  )}
                  <WundaButton
                    onClick={handleSave}
                    variant="contained"
                    disabled={
                      id !== 'new' &&
                      (!hasChanges || Object.keys(errors).length > 0)
                    }
                  >
                    {id === 'new' ? 'Create Template' : 'Save Template'}
                  </WundaButton>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Right side - Template Sections */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography variant="h6">Template Sections</Typography>
                {id !== 'new' && (
                  <Tooltip title="Add new section">
                    <IconButton
                      onClick={handleAddSection}
                      size="small"
                      sx={{
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                      }}
                    >
                      <IconifyIcon icon="mdi:plus" width={20} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Stack spacing={2}>
                {templateSections
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((section, index) => (
                    <Card
                      key={section._id}
                      sx={{
                        p: 2,
                        cursor: 'move',
                        position: 'relative',
                        transform:
                          draggedIndex === index ? 'scale(1.02)' : 'scale(1)',
                        opacity: draggedIndex === index ? 0.4 : 1,
                        '&:hover': {
                          boxShadow: (theme) => theme.shadows[4],
                        },
                        '&::after':
                          dragOverIndex === index
                            ? {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: dragOverIndex > draggedIndex ? 'auto' : 0,
                                bottom:
                                  dragOverIndex > draggedIndex ? 0 : 'auto',
                                height: '4px',
                                backgroundColor: 'primary.main',
                                borderRadius: '2px',
                              }
                            : {},
                      }}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragEnd={handleDragEnd}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <Stack spacing={2}>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <DragIndicatorIcon
                            sx={{
                              color: 'text.secondary',
                              cursor: 'grab',
                              '&:active': {
                                cursor: 'grabbing',
                              },
                            }}
                          />
                          {editingSection?._id === section._id &&
                          editingField === 'heading' ? (
                            <TextField
                              size="small"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              onBlur={() => handleSectionFieldChange(editValue)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSectionFieldChange(editValue);
                                }
                              }}
                              autoFocus
                              sx={{ width: '100%' }}
                              inputProps={{
                                style: { fontWeight: 'bold' },
                              }}
                            />
                          ) : (
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              onClick={() =>
                                handleStartEdit(
                                  section,
                                  'heading',
                                  section.heading
                                )
                              }
                              sx={{
                                cursor: 'pointer',
                                flexGrow: 1,
                                '&:hover': {
                                  backgroundColor: (theme) =>
                                    theme.palette.action.hover,
                                  borderRadius: 1,
                                },
                                p: 0.5,
                              }}
                            >
                              {section.heading}
                            </Typography>
                          )}
                          <IconButton
                            size="small"
                            onClick={(e) => handleMenuOpen(e, section)}
                            sx={{
                              ml: 'auto',
                              '&:hover': {
                                backgroundColor: 'action.hover',
                              },
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(
                              anchorEl && selectedSection?._id === section._id
                            )}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: 'visible',
                                filter:
                                  'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={handleCloneSection}>
                              <IconifyIcon
                                icon="mdi:content-copy"
                                width={20}
                                style={{ marginRight: 8 }}
                              />
                              Clone
                            </MenuItem>
                            <MenuItem
                              onClick={handleDeleteSectionClick}
                              sx={{ color: 'error.main' }}
                            >
                              <IconifyIcon
                                icon="mdi:delete"
                                width={20}
                                style={{ marginRight: 8 }}
                              />
                              Delete
                            </MenuItem>
                          </Menu>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            pl: 4,
                            flexWrap: 'wrap',
                            '& .MuiChip-root': {
                              maxWidth: '100%',
                              flexShrink: 1,
                            },
                          }}
                        >
                          {editingSection?._id === section._id &&
                          editingField === 'model' ? (
                            <TextField
                              size="small"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              onBlur={() => handleSectionFieldChange(editValue)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSectionFieldChange(editValue);
                                }
                              }}
                              autoFocus
                              sx={{ width: '150px' }}
                            />
                          ) : (
                            <Chip
                              label={`Model: ${section.model}`}
                              size="small"
                              onClick={() =>
                                handleStartEdit(section, 'model', section.model)
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                          {editingSection?._id === section._id &&
                          editingField === 'max_tokens' ? (
                            <TextField
                              size="small"
                              type="number"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              onBlur={() => handleSectionFieldChange(editValue)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSectionFieldChange(editValue);
                                }
                              }}
                              autoFocus
                              sx={{ width: '100px' }}
                            />
                          ) : (
                            <Chip
                              label={`Max Tokens: ${section.max_tokens}`}
                              size="small"
                              onClick={() =>
                                handleStartEdit(
                                  section,
                                  'max_tokens',
                                  section.max_tokens
                                )
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                          {editingSection?._id === section._id &&
                          editingField === 'temperature' ? (
                            <TextField
                              size="small"
                              type="number"
                              inputProps={{ step: 0.1, min: 0, max: 2 }}
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              onBlur={() => handleSectionFieldChange(editValue)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSectionFieldChange(editValue);
                                }
                              }}
                              autoFocus
                              sx={{ width: '100px' }}
                            />
                          ) : (
                            <Chip
                              label={`Temperature: ${section.temperature}`}
                              size="small"
                              onClick={() =>
                                handleStartEdit(
                                  section,
                                  'temperature',
                                  section.temperature
                                )
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                        </Box>
                        <Box sx={{ pl: 4, display: 'flex', gap: 2 }}>
                          <WundaButton
                            onClick={() => handleEditUserPrompt(section)}
                            startIcon={<IconifyIcon icon="mdi:pencil" />}
                          >
                            Prompt
                          </WundaButton>
                        </Box>
                      </Stack>
                    </Card>
                  ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <PromptDialog
        open={openDeleteDialog}
        title="Delete Template"
        message="Are you sure you want to delete this template? This action cannot be undone."
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteDialog(false)}
      />

      <TextEditorDialog
        open={openSystemPromptEditor}
        onClose={() => setOpenSystemPromptEditor(false)}
        onSave={handleSystemPromptSave}
        initialValue={formData.systemPrompt}
        title="System Prompt"
        templateId={id}
      />

      <TextEditorDialog
        open={openRefinePromptEditor}
        onClose={() => setOpenRefinePromptEditor(false)}
        onSave={handleRefinePromptSave}
        initialValue={formData.refinePrompt}
        title="Refine Prompt"
        templateId={id}
      />

      <TextEditorDialog
        open={openUserPromptEditor}
        onClose={() => setOpenUserPromptEditor(false)}
        onSave={handleSaveUserPrompt}
        title="Edit User Prompt"
        initialValue={selectedSection?.userPrompt || ''}
        templateId={id}
        templateSectionId={selectedSection?._id || ''}
      />

      <PromptDialog
        open={openUnsavedChangesDialog}
        title="Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to leave? Your changes will be lost."
        onCancel={() => setOpenUnsavedChangesDialog(false)}
        onConfirm={handleDiscardChanges}
        confirmButtonLabel="Discard Changes"
      />

      <PromptDialog
        open={openDeleteSectionDialog}
        title="Delete Section"
        message="Are you sure you want to delete this section? This action cannot be undone."
        onCancel={() => {
          setOpenDeleteSectionDialog(false);
          setSelectedSection(null);
        }}
        onConfirm={handleDeleteSectionConfirm}
        confirmButtonLabel="Delete"
      />

      <Dialog
        open={openNewSectionDialog}
        onClose={() => setOpenNewSectionDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Section</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Heading"
              value={newSectionData.heading}
              onChange={(e) =>
                setNewSectionData((prev) => ({
                  ...prev,
                  heading: e.target.value,
                }))
              }
              required
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="User Prompt"
              value={newSectionData.userPrompt}
              onChange={(e) =>
                setNewSectionData((prev) => ({
                  ...prev,
                  userPrompt: e.target.value,
                }))
              }
              required
            />
            <TextField
              fullWidth
              label="Model"
              value={newSectionData.model}
              onChange={(e) =>
                setNewSectionData((prev) => ({
                  ...prev,
                  model: e.target.value,
                }))
              }
              required
            />
            <TextField
              fullWidth
              type="number"
              label="Max Tokens"
              value={newSectionData.max_tokens}
              onChange={(e) =>
                setNewSectionData((prev) => ({
                  ...prev,
                  max_tokens: parseInt(e.target.value, 10),
                }))
              }
              inputProps={{ min: 1 }}
              required
            />
            <TextField
              fullWidth
              type="number"
              label="Temperature"
              value={newSectionData.temperature}
              onChange={(e) =>
                setNewSectionData((prev) => ({
                  ...prev,
                  temperature: parseFloat(e.target.value),
                }))
              }
              inputProps={{ step: 0.1, min: 0, max: 2 }}
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <WundaButton
            onClick={() => setOpenNewSectionDialog(false)}
            variant="outlined"
          >
            Cancel
          </WundaButton>
          <WundaButton onClick={handleSaveNewSection} variant="contained">
            Add Section
          </WundaButton>
        </DialogActions>
      </Dialog>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
}
