import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, useMediaQuery, useTheme } from '@mui/material';
import WundaIconButton from './WundaIconButton';

const WundaModal = ({ open, onClose, children, showCloseButton = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {showCloseButton && (
          <WundaIconButton
            icon="mdi:close"
            onClick={onClose}
            tooltip="Close"
            sx={{ position: 'absolute', right: 8, top: 8 }}
          />
        )}
        {children}
      </Box>
    </Modal>
  );
};

WundaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showCloseButton: PropTypes.bool,
};

export default WundaModal;
