import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Alert,
  Chip,
  Tooltip,
} from '@mui/material';
// components
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import ReportEditForm from '../sections/@dashboard/report/ReportEditForm';
import DownloadInsightsFormatDialog from '../components/wundamental/DownloadInsightsFormatDialog';
import WundaShareToEmailDialog from '../components/wundamental/WundaShareToEmailDialog';
import PromptDialog from '../components/dialog/PromptDialog';
import StatusProgressLabel from '../components/wundamental/StatusProgressLabel';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import GenericLoader from '../components/loader/GenericLoader';
import WundaButton from '../components/wundamental/WundaButton';
import { AuthContext } from '../providers/AuthProvider';
// sections
import {
  ReportListToolbar,
  ReportListHead,
} from '../sections/@dashboard/report';
import { apiRequest } from '../api/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sessionName', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'generatedAt', label: 'Generated At', alignRight: false },
  { id: 'templateName', label: 'Template', alignRight: false },
  { id: 'tags', label: 'Tags', alignRight: false },
  { id: 'indicators', label: '', alignRight: false },
];
const FILTER_ENABLED = true;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (item) =>
        item.sessionName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.tags &&
          item.tags.some(
            (tag) => tag.toLowerCase().indexOf(query.toLowerCase()) !== -1
          ))
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReportsPage() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { currentUser, setAdvancedBalanceMode, refreshBalance } =
    useContext(AuthContext);
  const snackbarRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('generatedAt');

  const [filterName, setFilterName] = useState('');

  const [showSessionRecords, setShowSessionRecords] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [reports, setReports] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  // const [showForm, setShowForm] = useState(false);
  // const [showReportForm, setShowReportForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [highlightFirstRow, setHighlightFirstRow] = useState(false);
  const [highlightApplied, setHighlightApplied] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage
  );

  useEffect(() => {
    setAdvancedBalanceMode(true);
  }, [setAdvancedBalanceMode]);

  const handleStatusChange = useCallback((newStatus, reportId) => {
    setReports((reports) =>
      reports.map((report) =>
        report._id === reportId ? { ...report, status: newStatus } : report
      )
    );
  }, []);

  const handleOpenMenu = (itemId, event) => {
    const selectedRow = reports.find((row) => row._id === itemId);
    setCurrentRow(selectedRow);
    setDeleteItemId(itemId);
    setPopoverOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setPopoverOpen(null);
    setCurrentRow(null); // Clear the currently selected row
    setDeleteItemId(null); // Clear the delete item id
  };

  const handleOpenSession = async (row) => {
    navigate(`/home/sessions/${row.sessionID}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEditReportSuccess = () => {
    showSuccess('Successfully updated the report!');
    fetchReports();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({
      message,
      severity: 'success',
      duration: 10000,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOpenEdit = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    handleCloseMenu();
  };

  const handleRetryConfirm = () => {
    setOpenRetryDialog(true);
  };

  const handleRetryCancel = () => {
    handleCloseMenu();
    setOpenRetryDialog(false);
  };

  const handleRetryConfirmDialog = async () => {
    setLoading(true);
    try {
      await apiRequest('POST', `insights-report/retry/${deleteItemId}`);
      showSuccess('Successfully triggered retry of the report!');
    } catch (error) {
      console.error(`Error retrying report ${deleteItemId}`, error);
      setErrorMessage(`Error retrying a report! ${error.message}`);
    } finally {
      setOpenRetryDialog(false);
      handleCloseMenu();
      setLoading(false);
      fetchReports();
    }
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(true);
  };

  const handleCancelCancel = () => {
    handleCloseMenu();
    setOpenCancelDialog(false);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    handleCloseMenu();
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmDialog = async () => {
    setLoading(true);
    try {
      await apiRequest('DELETE', `insights-report/${deleteItemId}`);
      showSuccess('Successfully deleted a report!');
    } catch (error) {
      console.error(`Error deleting report ${deleteItemId}`, error);
      setErrorMessage(`Error deleting a report! ${error.message}`);
    } finally {
      setOpenDeleteDialog(false);
      setOpenCancelDialog(false);
      handleCloseMenu();
      setLoading(false);
      fetchReports();
    }
  };

  const fetchReports = useCallback(async () => {
    setLoading(true);

    try {
      const userId = currentUser.DBuser?._id;
      if (!userId) {
        setErrorMessage(
          `Error fetching transcripts! User not verified by server! Try again later.`
        );
        return;
      }
      const filter = {
        showSessionRecords,
      };
      const response = await apiRequest(
        'GET',
        `insights-report/list/${userId}`,
        { params: filter }
      );

      setReports(response);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setErrorMessage(`Error fetching reports! ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentUser, setReports, setLoading, showSessionRecords]);

  const handleDownloadReport = async (reportId, format) => {
    try {
      let response;
      let downloadUrl;
      let fileExtension;

      if (format === 'pdf') {
        response = await apiRequest(
          'GET',
          `/insights-report/download/pdf/${reportId}`,
          {
            responseType: 'blob',
          }
        );
        downloadUrl = URL.createObjectURL(response);
        fileExtension = 'pdf';
      } else {
        response = await apiRequest(
          'GET',
          `/insights-report/download/${reportId}`
        );
        downloadUrl = response.url;
        fileExtension = 'docx';
      }

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `report_${reportId}.${fileExtension}`;
      document.body.appendChild(link);
      link.click();

      if (format === 'pdf') {
        URL.revokeObjectURL(downloadUrl); // Cleanup for blob URL
      }

      link.remove();
      showSuccess(
        `Successfully downloaded the report as ${fileExtension.toUpperCase()}!`
      );
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error downloading a report! ${error.message}`);
    } finally {
      handleCloseMenu();
    }
  };

  const handleShareReport = async (reportId, email) => {
    try {
      await apiRequest('POST', `/insights-report/share/${reportId}`, {
        data: {
          email,
        },
      });
      showSuccess(`Successfully shared the report with ${email}!`);
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error sharing a report! ${error.message}`);
    } finally {
      handleCloseMenu();
      fetchReports();
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: fullScreen ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  useEffect(() => {
    console.log('ReportsPage.js. useEffect() call');
    const highlightFirstRow = location.state?.highlightFirstRow;

    fetchReports();

    if (highlightFirstRow && !highlightApplied) {
      setHighlightFirstRow(true);
      setHighlightApplied(true);
      if (successMessage) {
        showSuccess(successMessage);
        setSuccessMessage(null);
      }
      // Optionally, remove the highlight after some time
      setTimeout(() => setHighlightFirstRow(false), 10000);
    }
  }, [fetchReports, location.state, highlightApplied, successMessage]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports.length) : 0;

  const handleIncludeSessionRecordsChange = (newValue) => {
    setShowSessionRecords(newValue);
  };

  const filteredReports = applySortFilter(
    reports,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredReports.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Reports | Wundamental </title>
      </Helmet>

      <Container maxWidth={false}>
        {loading && <GenericLoader />}
        <Typography variant="h4" sx={{ mb: 5 }}>
          Your Reports
        </Typography>
        <Card>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <ReportListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            isEnabled={FILTER_ENABLED}
            onIncludeSessionRecordsChange={handleIncludeSessionRecordsChange}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ReportListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reports.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredReports
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        _id,
                        sessionName,
                        status,
                        generatedAt,
                        templateName,
                        tags,
                        shareHistory,
                        sessionID,
                      } = row;
                      const isRowHighlighted = highlightFirstRow && index === 0;
                      const isShared = shareHistory && shareHistory.length > 0;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          style={
                            isRowHighlighted
                              ? {
                                  backgroundColor: theme.palette.secondary.main,
                                }
                              : null
                          }
                        >
                          <TableCell
                            align="right"
                            padding="none"
                            sx={{
                              position: 'sticky',
                              right: 0,
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 2,
                            }}
                          >
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(_id, event)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {sessionID && status === 'COMPLETED' && (
                                <WundaButton
                                  variant="outlined"
                                  size="small"
                                  title="Open"
                                  startIcon={
                                    <Iconify icon="ion:open-outline" />
                                  }
                                  onClick={() => {
                                    handleOpenSession(row);
                                  }}
                                >
                                  View session
                                </WundaButton>
                              )}
                              <Typography variant="subtitle2" noWrap>
                                {sessionName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <StatusProgressLabel
                              key={_id}
                              status={status}
                              pollingStatuses={['PENDING', 'IN_PROGRESS']}
                              url={`insights-report/status/${_id}`}
                              onStatusChange={(newStatus) =>
                                handleStatusChange(newStatus, _id)
                              }
                              onReady={() => refreshBalance()}
                              pollingInterval={30}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {generatedAt
                              ? new Date(generatedAt).toLocaleString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  hour12: false,
                                })
                              : ''}
                          </TableCell>
                          <TableCell align="left">{templateName}</TableCell>
                          <TableCell>
                            <Box sx={{ maxWidth: '150px' }}>
                              {tags &&
                                tags.map((tag, index) => (
                                  <Chip key={index} label={tag} />
                                ))}
                            </Box>
                            <Tooltip title={tags && tags.join(', ')}>
                              <Box sx={{ maxWidth: '150px' }}>{/* tags */}</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="normal"
                            sx={{
                              position: 'sticky',
                              right: 0,
                              backgroundColor: theme.palette.background.paper,
                              zIndex: 2,
                            }}
                          >
                            {isShared && (
                              <Tooltip title="This report has been shared with somebody">
                                <Iconify icon={'ri:user-shared-line'} />
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={reports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          <ReportEditForm
            row={selectedRow}
            onCancel={handleCloseEdit}
            onSuccess={handleEditReportSuccess}
          />
        </Box>
      </Modal>

      <PromptDialog
        open={openDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this item?"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry this item?"
        onCancel={handleRetryCancel}
        onConfirm={handleRetryConfirmDialog}
      />

      <PromptDialog
        open={openCancelDialog}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this report generation?"
        onCancel={handleCancelCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <Popover
        open={Boolean(popoverOpen)}
        anchorEl={popoverOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {currentRow && currentRow.status === 'PENDING' && (
          <MenuItem onClick={handleCancelConfirm}>
            <Iconify icon={'eva:close-outline'} sx={{ mr: 2 }} />
            Cancel
          </MenuItem>
        )}

        {currentRow && currentRow.status === 'FAILED' && (
          <MenuItem onClick={handleRetryConfirm}>
            <Iconify icon={'eva:refresh-outline'} sx={{ mr: 2 }} />
            Retry
          </MenuItem>
        )}

        <MenuItem
          onClick={() => setDownloadDialogOpen(true)}
          disabled={!currentRow || currentRow.status !== 'COMPLETED'}
        >
          <Iconify icon={'eva:download-outline'} sx={{ mr: 2 }} />
          Download
        </MenuItem>

        <MenuItem
          onClick={() => setShareDialogOpen(true)}
          disabled={!currentRow || currentRow.status !== 'COMPLETED'}
        >
          <Iconify icon={'eva:share-outline'} sx={{ mr: 2 }} />
          Share
        </MenuItem>

        <MenuItem onClick={() => handleOpenEdit(currentRow)}>
          <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {currentRow &&
          currentRow.status !== 'IN_PROGRESS' &&
          currentRow.status !== 'PENDING' && (
            <MenuItem
              sx={{ color: 'error.main' }}
              onClick={handleDeleteConfirm}
            >
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          )}
      </Popover>

      <DownloadInsightsFormatDialog
        open={downloadDialogOpen}
        isLoading={downloadLoading}
        onCancel={() => {
          setDownloadDialogOpen(false);
          setPopoverOpen(null);
        }}
        onConfirm={async (format) => {
          setDownloadLoading(true);
          await handleDownloadReport(currentRow._id, format);
          setDownloadLoading(false);
          setDownloadDialogOpen(false);
        }}
      />

      <WundaShareToEmailDialog
        dialogProps={{
          title: 'Send to e-mail',
          bodyText: 'Enter the email address to share the report with',
          confirmPromptText:
            'Are you sure you want to share this report to {email}?',
        }}
        open={shareDialogOpen}
        isLoading={shareLoading}
        onCancel={() => {
          setShareDialogOpen(false);
          setPopoverOpen(null);
        }}
        onConfirm={async (email) => {
          setShareLoading(true);
          await handleShareReport(currentRow._id, email);
          setShareLoading(false);
          setShareDialogOpen(false);
        }}
      />

      <GenericSnackbar ref={snackbarRef} duration={10000} />
    </>
  );
}
