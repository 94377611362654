function logMessage(classification, message) {
  // console.debug(`[${classification.toUpperCase()}] ${message}`);
  const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

  if (debugMode || classification !== 'DEBUG') {
    console.debug(`[${classification.toUpperCase()}] ${message}`);
  } else {
    console.log(`[${classification.toUpperCase()}] ${message}`);
  }
}

export { logMessage };
