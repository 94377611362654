import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import WundaButton from './WundaButton';
import GenericLoader from '../loader/GenericLoader';
import Iconify from '../iconify';

const DownloadInsightsFormatDialog = ({
  open,
  isLoading,
  onCancel,
  onConfirm,
  onSuccess = () => {},
}) => {
  const theme = useTheme();

  const handleConfirm = async (format) => {
    await onConfirm(format);
    onSuccess('Report downloaded successfully');
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          width: { xs: '95%', sm: '100%' },
          margin: { xs: 1, sm: 'auto' },
        },
      }}
    >
      <DialogTitle>Download Report</DialogTitle>
      <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
        {isLoading ? (
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <GenericLoader label="Preparing download..." variant="spinner" />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Paper
              elevation={0}
              sx={{
                flex: 1,
                cursor: 'pointer',
                borderRadius: 3,
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'divider',
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: theme.palette.action.hover,
                  borderColor: theme.palette.divider,
                },
              }}
              onClick={async () => {
                await handleConfirm('pdf');
                onCancel();
              }}
            >
              <Box
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Iconify
                  icon="vscode-icons:file-type-pdf2"
                  width={48}
                  height={48}
                  sx={{ mb: 1 }}
                />
                <Typography variant="subtitle1" component="div">
                  PDF
                </Typography>
              </Box>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                flex: 1,
                cursor: 'pointer',
                borderRadius: 3,
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'divider',
                transition: 'all 0.2s',
                '&:hover': {
                  bgcolor: theme.palette.action.hover,
                  borderColor: theme.palette.divider,
                },
              }}
              onClick={async () => {
                await handleConfirm('docx');
                onCancel();
              }}
            >
              <Box
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Iconify
                  icon="vscode-icons:file-type-word2"
                  width={48}
                  height={48}
                  sx={{ mb: 1 }}
                />
                <Typography variant="subtitle1" component="div">
                  DOCX
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{ p: { xs: 2, sm: 3 }, pt: 0, justifyContent: 'center' }}
      >
        <WundaButton onClick={onCancel} variant="outlined" size="small">
          Cancel
        </WundaButton>
      </DialogActions>
    </Dialog>
  );
};

DownloadInsightsFormatDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default DownloadInsightsFormatDialog;
