import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Stack,
  Button,
  ClickAwayListener,
  InputAdornment,
  TextField,
  Box,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { AuthContext } from '../providers/AuthProvider';
import { apiRequest } from '../api/api';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import GenericLoader from '../components/loader/GenericLoader';
import ErrorMessage from '../components/alert/ErrorMessage';
import PromptDialog from '../components/dialog/PromptDialog';
import WundaShareToEmailDialog from '../components/wundamental/WundaShareToEmailDialog';
import WundaButton from '../components/wundamental/WundaButton';
import { navigateToBilling } from '../utils/billing';
import { formatDateTime } from '../utils/formatDate';

export default function WorkspacePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(
    currentUser?.DBuser?.workspaceName || ''
  );
  const [nameError, setNameError] = useState('');

  const hasReachedSeatsLimit = useMemo(
    () =>
      currentUser?.DBuser?.workspaceMaxSeats
        ? (users || []).length >= currentUser?.DBuser?.workspaceMaxSeats
        : false,
    [currentUser?.DBuser?.workspaceMaxSeats, users]
  );

  const fetchUsers = useCallback(async () => {
    if (!currentUser?.DBuser?.workspace) {
      setErrorMessage('Workspace ID not found!');
      setLoading(false);
      return;
    }

    try {
      const response = await apiRequest(
        'GET',
        `/workspace/${currentUser?.DBuser?.workspace}`
      );
      setUsers(response.users);
    } catch (error) {
      setErrorMessage(`Error fetching users! ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentUser?.DBuser?.workspace]);

  useEffect(() => {
    if (
      !currentUser?.DBuser?.subscription?.isActive ||
      !currentUser?.DBuser?.subscription?.isTeamSubscription
    ) {
      navigate('/home/subscribe');
      return;
    }

    if (currentUser?.DBuser?.workspaceRole !== 'owner') {
      navigate('/home');
      return;
    }

    fetchUsers();
  }, [currentUser, navigate, fetchUsers]);

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveConfirm = () => {
    setOpenRemoveDialog(true);
    handleMenuClose();
  };

  const handleRemoveCancel = () => {
    setOpenRemoveDialog(false);
    setSelectedUser(null);
  };

  const handleDeactivateConfirm = () => {
    setOpenDeactivateDialog(true);
    handleMenuClose();
  };

  const handleDeactivateCancel = () => {
    setOpenDeactivateDialog(false);
    setSelectedUser(null);
  };

  const handleRemoveUserConfirmDialog = async () => {
    try {
      await apiRequest(
        'DELETE',
        `/workspace/${currentUser?.DBuser?.workspace}/user/${selectedUser.id}`
      );
      showSuccess(`${selectedUser.name} has been removed from the workspace`);
      fetchUsers();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error removing user: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setOpenRemoveDialog(false);
      setSelectedUser(null);
    }
  };

  const handleToggleUserStatusConfirmDialog = async () => {
    try {
      if (selectedUser.status === 'deactivated') {
        // Activate user
        await apiRequest(
          'POST',
          `/workspace/${currentUser?.DBuser?.workspace}/users/${selectedUser.id}/activate`
        );
      } else {
        // Deactivate user
        await apiRequest(
          'PUT',
          `/workspace/${currentUser?.DBuser?.workspace}/user/${selectedUser.id}/deactivate`
        );
      }

      showSuccess(
        `${selectedUser.name} has been ${selectedUser.status === 'deactivated' ? 'activated' : 'deactivated'}`
      );
      fetchUsers();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error updating user status: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setOpenDeactivateDialog(false);
      setSelectedUser(null);
    }
  };

  const handleInvite = async (email) => {
    if (hasReachedSeatsLimit) {
      setSnackbar({
        open: true,
        message:
          'Workspace seat limit reached. Please upgrade your plan for more seats.',
        severity: 'error',
      });
      setShowInviteModal(false);
      return;
    }

    setInviteLoading(true);
    try {
      await apiRequest('POST', '/workspace/invite', {
        data: { email },
      });
      showSuccess('Invitation sent successfully!');
      await Promise.all([fetchUsers(), refreshUser()]);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error sending invitation: ${error.message}`,
        severity: 'error',
      });
    } finally {
      setInviteLoading(false);
      setShowInviteModal(false);
    }
  };

  const handleWorkspaceNameUpdate = async (newName) => {
    if (!newName.trim()) {
      setNameError('Workspace name cannot be empty');
      return;
    }

    try {
      await apiRequest('PUT', `/workspace/${currentUser?.DBuser?.workspace}`, {
        data: { name: newName.trim() },
      });
      showSuccess('Workspace name updated successfully');
      await refreshUser();
      setIsEditingName(false);
      setNameError('');
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error updating workspace name: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const handleNameSubmit = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleWorkspaceNameUpdate(workspaceName);
    }
  };

  const handleClickAway = () => {
    if (isEditingName) {
      if (workspaceName.trim() !== currentUser?.DBuser?.workspaceName) {
        handleWorkspaceNameUpdate(workspaceName);
      } else {
        setIsEditingName(false);
      }
    }
  };

  const handleBillingNavigation = () => {
    navigateToBilling(currentUser.DBuser._id, setSnackbar);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        {isEditingName ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
              autoFocus
              fullWidth
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              onKeyDown={handleNameSubmit}
              error={Boolean(nameError)}
              helperText={nameError}
              sx={{
                maxWidth: '500px',
                '& .MuiInputBase-root': {
                  fontSize: '2.125rem',
                  fontWeight: 400,
                  padding: '5px 5px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleWorkspaceNameUpdate(workspaceName)}
                      edge="end"
                      disabled={!workspaceName.trim()}
                      sx={{
                        width: 40,
                        height: 40,
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.5rem',
                        },
                      }}
                    >
                      <Iconify icon="eva:checkmark-fill" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ClickAwayListener>
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            onClick={() => setIsEditingName(true)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                bgcolor: (theme) => theme.palette.action.hover,
                borderRadius: 1,
                px: 1,
              },
              px: 1,
            }}
          >
            Manage{' '}
            <Box
              component="span"
              sx={{
                bgcolor: theme.palette.secondary.main,
                borderRadius: 1,
                px: 1,
              }}
            >
              {currentUser?.DBuser?.workspaceName || 'Your'}
            </Box>{' '}
            Workspace
            <Iconify
              icon="eva:edit-fill"
              sx={{
                ml: 1,
                width: 20,
                height: 20,
                verticalAlign: 'middle',
                color: 'text.secondary',
                opacity: 0,
                transition: 'opacity 0.2s',
                '.MuiTypography-root:hover &': {
                  opacity: 0.72,
                },
              }}
            />
          </Typography>
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          {hasReachedSeatsLimit ? (
            <WundaButton
              variant="contained"
              color="primary"
              onClick={handleBillingNavigation}
              startIcon={<Iconify icon="eva:diagonal-arrow-right-up-fill" />}
            >
              Upgrade Plan
            </WundaButton>
          ) : (
            <Button
              variant="contained"
              onClick={() => setShowInviteModal(true)}
            >
              Invite
            </Button>
          )}
          {currentUser?.DBuser?.workspaceMaxSeats && (
            <Typography
              variant="body2"
              color={hasReachedSeatsLimit ? 'error' : 'text.secondary'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {users ? users.length : 0} /{' '}
              {currentUser.DBuser.workspaceMaxSeats} seats used
              {hasReachedSeatsLimit && (
                <Tooltip title="Upgrade your plan for more seats">
                  <Iconify
                    icon="mdi:alert-circle-outline"
                    sx={{ ml: 1, width: 16, height: 16, color: 'error.main' }}
                  />
                </Tooltip>
              )}
            </Typography>
          )}
        </Stack>
      </Stack>

      {loading && <GenericLoader />}
      <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />

      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {!isMobile && <TableCell>Email</TableCell>}
                {!isMobile && <TableCell>Role</TableCell>}
                {!isMobile && <TableCell>Status</TableCell>}
                {!isMobile && <TableCell>Last seen</TableCell>}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <GenericLoader />
                  </TableCell>
                </TableRow>
              ) : users && users.length > 0 ? (
                users.map((user) => (
                  <TableRow
                    key={user?.id || 'loading'}
                    sx={{
                      '& .MuiTableCell-root': {
                        color: (theme) =>
                          user?.status === 'invited' ||
                          user?.status === 'deactivated'
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary,
                      },
                    }}
                  >
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          alt={user?.name}
                          src={user?.photoUrl}
                          sx={{
                            opacity:
                              user?.status === 'invited' ||
                              user?.status === 'deactivated'
                                ? 0.5
                                : 1,
                          }}
                        />
                        <Typography variant="subtitle2" noWrap>
                          {user?.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    {!isMobile && <TableCell>{user?.email}</TableCell>}
                    {!isMobile && (
                      <TableCell>
                        {user?.role ? sentenceCase(user.role) : ''}
                      </TableCell>
                    )}
                    {!isMobile && (
                      <TableCell>
                        {user?.status ? sentenceCase(user.status) : ''}
                      </TableCell>
                    )}
                    {!isMobile && (
                      <TableCell>
                        {user?.lastSeenAt
                          ? formatDateTime(user.lastSeenAt)
                          : '-'}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {user?.id !== currentUser?.DBuser?._id && (
                        <>
                          <IconButton
                            onClick={(event) => handleMenuOpen(event, user)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            {selectedUser?.status === 'joined' && (
                              <MenuItem onClick={handleDeactivateConfirm}>
                                <Iconify
                                  icon={
                                    selectedUser?.status === 'deactivated'
                                      ? 'eva:checkmark-circle-2-outline'
                                      : 'eva:close-circle-outline'
                                  }
                                  sx={{ mr: 2 }}
                                />
                                Deactivate
                              </MenuItem>
                            )}
                            {selectedUser?.status === 'deactivated' && (
                              <MenuItem onClick={handleDeactivateConfirm}>
                                <Iconify
                                  icon={'eva:checkmark-circle-2-outline'}
                                  sx={{ mr: 2 }}
                                />
                                Activate
                              </MenuItem>
                            )}
                            <MenuItem onClick={handleRemoveConfirm}>
                              <Iconify
                                icon={'eva:trash-2-outline'}
                                sx={{ mr: 2 }}
                              />
                              Remove
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <WundaShareToEmailDialog
        dialogProps={{
          title: 'Send invitation to e-mail',
          bodyText: 'Enter the email address to invite to the workspace',
          confirmPromptText: null,
          subjectField: false,
          bodyField: false,
        }}
        open={showInviteModal}
        isLoading={inviteLoading}
        onCancel={() => setShowInviteModal(false)}
        onConfirm={handleInvite}
        disabled={hasReachedSeatsLimit}
        disabledMessage="Workspace seat limit reached. Please upgrade your plan for more seats."
      />

      <PromptDialog
        open={openRemoveDialog}
        title="Confirm User Removal"
        message={`Are you sure you want to remove ${selectedUser?.name} from the workspace?`}
        onCancel={handleRemoveCancel}
        onConfirm={handleRemoveUserConfirmDialog}
      />

      <PromptDialog
        open={openDeactivateDialog}
        title={`Confirm User ${selectedUser?.status === 'deactivated' ? 'Activation' : 'Deactivation'}`}
        message={`Are you sure you want to ${
          selectedUser?.status === 'deactivated' ? 'activate' : 'deactivate'
        } ${selectedUser?.name}?`}
        onCancel={handleDeactivateCancel}
        onConfirm={handleToggleUserStatusConfirmDialog}
      />

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
}
