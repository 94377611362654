import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

WundaAutoComplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  renderTags: PropTypes.func,
  variant: PropTypes.string,
};

export default function WundaAutoComplete({
  value,
  onChange,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  label,
  placeholder,
  multiple = false,
  renderTags,
  variant = 'outlined',
}) {
  const muiTheme = useTheme();

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => {
        // eslint-disable-next-line no-unused-vars, react/prop-types
        const { key, ...otherProps } = props;
        return (
          <li
            key={option._id}
            {...otherProps}
            style={{
              backgroundColor: otherProps['aria-selected']
                ? muiTheme.palette.secondary.main
                : 'inherit',
              color: otherProps['aria-selected']
                ? muiTheme.palette.secondary.contrastText
                : 'inherit',
            }}
          >
            {getOptionLabel(option)}
          </li>
        );
      }}
      renderTags={renderTags}
    />
  );
}
