import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Alert,
  Chip,
  Tooltip,
} from '@mui/material';
// components
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import PromptDialog from '../components/dialog/PromptDialog';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import StatusProgressLabel from '../components/wundamental/StatusProgressLabel';
import GenericLoader from '../components/loader/GenericLoader';
import WundaButton from '../components/wundamental/WundaButton';
import RecordingUploadForm from '../sections/@dashboard/recording/RecordingUploadForm';
import RecordingEditForm from '../sections/@dashboard/recording/RecordingEditForm';
import { AuthContext } from '../providers/AuthProvider';
// sections
import {
  RecordingListToolbar,
  RecordingListHead,
} from '../sections/@dashboard/recording';
import { apiRequest } from '../api/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'transcribe', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'uploadedAt', label: 'Uploaded At', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'language', label: 'Language', alignRight: false },
  { id: 'tags', label: 'Tags', alignRight: false },
];
const FILTER_ENABLED = true;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (item) =>
        item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.tags &&
          item.tags.some(
            (tag) => tag.toLowerCase().indexOf(query.toLowerCase()) !== -1
          ))
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RecordingsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { currentUser } = useContext(AuthContext);
  const { refreshBalance, setAdvancedBalanceMode } = useContext(AuthContext);

  const snackbarRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('uploadedAt');

  const [filterName, setFilterName] = useState('');
  const [showSessionRecords, setShowSessionRecords] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [recordings, setRecordings] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [loading, setLoading] = useState(true);

  const [showForm, setShowForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openTranscribeDialog, setOpenTranscribeDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [showListenModal, setShowListenModal] = useState(false);
  const [audioLink, setAudioLink] = useState('');

  useEffect(() => {
    setAdvancedBalanceMode(true);
  }, [setAdvancedBalanceMode]);

  const handleStatusChange = useCallback((newStatus, audioFileId) => {
    setRecordings((recordings) =>
      recordings.map((recording) =>
        recording._id === audioFileId
          ? { ...recording, status: newStatus }
          : recording
      )
    );
  }, []);

  const handleOpenMenu = (itemId, event) => {
    const selectedRow = recordings.find((row) => row._id === itemId);
    setCurrentRow(selectedRow);
    setDeleteItemId(itemId);
    setPopoverOpen(event.currentTarget);
  };

  const handleOpenEdit = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleOpenSession = async (row) => {
    navigate(`/home/sessions/${row.sessionID}`);
  };

  const handleTranscribeConfirmDialog = (row) => {
    setCurrentRow(row);
    setOpenTranscribeDialog(true);
  };

  const handleTranscribeCancel = () => {
    handleCloseMenu();
    setOpenTranscribeDialog(false);
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(true);
  };

  const handleCancelCancel = () => {
    handleCloseMenu();
    setOpenCancelDialog(false);
  };

  const handleCloseMenu = () => {
    setPopoverOpen(null);
    setCurrentRow(null); // Clear the currently selected row
    setDeleteItemId(null); // Clear the delete item id
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = transcripts.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleSelectRowClick = (event, _id) => {
  //   const selectedIndex = selected.indexOf(_id);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, _id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleUploadSuccess = () => {
    // Refresh the table data
    showSuccess(
      'Successfully uploaded the new recording! It will be processed shortly.'
    );
    fetchRecordings();
  };

  const handleUploadClick = () => {
    setShowForm(!showForm);
  };

  const handleTranscribeSuccess = () => {
    // showSuccess('Successfully finished report generation! Check your inbox!');
    // fetchTranscripts();

    // In your event handler where you navigate after a successful action
    const successMessage =
      'Transcription successfully started! See the status of the enqueued transcription above!';
    navigate('/home/transcripts', {
      state: { highlightFirstRow: true, successMessage },
    });
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    handleCloseMenu();
  };

  const handleEditRecordingSuccess = () => {
    showSuccess('Successfully updated the audio file!');
    fetchRecordings();
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    handleCloseMenu();
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmDialog = async () => {
    setLoading(true);
    try {
      await apiRequest('DELETE', `audio/${deleteItemId}`);
      fetchRecordings();
      showSuccess('Successfully deleted a recording!');
    } catch (error) {
      console.error(`Error deleting recording ${deleteItemId}`, error);
      setErrorMessage(`Error deleting a recording! ${error.message}`);
    } finally {
      setOpenDeleteDialog(false);
      setOpenCancelDialog(false);
      handleCloseMenu();
      setLoading(false);
    }
  };

  const handleOpenListenModal = (audioUrl) => {
    setAudioLink(audioUrl);
    setShowListenModal(true);
  };

  const handleDownload = async (row) => {
    setLoading(true);
    const { _id } = row;
    try {
      const response = await apiRequest('GET', `/audio/download/${_id}`);
      const downloadUrl = response.url;
      handleOpenListenModal(downloadUrl);
    } catch (error) {
      console.error(`Error downloading a recording ${_id}`, error);
      setErrorMessage(`Error downloading a recording! ${error.message}`);
    } finally {
      handleCloseMenu();
      setLoading(false);
    }
  };

  const handleTranscribeConfirm = async () => {
    const audioFileId = currentRow._id;
    const userId = currentUser.DBuser?._id;
    try {
      handleCloseMenu();
      setLoading(true);
      const response = await apiRequest('POST', `/transcript/transcribe`, {
        data: { audioFileId, userId },
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });

      console.log('Transcription started', response);
      showSuccess('Transcription started!');
      handleTranscribeSuccess();
    } catch (error) {
      console.error('Failed to start transcription:', error);
      setErrorMessage(
        `Error starting transcription! ${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
      handleCloseMenu();
      setOpenTranscribeDialog(false);
      refreshBalance();
      fetchRecordings();
    }
  };

  const fetchRecordings = useCallback(async () => {
    setLoading(true);

    try {
      const userId = currentUser.DBuser?._id;
      const filter = {
        showSessionRecords,
      };
      if (!userId) {
        setErrorMessage(
          `Error fetching recordings! User not verified by server! Try again later.`
        );
        return;
      }
      const response = await apiRequest('GET', `audio/list/${userId}`, {
        params: filter,
      });

      setRecordings(response);
    } catch (error) {
      console.error('Error fetching recordings:', error.message);
      setErrorMessage(`Error fetching recordings! ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentUser, setRecordings, setLoading, showSessionRecords]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: fullScreen ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  useEffect(() => {
    fetchRecordings();
  }, [fetchRecordings]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recordings.length) : 0;

  const handleIncludeSessionRecordsChange = (newValue) => {
    setShowSessionRecords(newValue);
  };

  const filteredRecordings = applySortFilter(
    recordings,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = !filteredRecordings.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Recordings | Wundamental </title>
      </Helmet>

      <Container maxWidth={false}>
        {loading && <GenericLoader />}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {showForm ? 'Upload Audio File' : 'Your Recordings'}
          </Typography>
          {!showForm && (
            <WundaButton
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleUploadClick}
            >
              New Recording
            </WundaButton>
          )}
        </Stack>

        {showForm ? (
          <RecordingUploadForm
            onCancel={handleCloseForm}
            currentUser={currentUser}
            onSuccess={handleUploadSuccess}
          />
        ) : (
          <Card>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <RecordingListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              isEnabled={FILTER_ENABLED}
              onIncludeSessionRecordsChange={handleIncludeSessionRecordsChange}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <RecordingListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={recordings.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredRecordings
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          _id,
                          name,
                          status,
                          uploadedAt,
                          duration,
                          language,
                          tags,
                          sessionID,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell
                              align="right"
                              padding="none"
                              sx={{
                                position: 'sticky',
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 2,
                              }}
                            >
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(_id, event)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>

                            <TableCell
                              align="left"
                              padding="none"
                              sx={{
                                position: 'sticky',
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                              }}
                            >
                              {sessionID ? (
                                <WundaButton
                                  variant="outlined"
                                  size="small"
                                  title="View session"
                                  startIcon={
                                    <Iconify icon="ion:open-outline" />
                                  }
                                  onClick={() => {
                                    handleOpenSession(row);
                                  }}
                                >
                                  View session
                                </WundaButton>
                              ) : (
                                status === 'READY' && (
                                  <WundaButton
                                    variant="outlined"
                                    size="small"
                                    title="Transcribe"
                                    startIcon={
                                      <Iconify icon="material-symbols:transcribe-outline" />
                                    }
                                    onClick={() => {
                                      handleTranscribeConfirmDialog(row);
                                    }}
                                  >
                                    Transcribe
                                  </WundaButton>
                                )
                              )}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <StatusProgressLabel
                                key={_id}
                                status={status}
                                pollingStatuses={['PENDING', 'PROCESSING']}
                                url={`audio/status/${_id}`}
                                onStatusChange={(newStatus) =>
                                  handleStatusChange(newStatus, _id)
                                }
                                onReady={() => refreshBalance()}
                                pollingInterval={10}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {uploadedAt
                                ? new Date(uploadedAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false,
                                    }
                                  )
                                : ''}
                            </TableCell>
                            <TableCell align="left">{duration?.text}</TableCell>
                            <TableCell align="left">{language}</TableCell>
                            <TableCell>
                              <Box sx={{ maxWidth: '150px' }}>
                                {tags &&
                                  tags.map((tag, index) => (
                                    <Chip key={index} label={tag} />
                                  ))}
                              </Box>
                              <Tooltip title={tags && tags.join(', ')}>
                                <Box sx={{ maxWidth: '150px' }}>
                                  {/* tags */}
                                </Box>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={recordings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <PromptDialog
        open={openDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this item?"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openCancelDialog}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this recording upload?"
        onCancel={handleCancelCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openTranscribeDialog}
        title="Confirm Transcription"
        message="Are you sure you want to transcribe this item?"
        onCancel={handleTranscribeCancel}
        onConfirm={handleTranscribeConfirm}
      />

      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          <RecordingEditForm
            row={selectedRow}
            onCancel={handleCloseEdit}
            onSuccess={handleEditRecordingSuccess}
          />
        </Box>
      </Modal>

      <Modal open={showListenModal} onClose={() => setShowListenModal(false)}>
        <Box
          sx={{
            ...modalStyle,
            bgcolor: (theme) => theme.palette.grey[600],
          }}
        >
          <iframe
            src={audioLink}
            style={{ height: '100%', width: '100%' }}
            title="Audio Player"
          />
        </Box>
      </Modal>

      <Popover
        open={Boolean(popoverOpen)}
        anchorEl={popoverOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* TODO: Add transcript edit option */}
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Generate report
        </MenuItem> */}

        {currentRow && currentRow.status === 'PENDING' && (
          <MenuItem onClick={handleCancelConfirm}>
            <Iconify icon={'eva:close-outline'} sx={{ mr: 2 }} />
            Cancel
          </MenuItem>
        )}

        {currentRow && currentRow.sessionID ? (
          <MenuItem onClick={() => handleOpenSession(currentRow)}>
            <Iconify icon={'ion:open-outline'} sx={{ mr: 2 }} />
            Open
          </MenuItem>
        ) : (
          currentRow &&
          currentRow.status === 'READY' && (
            <MenuItem onClick={() => handleTranscribeConfirmDialog(currentRow)}>
              <Iconify
                icon={'material-symbols:transcribe-outline'}
                sx={{ mr: 2 }}
              />
              Transcribe
            </MenuItem>
          )
        )}

        {currentRow && currentRow.status === 'READY' && (
          <MenuItem onClick={() => handleDownload(currentRow)}>
            <Iconify icon={'zondicons:play-outline'} sx={{ mr: 2 }} />
            Listen
          </MenuItem>
        )}

        <MenuItem onClick={() => handleOpenEdit(currentRow)}>
          <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        {currentRow && currentRow.status !== 'PENDING' && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteConfirm}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        )}
      </Popover>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
