import { apiRequest } from '../api/api';

const themeService = {
  getAllThemes: async () => {
    const response = await apiRequest('GET', '/theme/list');
    return response;
  },

  getThemeById: async (id) => {
    const response = await apiRequest('GET', `/theme/${id}`);
    return response;
  },

  createTheme: async (themeData) => {
    const response = await apiRequest('POST', '/theme', {
      data: themeData,
    });
    return response;
  },

  updateTheme: async (id, themeData) => {
    const response = await apiRequest('PUT', `/theme/${id}`, {
      data: themeData,
    });
    return response;
  },

  deleteTheme: async (id) => {
    const response = await apiRequest('DELETE', `/theme/${id}`);
    return response;
  },
};

export default themeService;
