import { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  FormLabel,
  Box,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import WundaButton from '../../../components/wundamental/WundaButton';
import GenericLoader from '../../../components/loader/GenericLoader';
import { apiRequest } from '../../../api/api';
import { AuthContext } from '../../../providers/AuthProvider';

const GenerateReportForm = ({ currentUser, row, onCancel, onSuccess }) => {
  const methods = useForm({
    defaultValues: {
      name: row ? row.name : '',
      sessionDate: row && row.sessionDate ? dayjs(row.sessionDate) : null,
      speakerRatios: row ? row.speakerRatios : {},
      sessionDuration: row ? row.sessionDuration : null,
      tags: row ? row.tags : [],
    },
  });

  // Define the mapping
  const roleMapping = {
    coach: 'nurturer',
    coachee: 'flourisher',
    therapist: 'nurturer',
    patient: 'flourisher',
  };

  // Define the field names
  const roleFieldNames = {
    nurturer: 'nurturerName',
    flourisher: 'flourisherName',
  };

  const roleOrder = [
    'coach',
    'coachee',
    'manager',
    'employee',
    'therapist',
    'patient',
    'interviewer',
    'interviewee',
  ];
  const transcriptRoles = Object.keys(row.roleRatios || row.speakerRatios).sort(
    (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
  );

  const { refreshBalance } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(row.tags || []);
  const [tagInputValue, setTagInputValue] = useState('');
  const [reportType, setReportType] = useState('AUTO');
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = methods;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (row) {
      setValue('transcriptId', row._id);
      setValue('sessionName', row.name);
      setValue('sessionDate', row.sessionDate);
      setValue('speakerRatios', row.speakerRatios);
      setValue('sessionDuration', row.sessionDuration);
    }
  }, [row, setValue]);

  // Fetch templates from API
  useEffect(() => {
    const fetchTemplates = async () => {
      setLoadingTemplates(true);

      try {
        const response = await apiRequest('GET', 'template/list/published');
        setTemplates(response);

        if (response.length === 1) {
          setSelectedTemplate(response[0]._id);
          setValue('templateId', response[0]._id);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
        setErrorMessage('Error fetching templates!');
      } finally {
        setLoadingTemplates(false);
      }
    };

    fetchTemplates();
  }, [setValue]);

  const handleReportGeneration = async (data) => {
    const {
      transcriptId,
      templateId,
      clientEmail,
      sessionName,
      sessionDate,
      sessionDuration,
      nurturerName,
      flourisherName,
      tags,
    } = data;

    const payload = {
      transcriptId,
      templateId,
      reportType,
      recipientEmail: clientEmail,
      sessionName,
      sessionDate,
      sessionDuration,
      nurturerName,
      flourisherName,
      userId: currentUser.DBuser._id,
      sendAsEmail: true,
      tags,
    };

    try {
      setLoading(true);
      const response = await apiRequest('POST', '/insights-report', {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 300000,
      });

      console.log('Report generation success:', response);
      setSuccessMessage('Started report generation');
      reset();

      if (onSuccess) {
        onSuccess('Started report generation');
      }

      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      console.error('Failed to start report generation:', error);
      setErrorMessage(
        `Error starting report generation! ${error.response.data.message || error.message}`
      );
    } finally {
      setLoading(false);
      refreshBalance();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (tagInputValue.trim() !== '' && !tags.includes(tagInputValue.trim())) {
      setErrorMessage(
        'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
      );
      return;
    }
    handleSubmit(
      async (data) => {
        try {
          await handleReportGeneration(data);
        } catch (error) {
          setErrorMessage(error.message || 'An unexpected error occurred.');
        }
      },
      (errors) => {
        console.log('Form validation errors:', errors);
        setErrorMessage(
          'There were some errors with your submission. Did you fill all required fields?'
        );
      }
    )();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose Report Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            row
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <FormControlLabel
              value="AUTO"
              control={<Radio />}
              label="Automatic"
            />
            <FormControlLabel
              value="MANUAL"
              control={<Radio />}
              label="Manual"
            />
          </RadioGroup>
          {reportType === 'AUTO' ? (
            <Typography variant="caption" display="block" gutterBottom>
              <strong>Automatic</strong>: Generate and send to client directly.
            </Typography>
          ) : (
            <Typography variant="caption" display="block" gutterBottom>
              <strong>Manual</strong>: Just generate report without any action.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <WundaButton onClick={handleClose}>OK</WundaButton>
        </DialogActions>
      </Dialog>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="sessionName"
                defaultValue=""
                rules={{ required: 'Please name your report' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name *"
                    fullWidth
                    error={!!errors.sessionName}
                    helperText={errors.sessionName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {reportType === 'MANUAL' ? (
                <Controller
                  control={control}
                  name="templateId"
                  rules={{ required: 'Please choose a report template' }}
                  defaultValue={selectedTemplate}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.templateId?.message}>
                      <InputLabel id="template-select-label">
                        Choose report template:
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="template-select-label"
                        id="template-select"
                        fullWidth
                        disabled={loadingTemplates}
                        sx={{ marginTop: '8px' }}
                      >
                        {templates.map((template) => (
                          <MenuItem key={template._id} value={template._id}>
                            {template.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.templateId && (
                        <FormHelperText>
                          {errors.templateId.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              ) : (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="clientEmail"
                    defaultValue=""
                    rules={{
                      required: 'Client email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                        message: 'invalid email address',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Client E-mail *"
                        fullWidth
                        error={!!errors.clientEmail}
                        helperText={errors.clientEmail?.message}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="sessionDate"
                  defaultValue={null}
                  rules={{ required: 'Session date is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <DatePicker
                        label="Session Date"
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          // Check if newValue is a valid date
                          if (newValue && !Number.isNaN(newValue.valueOf())) {
                            field.onChange(newValue.toISOString());
                          } else {
                            // Handle invalid date, e.g., set to null or a default value
                            field.onChange(null);
                          }
                        }}
                        renderDay={(
                          day,
                          selectedDate,
                          isInCurrentMonth,
                          dayComponent
                        ) => dayComponent}
                        slots={{
                          OpenPickerIcon: () => (
                            <Iconify icon="ic:round-date-range" />
                          ), // Use the Iconify icon here
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ['clear', 'today'],
                          },
                        }}
                      />
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                fullWidth
                sx={{ mt: 1, border: '1px solid #ccc', p: 2 }}
              >
                <FormLabel component="legend">Speakers (optional)</FormLabel>
                {transcriptRoles.map((role, index) => {
                  const fieldName =
                    roleFieldNames[roleMapping[role.toLowerCase()]];

                  return (
                    <Box
                      key={role}
                      sx={{ width: '50%', mt: index !== 0 ? 2 : 0 }}
                    >
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={fieldName}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={`${sentenceCase(role)}'s Name`}
                              fullWidth
                              error={!!errors[fieldName]}
                              helperText={errors[fieldName]?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  );
                })}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Tags"
                variant="outlined"
                fullWidth
                placeholder="Enter a tag and press Enter or any non-alphanumeric key"
                value={tagInputValue} // bind the state variable to the input value
                onChange={(e) => setTagInputValue(e.target.value)} // update the state variable on every change
                onBlur={() => {
                  if (
                    tagInputValue.trim() !== '' &&
                    !tags.includes(tagInputValue.trim())
                  ) {
                    setErrorMessage(
                      'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onKeyUp={(event) => {
                  const sanitizedValue = event.target.value
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .trim();
                  const isDelimiter =
                    /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

                  if (isDelimiter && sanitizedValue !== '') {
                    if (!tags.includes(sanitizedValue)) {
                      const newTags = [...tags, sanitizedValue];
                      setTags(newTags);
                      setValue('tags', newTags); // Assuming you still need this from the first example
                    }
                    setTagInputValue(''); // reset the input value state
                  }
                }}
              />

              <Box mt={2}>
                {tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => {
                      const newTags = tags.filter((t) => t !== tag);
                      setTags(newTags);
                      setValue('tags', newTags);
                    }}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                  />
                ))}
              </Box>
              <Controller
                name="tags"
                control={control}
                render={() => null}
                defaultValue={[]}
              />
            </Grid>

            <Grid item xs={12}>
              <WundaButton
                variant="outlined"
                color="primary"
                onClick={onCancel}
                sx={{ mr: 2 }}
              >
                Cancel
              </WundaButton>
              <WundaButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Submit
              </WundaButton>
            </Grid>
          </Grid>
          <Box mt={2}>
            {loading && <GenericLoader />}
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

GenerateReportForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  row: PropTypes.object,
};

export default GenerateReportForm;
