import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => ({
  textAlign: 'center',
  verticalAlign: 'middle',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1px',
  marginBottom: '1px',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderRadius: '25px',
  transition:
    'color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color:
    variant === 'outlined'
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText,
  backgroundColor:
    variant === 'outlined' ? 'transparent' : theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    color:
      variant === 'outlined'
        ? theme.palette.primary.main
        : theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
  },
}));

const WundaButton = ({ tooltip = '', ...props }) => (
  <Tooltip title={tooltip}>
    <span>
      <StyledButton {...props} />
    </span>
  </Tooltip>
);

WundaButton.propTypes = {
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default WundaButton;
