import React, { useEffect } from 'react';
import { auth } from '../../firebase/firebase';

const EmailVerification = () => {
  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      // get user's info from local storage
      const fullName = window.localStorage.getItem('fullName');
      //   const country = window.localStorage.getItem("country");

      // Sign in the user
      auth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage
          window.localStorage.removeItem('emailForSignIn');

          // Clear user's info from local storage
          window.localStorage.removeItem('fullName');
          window.localStorage.removeItem('country');

          // User signed in
          // Get user's Firebase uid
          const { uid } = result.user;
          console.log('user fullname:', fullName);
          console.log('user uid:', uid);
          console.log('Firebase user', result.user);

          // TODO: Register the new user in your backend
          // fetch("https://your-api-url.com/users", {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify({
          //     uid,
          //     fullName,
          //     email,
          //     country,
          //   }),
          // })
          //   .then((response) => response.json())
          //   .then((data) => {
          //     console.log("Success:", data);
          //   })
          //   .catch((error) => {
          //     console.error("Error:", error);
          //   });
        })
        .catch((error) => {
          console.error('Error signing in with email link', error);
        });
    }
  }, []);

  return <div>Verifying...</div>;
};

export default EmailVerification;
