import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// Convert time string (HH:MM:SS or MM:SS or XXmYYs) to seconds
export function timeToSeconds(timeStr) {
  if (!timeStr) return 0;

  // Handle XXmYYs format
  const minsSecsMatch = timeStr.match(/(\d+)m\s*:\s*(\d+)s/);
  if (minsSecsMatch) {
    return Number(minsSecsMatch[1]) * 60 + Number(minsSecsMatch[2]);
  }

  // Handle HH:MM:SS or MM:SS format
  const parts = timeStr
    .split(':')
    .map((part) => Number(part.replace(/[^\d.-]/g, '')));
  let seconds = 0;

  if (parts.length === 3) {
    seconds += parts[0] * 3600; // hours
    seconds += parts[1] * 60; // minutes
    seconds += parts[2]; // seconds
  } else if (parts.length === 2) {
    seconds += parts[0] * 60; // minutes
    seconds += parts[1]; // seconds
  }

  return seconds;
}

// Helper function to find the closest dialogue item
export function findClosestDialogue(time, dialogueArray) {
  if (!dialogueArray || dialogueArray.length === 0) {
    return null;
  }

  const target = timeToSeconds(time);
  let closest = dialogueArray[0];
  let closestDiff = Math.abs(timeToSeconds(closest.time) - target);

  for (let i = 1; i < dialogueArray.length; i += 1) {
    const diff = Math.abs(timeToSeconds(dialogueArray[i].time) - target);
    if (diff < closestDiff) {
      closest = dialogueArray[i];
      closestDiff = diff;
    }
  }

  return closest;
}

// React component for clickable timestamps
function TimestampButton({ timeStr, onTimeClick }) {
  const theme = useTheme();

  const handleClick = () => {
    if (onTimeClick) {
      onTimeClick(timeStr);
    }
  };

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        borderRadius: '4px',
        padding: '2px 6px',
        margin: '0 2px',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      }}
      onClick={handleClick}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
      role="button"
      tabIndex={0}
    >
      {timeStr}
    </Box>
  );
}

TimestampButton.propTypes = {
  timeStr: PropTypes.string.isRequired,
  onTimeClick: PropTypes.func.isRequired,
};

// Render text with clickable timestamps
export function renderWithClickableTimestamps(text, onTimeClick) {
  if (!text || typeof text !== 'string') return text;

  // Match various timestamp formats:
  // - [HH:MM:SS] or HH:MM:SS
  // - [MM:SS] or MM:SS
  // - [XXmYYs] or XXmYYs
  const timePattern =
    /\[(\d+:\d+(?::\d+)?)\]|\b(\d+:\d+(?::\d+)?)\b|\[(\d+m\s*:\s*\d+s)\]|\b(\d+m\s*:\s*\d+s)\b/g;
  let lastIndex = 0;
  const parts = [];
  let match;

  while ((match = timePattern.exec(text)) !== null) {
    // Add text before the timestamp
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    // Get the timestamp without brackets (from any of the capture groups)
    const timestamp = (match[1] || match[2] || match[3] || match[4]).replace(
      /\s+/g,
      ''
    );

    // Add the timestamp component
    parts.push(
      <TimestampButton
        key={`timestamp-${match.index}`}
        timeStr={timestamp}
        onTimeClick={onTimeClick}
      />
    );

    lastIndex = match.index + match[0].length;
  }

  // Add remaining text
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return <>{parts}</>;
}
