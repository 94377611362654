import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../iconify';

const Loader = ({ label = 'Loading...', variant = 'default' }) => {
  const theme = useTheme();

  const loaderIcon =
    variant === 'spinner'
      ? {
          icon: 'line-md:loading-twotone-loop',
          sx: {
            color: '#D4FF51',
            animation: 'spin 1s linear infinite',
            '@keyframes spin': {
              '0%': { transform: 'rotate(0deg)' },
              '100%': { transform: 'rotate(360deg)' },
            },
          },
        }
      : {
          icon: 'svg-spinners:blocks-wave',
          sx: { color: '#D4FF51' },
        };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      p={2}
    >
      <Iconify
        icon={loaderIcon.icon}
        width={50}
        height={50}
        sx={loaderIcon.sx}
      />
      <Typography
        variant="h6"
        sx={{ ml: 2, color: theme.palette.text.primary }}
      >
        {label}
      </Typography>
    </Box>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'spinner']),
};

export default Loader;
