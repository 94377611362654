import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  Container,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQAccordion = ({ faqs }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container sx={{ marginTop: 2 }}>
      <Typography variant="h6" gutterBottom component="div">
        FAQ
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ boxShadow: 'none', border: 'none' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography variant="body1">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

FAQAccordion.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FAQAccordion;
