import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
  Popover,
  MenuItem,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { renderWithClickableTimestamps } from '../../utils/timestampUtils';
import { apiRequest } from '../../api/api';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import PromptDialog from '../dialog/PromptDialog';
import Iconify from '../iconify';
import WundaIconButton from './WundaIconButton';

const WundaTranscript = forwardRef(
  (
    {
      transcript,
      sessionName,
      sessionId,
      isSharedView,
      showTranscriptCard,
      isActive,
      onSuccess,
      onError,
      onDelete,
      setShowListenCard,
      setSelectedDialogue,
      generateInsightsCondition,
      onGenerateInsights,
      demoForAll,
      sessionData,
    },
    ref
  ) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);

    const handleDeleteConfirm = () => {
      setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
      setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmDialog = async () => {
      setOpenDeleteDialog(false);
      if (!transcript?._id) return;

      setIsLoading(true);
      try {
        //await apiRequest('DELETE', `transcript/${transcript._id}`);
        logEvent(analytics, 'delete_transcript', {
          transcriptId: transcript._id,
          method: 'session_detail',
        });
        onSuccess('Successfully deleted a transcript!');
        if (typeof onDelete === 'function') {
          onDelete();
        }
      } catch (error) {
        console.error(`Error deleting transcript ${transcript._id}`, error);
        if (typeof onError === 'function') {
          onError(`Error deleting a transcript! ${error.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    };

    const handleTimeSelect = (dialogueItem) => {
      if (dialogueItem) {
        setSelectedDialogue(dialogueItem);
        setShowListenCard(true);
        setOpenCard('recording');
        const timeInSeconds = dialogueItem.startTime;
        const audioPlayer = document.querySelector('iframe');
        if (audioPlayer) {
          audioPlayer.contentWindow.postMessage(
            { type: 'seek', time: timeInSeconds },
            '*'
          );
        }
      }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDownloadClick = (event) => {
      setDownloadAnchorEl(event.currentTarget);
    };

    const handleDownloadClose = () => {
      setDownloadAnchorEl(null);
    };

    const handleDownloadFormat = async (format) => {
      try {
        const { _id } = transcript;
        const fileExtension = format.toLowerCase();

        const response = await apiRequest(
          'GET',
          `/transcript/download/${_id}`,
          {
            params: { format: fileExtension },
            responseType: 'blob',
          }
        );
        const downloadUrl = URL.createObjectURL(response);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `Transcript - ${sessionName}.${fileExtension}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(downloadUrl);

        logEvent(analytics, 'download_transcript', {
          sessionId,
          method: 'session_detail',
          format: fileExtension,
        });

        onSuccess({
          message: `Successfully downloaded the transcript as ${fileExtension.toUpperCase()}!`,
          severity: 'success',
        });
      } catch (error) {
        console.error('Error downloading transcript:', error);
        onError(`Error downloading transcript! ${error.message}`);
      } finally {
        handleDownloadClose();
      }
    };

    useImperativeHandle(ref, () => ({
      handleDownloadClick,
      handleDeleteConfirm,
      handleTimeSelect,
    }));

    if (!transcript) {
      return (
        <Card sx={{ mb: 2 }}>
          <Alert severity="warning">Transcript has been deleted</Alert>
        </Card>
      );
    }

    return (
      <>
        <Card
          sx={{
            mb: 2,
            backgroundColor: theme.palette.background.paper,
            opacity: isActive ? 1 : 0.7,
            transition: 'opacity 0.3s ease',
          }}
        >
          <CardHeader
            avatar={
              <DescriptionIcon
                sx={{
                  fontSize: 60,
                  color: (theme) =>
                    showTranscriptCard ? 'inherit' : theme.palette.grey[500],
                }}
              />
            }
            action={
              (!isSharedView || sessionData?.shareToken?.transcriptEnabled) && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  {generateInsightsCondition && (
                    <WundaIconButton
                      onClick={onGenerateInsights}
                      tooltip="Generate insights for coachee"
                      size="small"
                      icon="streamline:ai-technology-spark"
                    />
                  )}
                  <WundaIconButton
                    onClick={handleDownloadClick}
                    tooltip="Download"
                    size="small"
                    icon="eva:download-outline"
                  />
                  {!isSharedView && (
                    <WundaIconButton
                      onClick={demoForAll ? null : handleDeleteConfirm}
                      tooltip={
                        demoForAll ? 'Delete (Disabled for demo)' : 'Delete'
                      }
                      size="small"
                      icon="eva:trash-2-outline"
                      disabled={demoForAll}
                    />
                  )}
                </Box>
              )
            }
            title={!isMobile && 'Transcript'}
            subheader={
              <Typography variant="caption">
                {isSharedView && !sessionData?.shareToken?.transcriptEnabled
                  ? 'Transcript not available'
                  : 'Read the transcription of the session'}
              </Typography>
            }
          />
          <CardContent>
            <Alert severity="info" sx={{ mb: 2 }}>
              Please note that this transcription has been generated
              automatically and may not be 100% accurate. Transcription quality
              depends heavily on audio quality and speaker clarity.
            </Alert>
            <Box sx={{ mt: 2 }}>
              {transcript.roleDialogue.map((dialogue, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    borderRadius: 1,
                    bgcolor: 'transparent',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={{
                        fontWeight: 600,
                        fontSize: '0.875rem',
                        lineHeight: 1.5,
                        mb: 0.5,
                      }}
                    >
                      {dialogue.speaker}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        fontSize: '0.75rem',
                        lineHeight: 1.5,
                        display: 'block',
                        mb: 0.5,
                      }}
                    >
                      (
                      {dialogue.startTime
                        ? new Date(dialogue.startTime * 1000)
                            .toISOString()
                            .slice(11, 19)
                        : ''}{' '}
                      -
                      {dialogue.endTime
                        ? new Date(dialogue.endTime * 1000)
                            .toISOString()
                            .slice(11, 19)
                        : ''}
                      )
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      pl: 2,
                      borderLeft: '2px solid',
                      borderColor: 'divider',
                      fontSize: '0.875rem',
                      lineHeight: 1.75,
                      letterSpacing: '0.00938em',
                    }}
                  >
                    {isSharedView
                      ? dialogue.text
                      : renderWithClickableTimestamps(
                          dialogue.text,
                          handleTimeSelect
                        )}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>

        <Popover
          open={Boolean(downloadAnchorEl)}
          anchorEl={downloadAnchorEl}
          onClose={handleDownloadClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => handleDownloadFormat('TXT')}>
            <Iconify icon={'eva:download-outline'} sx={{ mr: 2 }} />
            Download TXT
          </MenuItem>
          <MenuItem onClick={() => handleDownloadFormat('SRT')}>
            <Iconify icon={'eva:download-outline'} sx={{ mr: 2 }} />
            Download SRT
          </MenuItem>
        </Popover>

        <PromptDialog
          open={openDeleteDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this transcript? You cannot undo this action."
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirmDialog}
          isLoading={isLoading}
        />
      </>
    );
  }
);

WundaTranscript.propTypes = {
  transcript: PropTypes.shape({
    _id: PropTypes.string,
    roleDialogue: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        text: PropTypes.string,
        startTime: PropTypes.number,
        endTime: PropTypes.number,
      })
    ),
  }),
  sessionData: PropTypes.shape({
    shareToken: PropTypes.shape({
      transcriptEnabled: PropTypes.bool,
    }),
  }),
  sessionName: PropTypes.string,
  sessionId: PropTypes.string,
  isSharedView: PropTypes.bool,
  showTranscriptCard: PropTypes.bool,
  isActive: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func,
  setShowListenCard: PropTypes.func,
  setSelectedDialogue: PropTypes.func,
  generateInsightsCondition: PropTypes.bool,
  onGenerateInsights: PropTypes.func,
  demoForAll: PropTypes.bool,
};

export default WundaTranscript;
