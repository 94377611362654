import { useState, useEffect, useCallback, useRef } from 'react';
import { apiRequest } from '../api/api';

const useChat = (sessionId, sharedToken = null) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);
  const readerRef = useRef(null);

  useEffect(
    () => () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (readerRef.current) {
        readerRef.current.cancel();
      }
    },
    []
  );

  const sendMessage = useCallback(
    async (message, isRetry = false) => {
      let reader;
      try {
        setIsLoading(true);
        setError(null);

        if (!isRetry) {
          const userMessage = { role: 'user', content: message, isAI: false };
          setMessages((prevMessages) => [...prevMessages, userMessage]);
        }

        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        const chatHistory = messages.map(({ content, isAI }) => ({
          role: isAI ? 'assistant' : 'user',
          content,
        }));

        // Determine if this is an insights report chat based on the URL
        const isInsightsReport = window.location.pathname.includes(
          '/insights-report/shared/'
        );

        const endpoint = isInsightsReport
          ? `/insights-report/${sessionId}/chat/stream/shared/${sharedToken}`
          : sharedToken
            ? `/chat/stream/shared/${sharedToken}`
            : '/chat/stream';

        const requestBody = {
          message,
          chatHistory,
        };

        // Only include sessionId for non-insights report chats
        if (!isInsightsReport) {
          requestBody.sessionId = sessionId;
        }

        const response = await apiRequest('POST', endpoint, {
          data: requestBody,
          signal: abortControllerRef.current.signal,
          responseType: 'stream',
        });

        // Handle non-streaming error responses
        if (!response.ok) {
          let aiErrorMessage;

          if (response.status === 400) {
            try {
              const errorData = await response.json();
              aiErrorMessage =
                errorData.error ||
                "I can only discuss content from this specific session transcript. Could you please ask me something about the conversation we're analyzing?";
            } catch {
              aiErrorMessage =
                "I can only discuss content from this specific session transcript. Could you please ask me something about the conversation we're analyzing?";
            }
          } else {
            switch (response.status) {
              case 401:
                aiErrorMessage =
                  'I apologize, but I need to verify your identity again. Could you please refresh the page and sign in?';
                break;
              case 404:
                aiErrorMessage =
                  "I'm having trouble accessing this session's information. Could you try refreshing the page?";
                break;
              case 429:
                aiErrorMessage =
                  'I need a moment to catch my breath. Could you try again in a minute?';
                break;
              default:
                aiErrorMessage =
                  "I'm having a bit of trouble processing your request right now. Could you try rephrasing your question?";
            }
          }

          // Add AI error message to chat
          setMessages((prev) => [
            ...prev,
            {
              content: aiErrorMessage,
              isAI: true,
              role: 'assistant',
              isError: true,
            },
          ]);
          setIsLoading(false);
          return;
        }

        reader = response.body.getReader();
        readerRef.current = reader;
        const decoder = new TextDecoder();
        let aiResponse = '';

        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              setIsLoading(false);
              break;
            }

            const chunk = decoder.decode(value);
            const lines = chunk.split('\n').filter((line) => line.trim());

            for (const line of lines) {
              if (line === '[DONE]' || line === 'data: [DONE]') {
                setIsLoading(false);
                return;
              }

              try {
                // Handle streaming error messages
                if (line.includes('"error":')) {
                  try {
                    const errorData = JSON.parse(
                      line.startsWith('data: ') ? line.slice(6) : line
                    );
                    const aiErrorMessage =
                      errorData.error ||
                      "I'm having trouble understanding that. Could you try rephrasing your question?";
                    setMessages((prev) => [
                      ...prev,
                      {
                        content: aiErrorMessage,
                        isAI: true,
                        role: 'assistant',
                        isError: true,
                      },
                    ]);
                    setIsLoading(false);
                    return;
                  } catch (parseError) {
                    console.error('Error parsing error message:', parseError);
                    const aiErrorMessage =
                      'An error occurred while processing your request.';
                    setMessages((prev) => [
                      ...prev,
                      {
                        content: aiErrorMessage,
                        isAI: true,
                        role: 'assistant',
                        isError: true,
                      },
                    ]);
                    setIsLoading(false);
                    return;
                  }
                }

                // Strip "data: " prefix if present and parse normal message
                const jsonStr = line.startsWith('data: ')
                  ? line.slice(6)
                  : line;
                const data = JSON.parse(jsonStr);

                if (data.content) {
                  aiResponse += data.content;

                  // Update the AI message as chunks arrive
                  setMessages((prev) => {
                    const lastMessage = prev[prev.length - 1];
                    if (lastMessage && lastMessage.isAI) {
                      return [
                        ...prev.slice(0, -1),
                        { content: aiResponse, isAI: true, role: 'assistant' },
                      ];
                    } else {
                      return [
                        ...prev,
                        { content: aiResponse, isAI: true, role: 'assistant' },
                      ];
                    }
                  });
                }
              } catch (error) {
                if (error.name === 'AbortError') {
                  // Mark the message as stopped
                  setMessages((prev) => {
                    const lastMessage = prev[prev.length - 1];
                    if (lastMessage && lastMessage.isAI) {
                      return [
                        ...prev.slice(0, -1),
                        { ...lastMessage, stopped: true },
                      ];
                    }
                    return prev;
                  });
                  setIsLoading(false);
                  return;
                }
                // Only log parsing errors if they're not error messages
                if (!line.includes('"error":')) {
                  console.error('Error parsing stream data:', error);
                }
                // If it's an error message, throw it to be handled
                if (error.message !== 'Unexpected end of JSON input') {
                  throw error;
                }
              }
            }
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            // Mark the message as stopped
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage && lastMessage.isAI) {
                return [
                  ...prev.slice(0, -1),
                  { ...lastMessage, stopped: true },
                ];
              }
              return prev;
            });
            setIsLoading(false);
          } else {
            throw error;
          }
        } finally {
          if (reader) {
            try {
              reader.releaseLock();
            } catch (error) {
              console.error('Error releasing reader lock:', error);
            }
            readerRef.current = null;
          }
        }
      } catch (error) {
        console.error('Chat error:', error);
        setError(error.message);
        setIsLoading(false);
      }
    },
    [sessionId, messages, sharedToken]
  );

  const retryLastMessage = useCallback(
    (messageIndex) => {
      // Find the user message that corresponds to the AI message being retried
      const userMessageIndex = messageIndex - 1;
      if (
        userMessageIndex >= 0 &&
        messages[userMessageIndex] &&
        !messages[userMessageIndex].isAI
      ) {
        const userMessage = messages[userMessageIndex];

        // Keep only messages up to and including the user message being retried
        setMessages((prev) => prev.slice(0, userMessageIndex + 1));

        // Send the message without adding it to messages again
        sendMessage(userMessage.content, true);
      }
    },
    [messages, sendMessage]
  );

  const stopResponse = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        // Force close the reader if it exists
        if (readerRef.current) {
          await readerRef.current.cancel();
        }
        // Mark the last AI message as stopped
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.isAI) {
            return [...prev.slice(0, -1), { ...lastMessage, stopped: true }];
          }
          return prev;
        });
        abortControllerRef.current = null;
        readerRef.current = null;
        setIsLoading(false);
      }
    } catch (error) {
      // Ignore AbortError as it's expected
      if (error.name !== 'AbortError') {
        console.error('Error stopping response:', error);
      }
      // Ensure we clean up even if there's an error
      abortControllerRef.current = null;
      readerRef.current = null;
      setIsLoading(false);
    }
  }, []);

  return {
    messages,
    isLoading,
    error,
    sendMessage,
    retryLastMessage,
    stopResponse,
  };
};

export default useChat;
