import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Alert,
  Chip,
  Tooltip,
} from '@mui/material';
// components
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import PromptDialog from '../components/dialog/PromptDialog';
import Iconify from '../components/iconify';
import StatusProgressLabel from '../components/wundamental/StatusProgressLabel';
import Scrollbar from '../components/scrollbar';
import GenericLoader from '../components/loader/GenericLoader';
import WundaButton from '../components/wundamental/WundaButton';
import ExistingTranscriptionUploadForm from '../sections/@dashboard/transcript/ExistingTranscriptUploadForm';
import GenerateReportForm from '../sections/@dashboard/transcript/GenerateReportForm';
import TranscriptEditForm from '../sections/@dashboard/transcript/TranscriptEditForm';
import { AuthContext } from '../providers/AuthProvider';
// sections
import {
  TranscriptListToolbar,
  TranscriptListHead,
} from '../sections/@dashboard/transcript';
import { apiRequest } from '../api/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'indicators', label: '', alignRight: false },
  { id: 'generateReport', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'tags', label: 'Tags', alignRight: false },
];
const FILTER_ENABLED = true;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (item) =>
        item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.tags &&
          item.tags.some(
            (tag) => tag.toLowerCase().indexOf(query.toLowerCase()) !== -1
          ))
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TranscriptsPage() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { currentUser, setAdvancedBalanceMode, refreshBalance } =
    useContext(AuthContext);
  const snackbarRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('uploadedAt');

  const [filterName, setFilterName] = useState('');

  const [showSessionRecords, setShowSessionRecords] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [transcripts, setTranscripts] = useState([]);
  const [confirmItemId, setConfirmItemId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [loading, setLoading] = useState(true);
  const [highlightFirstRow, setHighlightFirstRow] = useState(false);
  const [highlightApplied, setHighlightApplied] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage
  );
  const [showForm, setShowForm] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openRetryDialog, setOpenRetryDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    setAdvancedBalanceMode(true);
  }, [setAdvancedBalanceMode]);

  const handleStatusChange = useCallback((newStatus, transcriptId) => {
    setTranscripts((transcripts) =>
      transcripts.map((transcript) =>
        transcript._id === transcriptId
          ? { ...transcript, status: newStatus }
          : transcript
      )
    );
  }, []);

  const handleOpenMenu = (itemId, event) => {
    const selectedRow = transcripts.find((row) => row._id === itemId);
    setCurrentRow(selectedRow);
    setConfirmItemId(itemId);
    setPopoverOpen(event.currentTarget);
  };

  const handleOpenEdit = (row) => {
    setSelectedRow(row);
    setShowEditModal(true);
  };

  const handleCloseMenu = () => {
    setPopoverOpen(null);
    setCurrentRow(null); // Clear the currently selected row
    setConfirmItemId(null); // Clear the delete item id
  };

  const handleOpenSession = async (row) => {
    navigate(`/home/sessions/${row.sessionID}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = transcripts.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleSelectRowClick = (event, _id) => {
  //   const selectedIndex = selected.indexOf(_id);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, _id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleNewTranscriptSuccess = () => {
    // Refresh the table data
    showSuccess('Successfully added new transcript!');
    fetchTranscripts();
  };

  const handleNewExistingTranscriptionClick = () => {
    setShowForm(!showForm);
  };

  const handleGenerateReportClick = (row) => {
    setSelectedRow(row);
    setShowReportForm(!showReportForm);
  };

  const handleCloseReportForm = () => {
    setShowReportForm(false);
    handleCloseMenu();
  };

  const handleGenerateReportSuccess = () => {
    // showSuccess('Successfully finished report generation! Check your inbox!');
    // fetchTranscripts();

    // In your event handler where you navigate after a successful action
    const successMessage =
      'Report generation successfully started! See the status of the enqueued report above. A copy will be sent to your email once the job finishes!';
    navigate('/home/reports', {
      state: { highlightFirstRow: true, successMessage },
    });
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    handleCloseMenu();
  };

  const handleEditReportSuccess = () => {
    showSuccess('Successfully updated the transcript!');
    fetchTranscripts();
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(true);
  };

  const handleCancelCancel = () => {
    handleCloseMenu();
    setOpenCancelDialog(false);
  };

  const handleRetryConfirm = () => {
    setOpenRetryDialog(true);
  };

  const handleRetryCancel = () => {
    handleCloseMenu();
    setOpenRetryDialog(false);
  };

  const handleRetryConfirmDialog = async () => {
    setLoading(true);
    try {
      await apiRequest('POST', `transcript/retry/${confirmItemId}`);
      showSuccess('Successfully triggered retry of the transcription!');
    } catch (error) {
      console.error(`Error retrying transcription ${confirmItemId}`, error);
      setErrorMessage(`Error retrying the transcription! ${error.message}`);
    } finally {
      setOpenRetryDialog(false);
      handleCloseMenu();
      setLoading(false);
      fetchTranscripts();
    }
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteCancel = () => {
    handleCloseMenu();
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmDialog = async () => {
    setLoading(true);
    try {
      await apiRequest('DELETE', `transcript/${confirmItemId}`);
      fetchTranscripts();
      showSuccess('Successfully deleted a transcript!');
    } catch (error) {
      console.error(`Error deleting transcript ${confirmItemId}`, error);
      setErrorMessage('Error deleting a transcript!');
    } finally {
      setOpenDeleteDialog(false);
      setOpenCancelDialog(false);
      handleCloseMenu();
      setLoading(false);
    }
  };

  const handleDownload = async (row) => {
    try {
      const { _id, name, format } = row;
      const fileExtension = format;

      const response = await apiRequest('GET', `/transcript/download/${_id}`, {
        responseType: 'blob',
      });
      const downloadUrl = URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Transcript - ${name}.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(downloadUrl);
      showSuccess(`Successfully downloaded the transcript!`);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error downloading a transcript!');
    } finally {
      handleCloseMenu();
    }
  };

  const fetchTranscripts = useCallback(async () => {
    setLoading(true);

    try {
      const userId = currentUser.DBuser?._id;
      if (!userId) {
        setErrorMessage(
          `Error fetching transcripts! User not verified by server! Try again later.`
        );
        return;
      }
      const filter = {
        showSessionRecords,
      };
      const response = await apiRequest('GET', `transcript/list/${userId}`, {
        params: filter,
      });

      setTranscripts(response);
    } catch (error) {
      console.error('Error fetching transcripts:', error);
      setErrorMessage(`Error fetching transcripts! ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentUser, setTranscripts, setLoading, showSessionRecords]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: fullScreen ? '100%' : '50%', // If screen size is small, width is 100%, else it's 50%.
    bgcolor: 'background.paper',
    border: '2px solid #365a47',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  useEffect(() => {
    console.log('TranscriptsPage.js. useEffect() call');
    const highlightFirstRow = location.state?.highlightFirstRow;

    fetchTranscripts();

    if (highlightFirstRow && !highlightApplied) {
      setHighlightFirstRow(true);
      setHighlightApplied(true);
      if (successMessage) {
        showSuccess(successMessage);
        setSuccessMessage(null);
      }
      // Optionally, remove the highlight after some time
      setTimeout(() => setHighlightFirstRow(false), 10000);
    }
  }, [fetchTranscripts, location.state, highlightApplied, successMessage]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transcripts.length) : 0;

  const handleIncludeSessionRecordsChange = (newValue) => {
    setShowSessionRecords(newValue);
  };

  const filteredTranscripts = applySortFilter(
    transcripts,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredTranscripts.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Transcripts | Wundamental </title>
      </Helmet>

      <Container maxWidth={false}>
        {loading && <GenericLoader />}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {showForm ? 'Upload Existing Transcript' : 'Your Transcripts'}
          </Typography>
          {!showForm && (
            <WundaButton
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleNewExistingTranscriptionClick}
            >
              New Transcript
            </WundaButton>
          )}
        </Stack>

        {showForm ? (
          <ExistingTranscriptionUploadForm
            onCancel={handleCloseForm}
            currentUser={currentUser}
            onSuccess={handleNewTranscriptSuccess}
          />
        ) : (
          <Card>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <TranscriptListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              isEnabled={FILTER_ENABLED}
              onIncludeSessionRecordsChange={handleIncludeSessionRecordsChange}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TranscriptListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={transcripts.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredTranscripts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const {
                          _id,
                          name,
                          status,
                          uploadedAt,
                          createdAt,
                          tags,
                          source,
                          sessionID,
                        } = row;
                        const isRowHighlighted =
                          highlightFirstRow && index === 0;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            style={
                              isRowHighlighted
                                ? {
                                    backgroundColor:
                                      theme.palette.secondary.main,
                                  }
                                : null
                            }
                          >
                            <TableCell
                              align="right"
                              padding="none"
                              sx={{
                                position: 'sticky',
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 2,
                              }}
                            >
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(_id, event)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              align="center"
                              padding="none"
                              sx={{
                                position: 'sticky',
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 2,
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                {source === 'TRANSCRIBE' ? (
                                  <Tooltip title="Transcribed from audio">
                                    <Iconify icon={'eva:mic-outline'} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Uploaded directly">
                                    <Iconify icon={'eva:upload-outline'} />
                                  </Tooltip>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              padding="none"
                              sx={{
                                position: 'sticky',
                                right: 0,
                                backgroundColor: theme.palette.background.paper,
                              }}
                            >
                              {sessionID && status === 'COMPLETED' ? (
                                <WundaButton
                                  variant="outlined"
                                  size="small"
                                  title="Open"
                                  startIcon={
                                    <Iconify icon="ion:open-outline" />
                                  }
                                  onClick={() => {
                                    handleOpenSession(row);
                                  }}
                                >
                                  View session
                                </WundaButton>
                              ) : (
                                !(
                                  status === 'IN_PROGRESS' ||
                                  status === 'PENDING' ||
                                  status === 'FAILED'
                                ) && (
                                  <WundaButton
                                    variant="outlined"
                                    size="small"
                                    title="Generate report"
                                    startIcon={
                                      <Iconify icon="streamline:ai-technology-spark" />
                                    }
                                    onClick={() =>
                                      handleGenerateReportClick(row)
                                    }
                                  >
                                    Report
                                  </WundaButton>
                                )
                              )}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <StatusProgressLabel
                                key={_id}
                                status={status}
                                pollingStatuses={['PENDING', 'IN_PROGRESS']}
                                url={`transcript/status/${_id}`}
                                onStatusChange={(newStatus) =>
                                  handleStatusChange(newStatus, _id)
                                }
                                onReady={() => refreshBalance()}
                                pollingInterval={30}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {createdAt
                                ? new Date(createdAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false,
                                    }
                                  )
                                : new Date(uploadedAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false,
                                    }
                                  )}
                            </TableCell>
                            <TableCell>
                              <Box sx={{ maxWidth: '150px' }}>
                                {tags &&
                                  tags.map((tag, index) => (
                                    <Chip key={index} label={tag} />
                                  ))}
                              </Box>
                              <Tooltip title={tags && tags.join(', ')}>
                                <Box sx={{ maxWidth: '150px' }}>
                                  {/* tags */}
                                </Box>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={transcripts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Modal
        open={showReportForm}
        onClose={handleCloseReportForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <GenerateReportForm
            currentUser={currentUser}
            row={selectedRow}
            onCancel={handleCloseReportForm}
            onSuccess={handleGenerateReportSuccess}
          />
        </Box>
      </Modal>

      <PromptDialog
        open={openDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this item?"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openCancelDialog}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this report generation?"
        onCancel={handleCancelCancel}
        onConfirm={handleDeleteConfirmDialog}
      />

      <PromptDialog
        open={openRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry this transcription?"
        onCancel={handleRetryCancel}
        onConfirm={handleRetryConfirmDialog}
      />

      <Modal open={showEditModal} onClose={handleCloseEdit}>
        <Box sx={modalStyle}>
          <TranscriptEditForm
            row={selectedRow}
            onCancel={handleCloseEdit}
            onSuccess={handleEditReportSuccess}
          />
        </Box>
      </Modal>

      <Popover
        open={Boolean(popoverOpen)}
        anchorEl={popoverOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* TODO: Add transcript edit option */}
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Generate report
        </MenuItem> */}

        {currentRow && currentRow.status === 'PENDING' && (
          <MenuItem onClick={handleCancelConfirm}>
            <Iconify icon={'eva:close-outline'} sx={{ mr: 2 }} />
            Cancel
          </MenuItem>
        )}

        {currentRow && currentRow.status === 'FAILED' && (
          <MenuItem onClick={handleRetryConfirm}>
            <Iconify icon={'eva:refresh-outline'} sx={{ mr: 2 }} />
            Retry
          </MenuItem>
        )}

        {currentRow && currentRow.sessionID ? (
          <MenuItem onClick={() => handleOpenSession(currentRow)}>
            <Iconify icon={'ion:open-outline'} sx={{ mr: 2 }} />
            Open
          </MenuItem>
        ) : (
          currentRow &&
          (currentRow.status === 'COMPLETED' ||
            currentRow.status === 'UPLOADED') && (
            <MenuItem onClick={() => handleGenerateReportClick(currentRow)}>
              <Iconify icon={'streamline:ai-technology-spark'} sx={{ mr: 2 }} />
              Report
            </MenuItem>
          )
        )}

        {currentRow &&
          (currentRow.status === 'COMPLETED' ||
            currentRow.status === 'UPLOADED') && (
            <MenuItem onClick={() => handleDownload(currentRow)}>
              <Iconify icon={'eva:download-outline'} sx={{ mr: 2 }} />
              Download
            </MenuItem>
          )}

        <MenuItem onClick={() => handleOpenEdit(currentRow)}>
          <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {currentRow &&
          currentRow.status !== 'IN_PROGRESS' &&
          currentRow.status !== 'PENDING' && (
            <MenuItem
              sx={{ color: 'error.main' }}
              onClick={handleDeleteConfirm}
            >
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          )}
      </Popover>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
