import { apiRequest } from '../api/api';

const promptLabService = {
  testTemplateSection: async (
    sessionId,
    templateId,
    templateSectionId,
    userPrompt,
    params
  ) => {
    const response = await apiRequest('POST', '/prompt-lab/test-section', {
      data: {
        sessionId,
        templateId,
        templateSectionId,
        userPrompt,
        params,
      },
      responseType: 'stream',
    });
    return response;
  },
};

export default promptLabService;
