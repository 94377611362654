import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Iconify from '../iconify';

const TextEditorDialog = ({
  open,
  onClose,
  onSave,
  title,
  initialValue = '',
  isJsonMode = false,
  fullWidth = true,
  maxWidth = 'md',
  templateId = '',
  templateSectionId = '',
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [copySnackbar, setCopySnackbar] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValue(newValue);

      if (isJsonMode && newValue.trim()) {
        try {
          JSON.parse(newValue);
          setError(null);
        } catch (e) {
          setError('Invalid JSON format');
        }
      } else {
        setError(null);
      }
    },
    [isJsonMode]
  );

  const handleSave = useCallback(() => {
    if (isJsonMode) {
      try {
        JSON.parse(value);
        onSave(value);
        onClose();
      } catch (e) {
        setError('Invalid JSON format');
      }
    } else {
      onSave(value);
      onClose();
    }
  }, [value, isJsonMode, onSave, onClose]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopySnackbar(true);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleTestPrompt = useCallback(() => {
    if (templateId && templateSectionId) {
      navigate(
        `/home/wundamental/prompt-lab/${templateId}/${templateSectionId}`,
        {
          state: { returnTo: window.location.pathname },
        }
      );
    }
  }, [templateId, templateSectionId, navigate]);

  const showTestPrompt = Boolean(templateId && templateSectionId);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          height: '67vh',
          minHeight: '67vh',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleCopy} size="small">
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Box sx={{ flex: 1, p: 2, height: 'calc(100% - 32px)' }}>
          <TextField
            multiline
            fullWidth
            value={value}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            variant="outlined"
            spellCheck={false}
            InputProps={{
              sx: {
                height: '100%',
                '& textarea': {
                  height: '100% !important',
                  overflowY: 'scroll !important',
                  resize: 'none',
                },
              },
            }}
            sx={{ height: '100%' }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {showTestPrompt && (
            <Button
              onClick={handleTestPrompt}
              variant="contained"
              color="secondary"
              startIcon={<Iconify icon="mdi:flask-outline" />}
            >
              Test Prompt
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={onClose} color="inherit" sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={!!error}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
      <Snackbar
        open={copySnackbar}
        autoHideDuration={2000}
        onClose={() => setCopySnackbar(false)}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Dialog>
  );
};

TextEditorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  isJsonMode: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  templateId: PropTypes.string,
  templateSectionId: PropTypes.string,
};

export default TextEditorDialog;
