import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  CircularProgress,
  IconButton,
  Chip,
  Typography,
  CardHeader,
  Menu,
  MenuItem,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from './ChatMessage';
import { findClosestDialogue } from '../../utils/timestampUtils';
import WundaTranscriptPreview from '../wundamental/WundaTranscriptPreview';
import WundaIconButton from '../wundamental/WundaIconButton';

const EXAMPLE_MESSAGES = [
  // Key Takeaways and Summaries
  'What were the most important points discussed?',
  'What decisions or agreements were made during the conversation?',
  'What unresolved questions or issues remain?',

  // Action and Next Steps
  'What follow-up actions were identified for each participant?',
  'What priorities should be addressed next?',
  'Who is responsible for which tasks or decisions moving forward?',

  // Communication and Dynamics
  'What communication styles were most evident?',
  'How effectively did participants exchange ideas?',
  'What moments of alignment or disagreement were notable?',
  'What power dynamics or roles were evident in the interaction?',
  'How well did the participants build rapport or trust?',
  'Were there moments of tension or heightened collaboration? If so, what triggered them?',

  // Tone and Engagement
  'What was the overall tone of the conversation?',
  'How engaged were the participants throughout the meeting?',
  'What topics seemed to energize or demotivate participants?',
  'What emotions were most prevalent in the conversation?',
  'Were there any shifts in mood or energy levels during specific topics?',
  'Did the participant seem confident, uncertain, or defensive at any point?',

  // Strengths and Improvements
  'What strengths were demonstrated by the participants?',
  'What opportunities for improvement emerged?',
  'How could the conversation have been more effective?',
  'What key strengths could be leveraged based on this discussion?',
  'Which competencies or skills were demonstrated during the conversation?',
  'How well did the participant articulate their ideas?',

  // Insights and Broader Implications
  'What broader trends or patterns can be drawn from this conversation?',
  'What potential risks or opportunities were highlighted?',
  'What underlying motivations or concerns were hinted at but not explicitly stated?',
  'How might the outcomes of this conversation impact future decisions?',
  'What biases, if any, were evident in the dialogue?',

  // Innovation and Strategy
  'What creative solutions or ideas emerged during the discussion?',
  'What innovative opportunities could be derived from the topics discussed?',
  'How could this conversation inform strategic planning?',

  // Follow-Up Questions
  'What questions could help clarify key points from this discussion?',
  'What additional information might be helpful to review?',
  'What topics should be explored further in future conversations?',
  'What questions could have been asked to drive the conversation further?',
  'Which ideas had the most potential for further exploration or action?',
];

const Chat = ({
  messages,
  isLoading,
  sendMessage,
  retryLastMessage,
  stopResponse,
  roleDialogue = [],
  onTimeSelect = () => {},
  preventAutoScroll = false,
}) => {
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const getRandomPrompts = () => {
    const shuffled = [...EXAMPLE_MESSAGES].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const [inputValue, setInputValue] = useState('');
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialPrompts] = useState(getRandomPrompts());
  const [menuPrompts, setMenuPrompts] = useState([]);

  const scrollToBottom = useCallback(() => {
    if (!preventAutoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [preventAutoScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Focus input when AI finishes responding
  useEffect(() => {
    if (
      !preventAutoScroll &&
      !isLoading &&
      messages.length > 0 &&
      messages[messages.length - 1].isAI
    ) {
      inputRef.current?.focus();
    }
  }, [isLoading, messages, preventAutoScroll]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    const message = inputRef.current.value.trim();
    if (message && !isLoading) {
      inputRef.current.value = '';
      setInputValue('');
      await sendMessage(message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleTimeClick = (timeStr) => {
    if (!roleDialogue || !onTimeSelect) {
      return;
    }

    const dialogueItem = findClosestDialogue(timeStr, roleDialogue);

    if (dialogueItem) {
      // First show the preview
      setSelectedDialogue(dialogueItem);
      setDialoguePreviewOpen(true);
    }
  };

  const handlePromptClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuPrompts(getRandomPrompts());
  };

  const handlePromptClose = () => {
    setAnchorEl(null);
  };

  const handlePromptSelect = (prompt) => {
    inputRef.current.value = prompt;
    setInputValue(prompt);
    handleSubmit();
    handlePromptClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100vh',
      }}
    >
      <CardHeader
        avatar={
          <ChatIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: 24,
            }}
          />
        }
        title="Chat with AI"
        titleTypographyProps={{
          variant: 'h6',
          color: 'text.primary',
          fontWeight: 600,
        }}
        sx={{
          pb: 1,
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
      />

      {/* Messages Container */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          px: { xs: 1, sm: 2 },
          pt: 2,
          pb: 3,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.grey[300],
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: (theme) => theme.palette.grey[400],
          },
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
              mt: 4,
              mx: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Ask me anything about this conversation
            </Typography>
            {!inputValue.trim() && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  justifyContent: 'center',
                  px: 1,
                  width: '100%',
                }}
              >
                {initialPrompts.slice(0, 5).map((message, index) => (
                  <Chip
                    key={index}
                    label={message}
                    onClick={() => {
                      inputRef.current.value = message;
                      setInputValue(message);
                      handleSubmit();
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'primary.light',
                        color: 'primary.contrastText',
                      },
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        ) : (
          <>
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                isAI={message.isAI}
                messageIndex={index}
                onRetry={
                  message.isAI ? (index) => retryLastMessage(index) : undefined
                }
                onTimeClick={handleTimeClick}
              />
            ))}
          </>
        )}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mb: 2,
            }}
          >
            <Box
              sx={{
                p: 2,
                backgroundColor: 'grey.50',
                borderRadius: '12px',
                borderTopLeftRadius: '4px',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input Container */}
      <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          <WundaIconButton
            icon="eva:bulb-outline"
            tooltip="Sample prompts"
            onClick={handlePromptClick}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': { color: 'primary.main' },
            }}
          />
          <TextField
            fullWidth
            placeholder="Type your message..."
            variant="outlined"
            size="small"
            inputRef={inputRef}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
            multiline
            maxRows={4}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
          {isLoading ? (
            <IconButton
              onClick={stopResponse}
              color="primary"
              sx={{
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'action.hover' },
              }}
            >
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleSubmit}
              color="primary"
              disabled={!inputValue.trim()}
              sx={{
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'action.hover' },
              }}
            >
              <SendIcon />
            </IconButton>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handlePromptClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              mt: 1.5,
              maxHeight: 300,
              width: '320px',
              overflow: 'auto',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'grey.200',
              boxShadow: (theme) => `0 4px 12px 0 ${theme.palette.grey[200]}`,
              '& .MuiMenuItem-root': {
                px: 2,
                py: 1,
                typography: 'body2',
                borderRadius: 0.75,
                borderBottom: '1px solid',
                borderColor: 'grey.100',
                '&:last-child': {
                  borderBottom: 'none',
                },
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                },
              },
            },
          }}
        >
          {menuPrompts.map((prompt, index) => (
            <MenuItem
              key={index}
              onClick={() => handlePromptSelect(prompt)}
              sx={{
                color: 'text.secondary',
                whiteSpace: 'normal',
                minHeight: 'unset',
                '&:not(:last-of-type)': {
                  mb: 0.5,
                },
              }}
            >
              {prompt}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* Dialogue Preview Modal */}
      <WundaTranscriptPreview
        open={dialoguePreviewOpen}
        onClose={() => setDialoguePreviewOpen(false)}
        onGoToTranscript={() => {
          setDialoguePreviewOpen(false);
          onTimeSelect(selectedDialogue);
        }}
        selectedDialogue={selectedDialogue}
        roleDialogue={roleDialogue}
        onTimeSelect={onTimeSelect}
      />
    </Box>
  );
};

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  retryLastMessage: PropTypes.func.isRequired,
  stopResponse: PropTypes.func.isRequired,
  roleDialogue: PropTypes.array,
  onTimeSelect: PropTypes.func,
  preventAutoScroll: PropTypes.bool,
};

export default Chat;
