import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, List, ListItem, Container } from '@mui/material';
import PropTypes from 'prop-types';

const FileUpload = ({ onDrop, error, acceptedTypes }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      accept: acceptedTypes,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Typography variant="body1" color="error" key={file.path}>
      {errors.map((e) => e.message).join(', ')}
    </Typography>
  ));

  const files = acceptedFiles.map((file) => (
    <ListItem key={file.path}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));

  return (
    <Container>
      <Box
        {...getRootProps({
          sx: {
            width: '100%',
            height: 200,
            border: '1px dashed gray',
            borderRadius: '4px',
            p: 2,
            mb: 2,
            textAlign: 'center',
          },
        })}
      >
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag 'n' drop a file here, or click to select a file
        </Typography>
        {error && (
          <Typography variant="body1" color="error">
            {error.message}
          </Typography>
        )}
        {fileRejectionItems}
      </Box>
      {files.length > 0 && (
        <aside>
          <Typography variant="h6">Files</Typography>
          <List>{files}</List>
        </aside>
      )}
    </Container>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  acceptedTypes: PropTypes.object,
};

export default FileUpload;
