import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Box,
  Paper,
  Tooltip,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  ListItemIcon,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import WundaButton from './WundaButton';
import CheckIcon from '@mui/icons-material/Check';
import GenericSnackbar from '../snackbar/GenericSnackbar';
import { apiRequest } from '../../api/api';
import HistoryIcon from '@mui/icons-material/History';
import { format } from 'date-fns';
import ChatIcon from '@mui/icons-material/Chat';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArticleIcon from '@mui/icons-material/Article';
import GenericLoader from '../loader/GenericLoader';
import Iconify from '../../components/iconify';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontWeight: 'bold',
  padding: '20px',
}));

const EXPIRATION_OPTIONS = [
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 0, label: 'Never expires' },
];

const DEFAULT_SESSION_SETTINGS = {
  chatEnabled: true,
  audioEnabled: true,
  transcriptEnabled: true,
};

const DEFAULT_REPORT_SETTINGS = {
  chatEnabled: true,
  audioEnabled: true,
  transcriptEnabled: true,
};

const LinkSettingsDisplay = ({
  expirationDays,
  isChatEnabled,
  isAudioEnabled,
  isTranscriptEnabled,
}) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
      {expirationDays === null ? 'New Link Settings:' : 'Link Settings:'}
    </Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <LinkIcon fontSize="small" color="action" />
        </ListItemIcon>
        <ListItemText
          primary="Share link configuration:"
          secondary={
            <Box component="span" sx={{ display: 'block' }}>
              {`• Expires in: ${expirationDays === 0 ? 'Never' : `${expirationDays} days`}`}
              <br />
              {`• AI Chat: `}
              <Iconify
                icon={
                  isChatEnabled
                    ? 'eva:checkmark-circle-2-fill'
                    : 'eva:close-circle-fill'
                }
                sx={{
                  width: 16,
                  color: isChatEnabled ? '#00AB55' : '#FF4842',
                  verticalAlign: 'text-bottom',
                }}
              />
              <br />
              {`• Audio Recording: `}
              <Iconify
                icon={
                  isAudioEnabled
                    ? 'eva:checkmark-circle-2-fill'
                    : 'eva:close-circle-fill'
                }
                sx={{
                  width: 16,
                  color: isAudioEnabled ? '#00AB55' : '#FF4842',
                  verticalAlign: 'text-bottom',
                }}
              />
              <br />
              {`• Transcript: `}
              <Iconify
                icon={
                  isTranscriptEnabled
                    ? 'eva:checkmark-circle-2-fill'
                    : 'eva:close-circle-fill'
                }
                sx={{
                  width: 16,
                  color: isTranscriptEnabled ? '#00AB55' : '#FF4842',
                  verticalAlign: 'text-bottom',
                }}
              />
            </Box>
          }
        />
      </ListItem>
    </List>
  </Box>
);

LinkSettingsDisplay.propTypes = {
  expirationDays: PropTypes.number,
  isChatEnabled: PropTypes.bool.isRequired,
  isAudioEnabled: PropTypes.bool.isRequired,
  isTranscriptEnabled: PropTypes.bool.isRequired,
};

const WundaShareDialog = ({
  open,
  onClose,
  title = 'Share',
  showEmailShare = false,
  showLinkShare = false,
  linkShareProps = {},
  emailShareProps = {},
  isLoading: propIsLoading = false,
  onEmailShare,
  onGenerateLink,
  currentLink = '',
  disabled = false,
  disabledMessage,
  error = '',
  confirmEmailShare = true,
  confirmEmailPrompt = 'Are you sure you want to share to {email}?',
  confirmUnsharePrompt = 'Are you sure you want to disable sharing? This will make the current share link unusable.',
  shareToken = null,
  showWarning = '',
  isInsightsReport = false,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getRemainingDays = (expiresAt) => {
    if (!expiresAt) return null;
    const now = new Date();
    const expiry = new Date(expiresAt);
    const diffTime = expiry - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmNewLinkOpen, setConfirmNewLinkOpen] = useState(false);
  const [pendingExpirationDays, setPendingExpirationDays] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [confirmUnshareOpen, setConfirmUnshareOpen] = useState(false);
  const [showShareHistory, setShowShareHistory] = useState(false);
  const [shareHistory, setShareHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [expirationDays, setExpirationDays] = useState(() => {
    if (currentLink) {
      if (shareToken?.expiresAt === null) return 0;
      return getRemainingDays(shareToken?.expiresAt) || 7;
    }
    return 7;
  });
  const [isChatEnabled, setIsChatEnabled] = useState(() => {
    if (currentLink && shareToken) {
      return shareToken.chatEnabled ?? false;
    }
    return isInsightsReport
      ? DEFAULT_REPORT_SETTINGS.chatEnabled
      : DEFAULT_SESSION_SETTINGS.chatEnabled;
  });
  const [isAudioEnabled, setIsAudioEnabled] = useState(() => {
    if (currentLink && shareToken) {
      return shareToken.audioEnabled ?? false;
    }
    return isInsightsReport
      ? DEFAULT_REPORT_SETTINGS.audioEnabled
      : DEFAULT_SESSION_SETTINGS.audioEnabled;
  });
  const [isTranscriptEnabled, setIsTranscriptEnabled] = useState(() => {
    if (currentLink && shareToken) {
      return shareToken.transcriptEnabled ?? false;
    }
    return isInsightsReport
      ? DEFAULT_REPORT_SETTINGS.transcriptEnabled
      : DEFAULT_SESSION_SETTINGS.transcriptEnabled;
  });
  const [includeLink, setIncludeLink] = useState(true);
  const [includePdf, setIncludePdf] = useState(false);
  const [isLoading, setIsLoading] = useState(propIsLoading);
  const [confirmGenerateLinkOpen, setConfirmGenerateLinkOpen] = useState(false);

  const getClosestExpirationOption = (currentDays) => {
    if (!currentDays) return 7; // Default to 7 days if no current days

    // Find the closest option by comparing absolute differences
    return EXPIRATION_OPTIONS.reduce((closest, option) => {
      // Never expires (0) is only chosen if current is also never expires
      if (option.value === 0) return closest;
      if (currentDays === 0) return closest; // Keep previous if current is never expires

      const currentDiff = Math.abs(closest - currentDays);
      const optionDiff = Math.abs(option.value - currentDays);
      return optionDiff < currentDiff ? option.value : closest;
    }, 7); // Start with 7 as default
  };

  useEffect(() => {
    if (currentLink && shareToken) {
      const remainingDays = getRemainingDays(shareToken?.expiresAt);
      const closestOption = getClosestExpirationOption(remainingDays);
      setExpirationDays(closestOption);
    } else {
      setExpirationDays(7); // Default to 7 days for new links
    }
  }, [currentLink, shareToken]);

  useEffect(() => {
    if (currentLink && shareToken) {
      // Ensure we have default values if properties are undefined
      const chatEnabled = shareToken.chatEnabled ?? false;
      const audioEnabled = shareToken.audioEnabled ?? false;
      const transcriptEnabled = shareToken.transcriptEnabled ?? false;

      setIsChatEnabled(chatEnabled);
      setIsAudioEnabled(audioEnabled);
      setIsTranscriptEnabled(transcriptEnabled);
    }
  }, [shareToken, currentLink]);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  const handleExpirationChange = (event) => {
    const newValue = Number(event.target.value);
    setExpirationDays(newValue);
  };

  const handleEmailShare = async () => {
    if (isEmailValid && email) {
      setIsLoading(true);
      try {
        const options = {
          linkEnabled: includeLink,
          attachmentEnabled: includePdf,
        };

        if (emailShareProps?.onShare) {
          await emailShareProps.onShare(email, options);
        } else if (onEmailShare) {
          await onEmailShare(email, options);
        }
        showSuccess(`Successfully shared with ${email}`);
        setEmail('');

        // Fetch and show share history
        setShowShareHistory(false); // Reset first to ensure fresh fetch
        await fetchShareHistory(); // This will fetch and show the history
      } catch (error) {
        console.error('Error sharing via email:', error);
        setError(error.message || 'Failed to share via email');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEmailShareClick = () => {
    if (isEmailValid && email) {
      if (!confirmEmailShare) {
        handleEmailShare();
      } else {
        setConfirmOpen(true);
      }
    }
  };

  const handleGenerateLinkClick = () => {
    if (currentLink) {
      setConfirmNewLinkOpen(true);
      setPendingExpirationDays(expirationDays);
    } else {
      setConfirmGenerateLinkOpen(true);
    }
  };

  const handleConfirmNewLink = () => {
    if (pendingExpirationDays !== null) {
      const shareOptions = {
        chatEnabled: isChatEnabled,
        audioEnabled: isAudioEnabled,
        transcriptEnabled: isTranscriptEnabled,
      };
      onGenerateLink(pendingExpirationDays, shareOptions);
      showSuccess('New share link generated successfully');
    }
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
    setCopySuccess(false);
  };

  const handleCancelNewLink = () => {
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
    // Reset expiration days to current value if there's a link
    if (currentLink) {
      if (shareToken?.expiresAt === null) {
        setExpirationDays(0);
      } else {
        setExpirationDays(getRemainingDays(shareToken?.expiresAt));
      }
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmYes = () => {
    handleEmailShare();
    setConfirmOpen(false);
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleCopyLink = () => {
    if (currentLink) {
      navigator.clipboard.writeText(currentLink);
      setCopySuccess(true);
      showSuccess('Link copied to clipboard');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleUnshareClick = () => {
    setConfirmUnshareOpen(true);
  };

  const handleUnshareConfirmClose = () => {
    setConfirmUnshareOpen(false);
  };

  const handleUnshareConfirm = () => {
    if (linkShareProps.onDisableShare) {
      linkShareProps.onDisableShare();
    }
    // Reset switches to defaults using constants
    const defaults = isInsightsReport
      ? DEFAULT_REPORT_SETTINGS
      : DEFAULT_SESSION_SETTINGS;
    setIsChatEnabled(defaults.chatEnabled);
    setIsAudioEnabled(defaults.audioEnabled);
    setIsTranscriptEnabled(defaults.transcriptEnabled);
    setConfirmUnshareOpen(false);
  };

  const fetchShareHistory = async () => {
    if (!showShareHistory) {
      setLoadingHistory(true);
      try {
        const response = await apiRequest(
          'GET',
          `/insights-report/${emailShareProps.reportId}/share-history`
        );
        // If response is not an array, but has a shareHistory property
        const historyArray = Array.isArray(response)
          ? response
          : response.shareHistory || [];
        setShareHistory(historyArray);
      } catch (error) {
        console.error('Error fetching share history:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load share history',
          severity: 'error',
        });
      } finally {
        setLoadingHistory(false);
      }
    }
    setShowShareHistory(!showShareHistory);
  };

  const handleChatEnabledChange = (e) => {
    setIsChatEnabled(e.target.checked);
  };

  const handleAudioEnabledChange = (e) => {
    setIsAudioEnabled(e.target.checked);
  };

  const handleTranscriptEnabledChange = (e) => {
    setIsTranscriptEnabled(e.target.checked);
  };

  const handleIncludeLinkChange = (e) => {
    const newValue = e.target.checked;
    setIncludeLink(newValue);
    // If trying to disable the last enabled option, enable the other one
    if (!newValue && !includePdf) {
      setIncludePdf(true);
    }
  };

  const handleIncludePdfChange = (e) => {
    const newValue = e.target.checked;
    setIncludePdf(newValue);
    // If trying to disable the last enabled option, enable the other one
    if (!newValue && !includeLink) {
      setIncludeLink(true);
    }
  };

  const handleGenerateLinkConfirm = () => {
    const shareOptions = {
      chatEnabled: isChatEnabled,
      audioEnabled: isAudioEnabled,
      transcriptEnabled: isTranscriptEnabled,
    };
    onGenerateLink(expirationDays, shareOptions);
    showSuccess('Share link generated successfully');
    setCopySuccess(false);
    setConfirmGenerateLinkOpen(false);
  };

  const handleGenerateLinkCancel = () => {
    setConfirmGenerateLinkOpen(false);
  };

  const ShareOptionsGroup = () => (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={isChatEnabled}
            onChange={handleChatEnabledChange}
            disabled={isLoading || disabled}
            size="small"
          />
        }
        label="Enable AI Chat"
      />
      <FormControlLabel
        control={
          <Switch
            checked={isAudioEnabled}
            onChange={handleAudioEnabledChange}
            disabled={isLoading || disabled}
            size="small"
          />
        }
        label="Include Audio Recording"
      />
      <FormControlLabel
        control={
          <Switch
            checked={isTranscriptEnabled}
            onChange={handleTranscriptEnabledChange}
            disabled={isLoading || disabled}
            size="small"
          />
        }
        label="Include Transcript"
      />
    </FormGroup>
  );

  ShareOptionsGroup.propTypes = {};

  const handleDialogClose = () => {
    // Reset switch states to match shareToken values
    if (shareToken) {
      setIsChatEnabled(shareToken.chatEnabled ?? false);
      setIsAudioEnabled(shareToken.audioEnabled ?? false);
      setIsTranscriptEnabled(shareToken.transcriptEnabled ?? false);
    } else {
      // Reset to defaults if no shareToken
      setIsChatEnabled(isInsightsReport ? false : false);
      setIsAudioEnabled(isInsightsReport ? false : true);
      setIsTranscriptEnabled(isInsightsReport ? false : true);
    }
    onClose();
  };

  const getOptionLabel = (option) => {
    if (currentLink && shareToken) {
      const remainingDays = getRemainingDays(shareToken?.expiresAt);
      if (remainingDays && option.value === expirationDays) {
        return `${option.label} (current: ${remainingDays} days)`;
      }
    }
    return option.label;
  };

  useEffect(
    () => () => {
      setEmail('');
      setIsEmailValid(true);
      setConfirmOpen(false);
    },
    []
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="share-dialog-title"
        keepMounted={false}
        disablePortal={false}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: { xs: '95%', sm: '100%' },
            margin: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <StyledDialogTitle id="share-dialog-title">{title}</StyledDialogTitle>
        <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
          {disabled && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {disabledMessage}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {showLinkShare && (
            <Box sx={{ mb: showEmailShare ? 3 : 0 }}>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <LinkIcon color="primary" />
                <Typography variant="subtitle1">Share via link</Typography>
              </Box>
              <Paper
                variant="outlined"
                sx={{
                  p: { xs: 1.5, sm: 2 },
                  mb: 2,
                  '& .MuiTextField-root': {
                    mb: { xs: 1, sm: 2 },
                  },
                }}
              >
                {linkShareProps.isExpired && (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    {linkShareProps.expiredMessage ||
                      'This share link has expired. Please generate a new link.'}
                  </Alert>
                )}
                {currentLink && shareToken ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mb: 2,
                      }}
                    >
                      {isLoading ? (
                        <Box sx={{ p: 2 }}>
                          <GenericLoader
                            label="Please wait..."
                            variant="spinner"
                          />
                        </Box>
                      ) : (
                        <>
                          <TextField
                            fullWidth
                            value={currentLink}
                            placeholder="No link generated yet"
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <Tooltip
                                  title={
                                    linkShareProps.isExpired
                                      ? 'Link expired'
                                      : 'Copy link'
                                  }
                                >
                                  <span>
                                    <IconButton
                                      onClick={handleCopyLink}
                                      disabled={
                                        !currentLink ||
                                        isLoading ||
                                        linkShareProps.isExpired
                                      }
                                      edge="end"
                                    >
                                      {copySuccess ? (
                                        <CheckIcon color="success" />
                                      ) : (
                                        <ContentCopyIcon />
                                      )}
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              ),
                              sx: {
                                pr: 0.5,
                                fontSize: { xs: '0.875rem', sm: '1rem' },
                              },
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                marginBottom: '-1px',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            }}
                            disabled={isLoading || linkShareProps.isExpired}
                          />
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{
                              backgroundColor: 'background.paper',
                              border: 0,
                              borderColor: 'divider',
                              borderTop: 0,
                              px: 1.5,
                              py: 0.75,
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius: 4,
                            }}
                          >
                            {shareToken?.expiresAt === null
                              ? 'This link never expires'
                              : `This link expires on ${new Date(
                                  shareToken?.expiresAt
                                ).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                })} (${getRemainingDays(shareToken?.expiresAt)} days)`}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  isLoading && (
                    <Box sx={{ p: 2 }}>
                      <GenericLoader
                        label="Generating link..."
                        variant="spinner"
                      />
                    </Box>
                  )
                )}

                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel id="expiration-select-label">
                    Link Expiration
                  </InputLabel>
                  <Select
                    labelId="expiration-select-label"
                    value={expirationDays}
                    label="Link Expiration"
                    onChange={handleExpirationChange}
                    disabled={isLoading}
                  >
                    {EXPIRATION_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {getOptionLabel(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 2, sm: 3 },
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={isChatEnabled}
                      onChange={handleChatEnabledChange}
                      disabled={isLoading || disabled}
                      size="small"
                    />
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <ChatIcon
                        sx={{ fontSize: 20, color: 'text.secondary' }}
                      />
                      <Typography variant="body2">AI Chat</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={isAudioEnabled}
                      onChange={handleAudioEnabledChange}
                      disabled={isLoading || disabled}
                      size="small"
                    />
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <VolumeUpIcon
                        sx={{ fontSize: 20, color: 'text.secondary' }}
                      />
                      <Typography variant="body2">Recording</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={isTranscriptEnabled}
                      onChange={handleTranscriptEnabledChange}
                      disabled={isLoading || disabled}
                      size="small"
                    />
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <ArticleIcon
                        sx={{ fontSize: 20, color: 'text.secondary' }}
                      />
                      <Typography variant="body2">Transcript</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 1, sm: 0 },
                    mt: 1,
                  }}
                >
                  {linkShareProps.showDisableShare &&
                    !linkShareProps.isExpired && (
                      <WundaButton
                        size="small"
                        onClick={handleUnshareClick}
                        disabled={isLoading}
                        color="error"
                        variant="outlined"
                        startIcon={<LinkOffIcon />}
                        fullWidth={isMobile}
                      >
                        {linkShareProps.disableShareLabel || 'Disable Sharing'}
                      </WundaButton>
                    )}
                  <WundaButton
                    size="small"
                    onClick={handleGenerateLinkClick}
                    disabled={isLoading}
                    startIcon={<LinkIcon />}
                    variant="contained"
                    fullWidth={isMobile}
                  >
                    {currentLink ? 'Generate New Link' : 'Generate Link'}
                  </WundaButton>
                </Box>
              </Paper>
            </Box>
          )}

          {showEmailShare && (
            <Box>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <EmailIcon color="primary" />
                <Typography variant="subtitle1">Share via email</Typography>
              </Box>
              <Paper
                variant="outlined"
                sx={{
                  p: { xs: 1.5, sm: 2 },
                  mb: 2,
                  '& .MuiTextField-root': {
                    mb: { xs: 1, sm: 2 },
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Email Address"
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid && email !== ''}
                    helperText={
                      !isEmailValid && email !== ''
                        ? 'Invalid email address'
                        : ''
                    }
                    disabled={isLoading}
                    size="small"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: { xs: 'flex-end', sm: 'flex-start' },
                    }}
                  >
                    {emailShareProps.shareHistoryCount > 0 && (
                      <WundaButton
                        onClick={() => {
                          setShowShareHistory(!showShareHistory);
                          if (!showShareHistory) {
                            fetchShareHistory();
                          }
                        }}
                        startIcon={<HistoryIcon />}
                        size="small"
                        variant="outlined"
                        fullWidth={isMobile}
                      >
                        History
                      </WundaButton>
                    )}
                    <WundaButton
                      onClick={handleEmailShareClick}
                      disabled={!isEmailValid || !email || isLoading}
                      startIcon={<EmailIcon />}
                      size="small"
                      variant="contained"
                      fullWidth={isMobile}
                    >
                      Send
                    </WundaButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 2, sm: 3 },
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={includeLink}
                      onChange={handleIncludeLinkChange}
                      disabled={isLoading || disabled}
                      size="small"
                    />
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <LinkIcon
                        sx={{ fontSize: 20, color: 'text.secondary' }}
                      />
                      <Typography variant="body2">Include Link</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={includePdf}
                      onChange={handleIncludePdfChange}
                      disabled={isLoading || disabled}
                      size="small"
                    />
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <ArticleIcon
                        sx={{ fontSize: 20, color: 'text.secondary' }}
                      />
                      <Typography variant="body2">Include PDF</Typography>
                    </Box>
                  </Box>
                </Box>
                {includeLink && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      display: 'block',
                      mt: -1,
                      mb: 2,
                    }}
                  >
                    Uses the link sharing settings above. To change the
                    expiration or features, generate a link with different
                    settings above before sending to email.
                  </Typography>
                )}
                {showShareHistory && (
                  <Box>
                    {loadingHistory ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <Timeline
                        sx={{
                          p: 0,
                          m: 0,
                          '& .MuiTimelineItem-root': {
                            minHeight: 'auto',
                            '&:before': {
                              flex: 0,
                              padding: 0,
                            },
                          },
                          '& .MuiTimelineOppositeContent-root': {
                            flex: 0.3,
                            py: 0.75,
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiTimelineContent-root': {
                            py: 0.75,
                            px: 1,
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiTimelineSeparator-root': {
                            display: 'flex',
                            alignItems: 'center',
                          },
                        }}
                      >
                        {shareHistory.map((share, index) => (
                          <TimelineItem
                            key={`${share.recipient}-${share.sharedAt}-${index}`}
                          >
                            <TimelineOppositeContent
                              sx={{
                                color: 'text.secondary',
                                fontSize: '0.75rem',
                                m: 'auto 0',
                              }}
                            >
                              {share.sharedAt
                                ? format(
                                    new Date(share.sharedAt),
                                    'MMM d, h:mm a'
                                  )
                                : 'Date not available'}
                            </TimelineOppositeContent>

                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  p: 0.5,
                                  m: 0.5,
                                  bgcolor: 'grey.900',
                                  boxShadow: 'none',
                                }}
                              >
                                <Iconify
                                  icon="eva:email-outline"
                                  width={16}
                                  sx={{ color: 'secondary.main' }}
                                />
                              </TimelineDot>
                              {index < shareHistory.length - 1 && (
                                <TimelineConnector
                                  sx={{ bgcolor: 'grey.300' }}
                                />
                              )}
                            </TimelineSeparator>

                            <TimelineContent>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 500,
                                  color: 'text.primary',
                                  fontSize: '0.875rem',
                                }}
                              >
                                {share.recipient}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    )}
                  </Box>
                )}
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            p: { xs: 2, sm: 3 },
            pt: 0,
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {showWarning && (
            <Alert
              severity="warning"
              sx={{
                mb: 2,
                width: '100%',
                '& .MuiAlert-message': {
                  width: '100%',
                },
              }}
            >
              {showWarning}
            </Alert>
          )}
          <Box sx={{ alignSelf: 'flex-end' }}>
            <WundaButton onClick={handleDialogClose} size="small">
              Done
            </WundaButton>
          </Box>
        </DialogActions>
      </Dialog>

      {confirmEmailShare && (
        <Dialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: 2,
              width: { xs: '95%', sm: '100%' },
              margin: { xs: 1, sm: 'auto' },
            },
          }}
        >
          <StyledDialogTitle>Confirm Share</StyledDialogTitle>
          <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
            <DialogContentText className="fs-mask">
              {confirmEmailPrompt.replace('{email}', email)}
            </DialogContentText>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Share Settings:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" color="action" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Email will contain:"
                    secondary={
                      <>
                        {includeLink && includePdf
                          ? 'Share link and PDF attachment'
                          : includeLink
                            ? 'Share link only'
                            : includePdf
                              ? 'PDF attachment only'
                              : 'No content selected'}
                      </>
                    }
                  />
                </ListItem>
                {includeLink && (
                  <>
                    <LinkSettingsDisplay
                      expirationDays={
                        shareToken?.expiresAt === null
                          ? 0
                          : getRemainingDays(shareToken?.expiresAt) ||
                            expirationDays
                      }
                      isChatEnabled={
                        currentLink ? (shareToken?.chatEnabled ?? false) : true
                      }
                      isAudioEnabled={
                        currentLink ? (shareToken?.audioEnabled ?? false) : true
                      }
                      isTranscriptEnabled={
                        currentLink
                          ? (shareToken?.transcriptEnabled ?? false)
                          : true
                      }
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'block',
                        mt: 1,
                        color: 'text.secondary',
                        fontStyle: 'italic',
                      }}
                    >
                      To change link settings, generate a link with proper
                      settings manually first
                    </Typography>
                  </>
                )}
              </List>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: { xs: 2, sm: 3 }, pt: 0 }}>
            <WundaButton
              onClick={handleConfirmClose}
              variant="outlined"
              size="small"
            >
              Cancel
            </WundaButton>
            <WundaButton
              onClick={handleConfirmYes}
              variant="contained"
              size="small"
              autoFocus
            >
              Share
            </WundaButton>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={confirmNewLinkOpen}
        onClose={handleCancelNewLink}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: { xs: '95%', sm: '100%' },
            margin: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <StyledDialogTitle>Generate New Link</StyledDialogTitle>
        <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
          <DialogContentText>
            Are you sure you want to generate a new link? The current share link
            will become unusable.
          </DialogContentText>
          <LinkSettingsDisplay
            expirationDays={pendingExpirationDays}
            isChatEnabled={isChatEnabled}
            isAudioEnabled={isAudioEnabled}
            isTranscriptEnabled={isTranscriptEnabled}
          />
        </DialogContent>
        <DialogActions sx={{ p: { xs: 2, sm: 3 }, pt: 0 }}>
          <WundaButton
            onClick={handleCancelNewLink}
            variant="outlined"
            size="small"
          >
            Cancel
          </WundaButton>
          <WundaButton
            onClick={handleConfirmNewLink}
            variant="contained"
            size="small"
            autoFocus
          >
            Generate
          </WundaButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmUnshareOpen}
        onClose={handleUnshareConfirmClose}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: { xs: '95%', sm: '100%' },
            margin: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <StyledDialogTitle>Disable Sharing</StyledDialogTitle>
        <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
          <DialogContentText>{confirmUnsharePrompt}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: { xs: 2, sm: 3 }, pt: 0 }}>
          <WundaButton
            onClick={handleUnshareConfirmClose}
            variant="outlined"
            size="small"
          >
            Cancel
          </WundaButton>
          <WundaButton
            onClick={handleUnshareConfirm}
            variant="contained"
            color="error"
            size="small"
            autoFocus
          >
            Disable
          </WundaButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmGenerateLinkOpen}
        onClose={handleGenerateLinkCancel}
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: { xs: '95%', sm: '100%' },
            margin: { xs: 1, sm: 'auto' },
          },
        }}
      >
        <StyledDialogTitle>Generate Share Link</StyledDialogTitle>
        <DialogContent sx={{ p: { xs: 2, sm: 3 }, mt: 1 }}>
          <DialogContentText>
            Are you sure you want to generate a share link with these settings?
          </DialogContentText>
          <LinkSettingsDisplay
            expirationDays={expirationDays}
            isChatEnabled={isChatEnabled}
            isAudioEnabled={isAudioEnabled}
            isTranscriptEnabled={isTranscriptEnabled}
          />
        </DialogContent>
        <DialogActions sx={{ p: { xs: 2, sm: 3 }, pt: 0 }}>
          <WundaButton
            onClick={handleGenerateLinkCancel}
            variant="outlined"
            size="small"
          >
            Cancel
          </WundaButton>
          <WundaButton
            onClick={handleGenerateLinkConfirm}
            variant="contained"
            size="small"
            autoFocus
          >
            Generate
          </WundaButton>
        </DialogActions>
      </Dialog>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

WundaShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  showEmailShare: PropTypes.bool,
  showLinkShare: PropTypes.bool,
  linkShareProps: PropTypes.shape({
    onDisableShare: PropTypes.func,
    showDisableShare: PropTypes.bool,
    infoList: PropTypes.array,
    generateNewLinkLabel: PropTypes.string,
    disableShareLabel: PropTypes.string,
    isExpired: PropTypes.bool,
    expiredMessage: PropTypes.string,
    expirationDays: PropTypes.number,
    onExpirationChange: PropTypes.func,
  }),
  emailShareProps: PropTypes.shape({
    reportId: PropTypes.string,
    onShare: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  onEmailShare: PropTypes.func,
  onGenerateLink: PropTypes.func.isRequired,
  currentLink: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  error: PropTypes.string,
  confirmEmailShare: PropTypes.bool,
  confirmEmailPrompt: PropTypes.string,
  confirmUnsharePrompt: PropTypes.string,
  shareToken: PropTypes.shape({
    token: PropTypes.string,
    expiresAt: PropTypes.string,
    chatEnabled: PropTypes.bool,
    audioEnabled: PropTypes.bool,
    transcriptEnabled: PropTypes.bool,
  }),
  showWarning: PropTypes.node,
  isInsightsReport: PropTypes.bool,
};

export default WundaShareDialog;
