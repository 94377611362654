import React from 'react';
import { Card, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../iconify';
import WundaButton from '../wundamental/WundaButton';

const WorkspaceInviteNotification = ({
  workspaceName,
  onAccept,
  onDecline,
}) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2em',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      maxWidth: '500px',
      margin: '1em auto',
    }}
  >
    <Iconify icon={'mdi:account-plus'} width={50} height={50} />
    <Typography variant="h5" gutterBottom>
      Workspace Invitation
    </Typography>
    <Typography variant="body1" gutterBottom align="center">
      You have been invited to join the workspace "{workspaceName}".
    </Typography>
    <Stack direction="row" spacing={2} mt={2}>
      <WundaButton variant="outlined" color="error" onClick={onDecline}>
        Decline
      </WundaButton>
      <WundaButton variant="contained" color="primary" onClick={onAccept}>
        Accept Invitation
      </WundaButton>
    </Stack>
  </Card>
);

WorkspaceInviteNotification.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default WorkspaceInviteNotification;
