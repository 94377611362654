import { useState, useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import {
  TextField,
  Grid,
  Alert,
  Box,
  Chip,
  Typography,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import WundaButton from '../../../components/wundamental/WundaButton';
import { analytics } from '../../../firebase/firebase';
import GenericLoader from '../../../components/loader/GenericLoader';
import { apiRequest } from '../../../api/api';
import TextEditorDialog from '../../../components/common/TextEditorDialog';

const SessionEditForm = ({ sessionId, onCancel, onSuccess }) => {
  const methods = useForm({
    defaultValues: {
      name: '',
      flourisherName: '',
      scheduledStartAt: null,
      sessionContext: '',
    },
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState('');
  const [openContextEditor, setOpenContextEditor] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = methods;

  const sessionContext = watch('sessionContext');

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const sessionData = await apiRequest('GET', `/session/${sessionId}`);

        setValue('sessionId', sessionData._id);
        setValue('name', sessionData.name);
        setValue('flourisherName', sessionData.flourisherName);
        setValue(
          'scheduledStartAt',
          sessionData.scheduledStartAt
            ? dayjs(sessionData.scheduledStartAt)
            : dayjs(sessionData.createdAt)
        );
        setValue('tags', sessionData.tags || []);
        setTags(sessionData.tags || []);
        setValue('sessionContext', sessionData.sessionContext || '');
      } catch (error) {
        console.error('Failed to fetch the session data:', error);
      }
    };

    if (sessionId) {
      fetchSessionData();
    }
  }, [sessionId, setValue]);

  const handleEditSession = async (data) => {
    const {
      sessionId,
      name,
      flourisherName,
      scheduledStartAt,
      tags,
      sessionContext,
    } = data;

    const payload = {
      name,
      flourisherName,
      scheduledStartAt,
      tags,
      sessionContext,
    };

    try {
      setLoading(true);

      await apiRequest('PUT', `/session/${sessionId}`, {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 300000,
      });

      logEvent(analytics, 'edit_session', {
        sessionId,
      });

      setSuccessMessage('Successfully updated the session');
      reset();

      if (onSuccess) {
        onSuccess('Successfully updated the session');
      }

      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      console.error('Failed to update the session:', error);
      setErrorMessage(
        `Error updating the session! ${error.response?.data?.message || error}`
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (tagInputValue.trim() !== '' && !tags.includes(tagInputValue.trim())) {
      setErrorMessage(
        'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
      );
      return;
    }
    handleSubmit(
      async (data) => {
        try {
          await handleEditSession(data);
        } catch (error) {
          setErrorMessage(error.message || 'An unexpected error occurred.');
        }
      },
      (errors) => {
        console.log('Form validation errors:', errors);
        setErrorMessage(
          'There were some errors with your submission. Did you fill all required fields?'
        );
      }
    )();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Session ID: {sessionId}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              className="fs-mask"
              rules={{ required: 'Please name your session' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name *"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="flourisherName"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Client Name"
                  placeholder="Optional. This name would be shown in the insight reports."
                  fullWidth
                  error={!!errors.flourisherName}
                  helperText={errors.flourisherName?.message}
                  className="fs-mask"
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="scheduledStartAt"
                defaultValue={null}
                rules={{ required: 'Session date is required' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <DatePicker
                      label="Session Date"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(newValue) => {
                        // convert date to ISO string before passing to onChange
                        field.onChange(
                          newValue ? newValue.toISOString() : null
                        );
                      }}
                      renderDay={(
                        day,
                        selectedDate,
                        isInCurrentMonth,
                        dayComponent
                      ) => dayComponent}
                      slots={{
                        OpenPickerIcon: () => (
                          <Iconify icon="ic:round-date-range" />
                        ), // Use the Iconify icon here
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ['clear', 'today'],
                        },
                      }}
                    />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.grey[50],
              }}
            >
              <TextField
                label="Tags"
                variant="outlined"
                fullWidth
                placeholder="Enter a tag and press Enter or any non-alphanumeric key"
                value={tagInputValue}
                onChange={(e) => setTagInputValue(e.target.value)}
                onBlur={() => {
                  if (
                    tagInputValue.trim() !== '' &&
                    !tags.includes(tagInputValue.trim())
                  ) {
                    setErrorMessage(
                      'You forgot to add a tag. Please put a cursor in "Tag" field, press Enter or any non-alphanumeric key to add it.'
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onKeyUp={(event) => {
                  const sanitizedValue = event.target.value
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .trim();
                  const isDelimiter =
                    /[^a-zA-Z0-9]/.test(event.key) || event.key === 'Enter';

                  if (isDelimiter && sanitizedValue !== '') {
                    if (!tags.includes(sanitizedValue)) {
                      const newTags = [...tags, sanitizedValue];
                      setTags(newTags);
                      setValue('tags', newTags);
                    }
                    setTagInputValue('');
                    event.target.value = '';
                  }
                }}
              />
              <FormHelperText>
                Enter tags related to the session. Tags help you categorize your
                sessions. Press Enter or any non-alphanumeric key to add a tag.
              </FormHelperText>
              <Box mt={2}>
                {tags &&
                  tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => {
                        const newTags = tags.filter((t) => t !== tag);
                        setTags(newTags);
                        setValue('tags', newTags);
                      }}
                      style={{ marginRight: '5px', marginBottom: '5px' }}
                    />
                  ))}
              </Box>
              <Controller
                name="tags"
                control={control}
                render={() => null}
                defaultValue={[]}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.grey[50],
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <WundaButton
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenContextEditor(true)}
                  startIcon={
                    sessionContext ? (
                      <Iconify icon="mdi:text-box-check" color="success.main" />
                    ) : (
                      <Iconify icon="mdi:text-box-plus-outline" />
                    )
                  }
                >
                  {sessionContext
                    ? 'Edit Session Context'
                    : 'Add Session Context'}
                </WundaButton>
              </Box>
              <FormHelperText>
                Add any additional context about this session that might be
                helpful for analysis (optional)
              </FormHelperText>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <WundaButton
              variant="outlined"
              color="primary"
              onClick={onCancel}
              sx={{ mr: 2 }}
            >
              Cancel
            </WundaButton>
            <WundaButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Save
            </WundaButton>
          </Grid>
        </Grid>
        <Box mt={2}>
          {loading && <GenericLoader />}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
        <Controller
          name="sessionContext"
          control={control}
          render={() => null}
        />
        <TextEditorDialog
          open={openContextEditor}
          onClose={() => setOpenContextEditor(false)}
          onSave={(value) => {
            setValue('sessionContext', value);
            setOpenContextEditor(false);
          }}
          title="Session Context"
          initialValue={sessionContext || ''}
        />
      </form>
    </FormProvider>
  );
};

SessionEditForm.propTypes = {
  sessionId: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default SessionEditForm;
