import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import ErrorMessage from '../components/alert/ErrorMessage';
import GenericLoader from '../components/loader/GenericLoader';
import EmailSigninConfirm from '../components/wundamental/EmailSigninConfirm';
// sections
import SignupForm from '../sections/auth/SignupForm';
import { apiRequest } from '../api/api';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SignupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { referralCode } = useParams();
  const { email, fullName, firebaseUid } = location.state || {};
  const [formSubmitted, setFormSubmitted] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const isGoogleSignup = !!(fullName && email);
  const snackbarRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkReferralCode = async () => {
      try {
        const response = await apiRequest('GET', `/referral/${referralCode}`, {
          usePublicApiKey: true,
        });
        if (!response.isValid) {
          throw new Error('Invalid referral code');
        }
      } catch (error) {
        console.error('Error:', error);
        navigate('/404');
      }
    };

    if (referralCode) {
      checkReferralCode();
    }
  }, [referralCode, navigate]);

  const showSuccess = (message) => {
    snackbarRef.current.showSnackbar({ message, severity: 'success' });
  };

  const handleFormSubmit = () => {
    setFormSubmitted(true);
  };

  if (loading) {
    return <GenericLoader label="Signing up..." />;
  }

  return (
    <>
      <Helmet>
        <title> Login | Wundamental </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {fullName ? `Hi, ${fullName.split(' ')[0]},` : 'Hi,'}
              <br />
              Let's get you started!
            </Typography>
            <img
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
          <StyledContent>
            {!formSubmitted ? (
              <>
                <Typography variant="h4" gutterBottom>
                  {isGoogleSignup
                    ? 'Complete your sign up'
                    : 'Sign up to Wundamental'}
                </Typography>

                <SignupForm
                  onFormSubmit={handleFormSubmit}
                  userData={{ email, fullName, firebaseUid, referralCode }}
                  setLoading={setLoading}
                />
              </>
            ) : (
              <>
                <EmailSigninConfirm
                  title="Thank you for signing up!"
                  body="We have received your registration. An email with further instructions will be sent to you shortly."
                  onSuccess={() =>
                    showSuccess('Email sign-in link sent successfully')
                  }
                  onFailure={() =>
                    setErrorMessage(
                      'Failed to send e-mail link. Please try again later.'
                    )
                  }
                />
              </>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>

      <GenericSnackbar ref={snackbarRef} />
    </>
  );
}
