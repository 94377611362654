import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Box, Alert } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { apiRequest } from '../../api/api';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import PromptDialog from '../dialog/PromptDialog';

const WundaAudioPlayer = forwardRef(
  (
    {
      audioFile,
      isSharedView,
      demoForAll,
      onSuccess,
      onError,
      onDelete,
      sessionId,
      actions,
    },
    ref
  ) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordingFileUrl, setRecordingFileUrl] = useState(null);

    const loadAudioFile = useCallback(async () => {
      if (!audioFile?._id) return;

      try {
        const response = await apiRequest(
          'GET',
          `/audio/download/${audioFile._id}`
        );
        setRecordingFileUrl(response.url);
      } catch (error) {
        console.error('Error getting signed URL:', error);
        onError?.('Error loading audio file');
      }
    }, [audioFile?._id, onError]);

    useEffect(() => {
      if (audioFile?._id) {
        loadAudioFile();
      }
    }, [audioFile?._id, loadAudioFile]);

    const handleDeleteConfirm = () => {
      if (demoForAll) {
        onError('Cannot delete audio from demo session');
        return;
      }
      setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
      setOpenDeleteDialog(false);
    };

    const handleDeleteConfirmDialog = async () => {
      setOpenDeleteDialog(false);
      if (!audioFile?._id) return;

      setIsLoading(true);
      try {
        await apiRequest('DELETE', `audio/${audioFile._id}`);
        onSuccess('Successfully deleted a recording!');
        logEvent(analytics, 'delete_audio', {
          audioId: audioFile._id,
          sessionId,
          method: 'session_detail',
        });
        onDelete();
      } catch (error) {
        console.error(`Error deleting recording ${audioFile._id}`, error);
        onError(`Error deleting a recording! ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      handleDeleteConfirm,
      loadAudioFile,
    }));

    // Log view event when audio file is loaded
    useEffect(() => {
      if (audioFile && recordingFileUrl && sessionId) {
        logEvent(analytics, 'view_audio', {
          audioId: audioFile._id,
          sessionId,
          method: 'session_detail',
        });
      }
    }, [audioFile, recordingFileUrl, sessionId]);

    if (!audioFile) {
      return (
        <Card sx={{ mb: 2 }}>
          <Alert severity="warning">Audio recording has been deleted</Alert>
        </Card>
      );
    }

    return (
      <>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            avatar={
              <VolumeUpIcon
                sx={{
                  fontSize: 40,
                  color: (theme) =>
                    audioFile ? 'inherit' : theme.palette.grey[500],
                }}
              />
            }
            action={!isSharedView && actions}
            title="Listen to the audio recording"
            subheader={audioFile.originalFileName}
          />
          <CardContent>
            <Box
              sx={{
                overflow: 'hidden',
                width: '100%',
                position: 'relative',
                minHeight: '80px',
              }}
            >
              {recordingFileUrl ? (
                <iframe
                  src={recordingFileUrl}
                  style={{
                    height: '100%',
                    width: '100%',
                    border: 'none',
                    minHeight: '80px',
                  }}
                  title="Audio Player"
                />
              ) : (
                <Alert severity="error">Error loading audio file</Alert>
              )}
            </Box>
          </CardContent>
        </Card>

        <PromptDialog
          open={openDeleteDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this audio recording? You cannot undo this action."
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirmDialog}
          isLoading={isLoading}
        />
      </>
    );
  }
);

WundaAudioPlayer.propTypes = {
  audioFile: PropTypes.shape({
    _id: PropTypes.string,
    originalFileName: PropTypes.string,
  }),
  isSharedView: PropTypes.bool,
  demoForAll: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func,
  sessionId: PropTypes.string,
  actions: PropTypes.node,
};

WundaAudioPlayer.displayName = 'WundaAudioPlayer';

export default WundaAudioPlayer;
