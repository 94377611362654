import React, { useState, useCallback } from 'react';
import { logEvent } from 'firebase/analytics';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Tooltip,
  Rating,
  Grid,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EventIcon from '@mui/icons-material/Event';
import StarIcon from '@mui/icons-material/Star';
import { useTheme, styled } from '@mui/material/styles';
import Iconify from '../iconify';
import { analytics } from '../../firebase/firebase';
import DownloadInsightsFormatDialog from './DownloadInsightsFormatDialog';
import PromptDialog from '../dialog/PromptDialog';
import StatusProgressLabel from './StatusProgressLabel';
import WundaIconButton from './WundaIconButton';
import ShareInsightsReportButton from './ShareInsightsReportButton';
import WundaButton from './WundaButton';
import { apiRequest } from '../../api/api';
import {
  renderWithClickableTimestamps,
  findClosestDialogue,
} from '../../utils/timestampUtils';
import WundaTranscriptPreview from './WundaTranscriptPreview';

const TitleTypography = styled(Typography)(({ theme }) => ({
  variant: 'h3',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  marginBottom: '0.35em',
  wordBreak: 'break-word',
}));

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  variant: 'h4',
  color: theme.palette.text.secondary,
  marginBottom: '0.3em',
  wordBreak: 'break-word',
}));

function InsightsReportCard({
  index,
  report,
  roleDialogue = [],
  onSuccess,
  onFailure,
  onDataRefresh,
  handleShareStatusChange = () => {},
  handleReportUpdate = () => {},
  demoForAll = false,
  isSharedView = false,
  onTimeSelect = () => {},
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentReport, setCurrentReport] = useState(report);
  const [showDownloadInsightsReportModal, setShowDownloadInsightsReportModal] =
    React.useState(false);
  const [downloadInsightsReportLoading, setDownloadInsightsReportLoading] =
    React.useState(false);
  const [openInsightsDeleteDialog, setOpenInsightsDeleteDialog] =
    useState(false);
  const [openInsightsRetryDialog, setOpenInsightsRetryDialog] = useState(false);
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);
  const [insightsReportId, setInsightsReportId] = useState(null);

  const defaultRating = (currentReport.ratings &&
    currentReport.ratings.find((rating) => rating.role === 'nurturer')) || {
    rating: 0,
  };
  const [ratingValue, setRatingValue] = useState(defaultRating.rating);
  const [ratingHover, setRatingHover] = React.useState(-1);

  const ratingLabels = {
    1: 'Poor',
    2: 'Below Average',
    3: 'Average',
    4: 'Good',
    5: 'Excellent',
  };

  const getRatingLabelText = (ratingValue) => ratingLabels[ratingValue] || '';

  const handleSaveRating = useCallback(
    async (event, rating) => {
      event.preventDefault();
      setRatingValue(rating);
      const data = {
        rating,
        role: 'nurturer',
      };

      await apiRequest('POST', `/insights-report/rating/${currentReport._id}`, {
        data,
      })
        .then((response) => {
          logEvent(analytics, 'rate_insights_report', {
            reportId: response.report._id,
            rating,
            method: 'insights_report_card',
          });
          onSuccess('share', `Thank you! Your rating has been saved!`);
        })
        .catch((error) =>
          onFailure(
            'rating',
            `Error saving the rating! ${error.response?.data?.message || error.message}`
          )
        );
    },
    [onFailure, onSuccess, currentReport._id]
  );

  const handleDownloadInsightsReport = async (format) => {
    try {
      let response;
      let downloadUrl;
      let fileExtension;

      if (format === 'pdf') {
        response = await apiRequest(
          'GET',
          `/insights-report/download/pdf/${insightsReportId}`,
          {
            responseType: 'blob',
          }
        );
        downloadUrl = URL.createObjectURL(response);
        fileExtension = 'pdf';
      } else {
        response = await apiRequest(
          'GET',
          `/insights-report/download/${insightsReportId}`
        );
        downloadUrl = response.url;
        fileExtension = 'docx';
      }

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `report_${insightsReportId}.${fileExtension}`;
      document.body.appendChild(link);
      logEvent(analytics, 'download_insights_report', {
        reportId: insightsReportId,
        format,
        method: 'insights_report_card',
      });
      link.click();

      if (format === 'pdf') {
        URL.revokeObjectURL(downloadUrl); // Cleanup for blob URL
      }

      link.remove();
      onSuccess(
        'download',
        `Successfully downloaded the report as ${fileExtension.toUpperCase()}!`
      );
    } catch (error) {
      console.error(error);
      onFailure(
        'download',
        `Error downloading a report! ${error.response?.data?.message || error.message}`
      );
    }
  };

  const handleOpenDialoguePreviewModal = (time) => {
    if (roleDialogue) {
      const closestDialogue = findClosestDialogue(time, roleDialogue);
      setSelectedDialogue(closestDialogue);
      setDialoguePreviewOpen(true);
    }
  };

  const handleCloseDialoguePreviewModal = () => {
    setDialoguePreviewOpen(false);
  };

  const handleDeleteInsightsReportConfirmDialog = async () => {
    try {
      await apiRequest('DELETE', `insights-report/${insightsReportId}`);
      logEvent(analytics, 'delete_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('delete', `Successfully deleted a report!`);
    } catch (error) {
      console.error(`Error deleting report ${insightsReportId}`, error);
      onFailure(
        'delete',
        `Error deleting a report! ${error.response?.data?.message || error.message}`
      );
    } finally {
      setOpenInsightsDeleteDialog(false);
    }
  };

  const handleInsightsRetryConfirmDialog = async () => {
    try {
      await apiRequest('POST', `insights-report/retry/${insightsReportId}`);
      logEvent(analytics, 'retry_insights_report', {
        reportId: insightsReportId,
        method: 'insights_report_card',
      });
      onSuccess('retry', 'Successfully triggered retry of the report!');
    } catch (error) {
      console.error(`Error retrying report ${insightsReportId}`, error);
      onFailure(
        'retry',
        `Error retrying a report! ${error.response?.data?.message || error.message}`
      );
    } finally {
      setOpenInsightsRetryDialog(false);
    }
  };

  const handleNurturerReportStatusChange = useCallback(
    (newStatus, reportId) => {
      setCurrentReport((r) => {
        if (r._id === reportId) {
          return { ...r, status: newStatus };
        }
        return r;
      });
      handleReportUpdate({ ...currentReport, status: newStatus });
    },
    [currentReport, handleReportUpdate]
  );

  const handleInsightsRetryConfirm = (id) => {
    setInsightsReportId(id);
    setOpenInsightsRetryDialog(true);
  };

  const handleInsightsRetryCancel = () => {
    setOpenInsightsRetryDialog(false);
  };

  const openDownloadInsightsReportDialog = (reportId) => {
    setInsightsReportId(reportId);
    setShowDownloadInsightsReportModal(true);
  };

  const handleDownloadInsightsReportCancel = () => {
    setShowDownloadInsightsReportModal(false);
  };

  const handleDownloadInsightsReportConfirm = async (format) => {
    setDownloadInsightsReportLoading(true);
    await handleDownloadInsightsReport(format);
    setDownloadInsightsReportLoading(false);
    setShowDownloadInsightsReportModal(false);
  };

  const handleShareInsightsReportConfirm = async (
    email,
    expiresInDays,
    chatEnabled = false
  ) => {
    try {
      let response;
      if (email) {
        // Email share flow
        response = await apiRequest(
          'POST',
          `/insights-report/share/${currentReport._id}/email`,
          {
            data: {
              email,
              expiresInDays,
              chatEnabled,
            },
          }
        );
        onSuccess('share', 'Successfully shared the report!');
      } else {
        // Link share flow
        response = await apiRequest(
          'POST',
          `/insights-report/share/${currentReport._id}/link`,
          {
            data: {
              expiresInDays,
              chatEnabled,
            },
          }
        );
        onSuccess('share', 'Successfully generated share link!');
      }
      // Update current report and notify parent
      const updatedReport = {
        ...currentReport,
        shareHistory: response.shareHistory,
        shareToken: response.shareToken,
      };
      setCurrentReport(updatedReport);
      handleReportUpdate(updatedReport);
      handleShareStatusChange();
    } catch (error) {
      console.error('Error sharing report:', error);
      onFailure('share', `Error sharing report! ${error.message}`);
    }
  };

  const handleDeleteInsightsReportConfirm = (reportId) => {
    setInsightsReportId(reportId);
    setOpenInsightsDeleteDialog(true);
  };

  const handleDeleteInsightsReportCancel = () => {
    setOpenInsightsDeleteDialog(false);
  };

  function formatReportContentJSON(content) {
    return (
      <Box>
        <Typography variant="body1">{content.description}</Typography>
        {content.sections.map((section, index) => (
          <Box key={index} mt={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="rgba(0, 0, 0, 0.04)"
            >
              <Typography variant="subtitle1">{`${index + 1}. ${section.sectionTitle}`}</Typography>
              {section.items.some(
                (item) => item.label.toLowerCase() === 'evidence'
              ) && (
                <Box display="flex" alignItems="center" ml={1}>
                  {section.items.find(
                    (item) => item.label.toLowerCase() === 'evidence'
                  ).value === 'true' ? (
                    <Iconify icon={'openmoji:check-mark'} />
                  ) : (
                    <Iconify icon={'openmoji:cross-mark'} />
                  )}
                </Box>
              )}
            </Box>
            <Box mt={0}>
              <List sx={{ pt: 0 }}>
                {section.items.map((item, itemIndex) => (
                  <ListItem key={itemIndex} sx={{ pt: 0 }}>
                    <ListItemText
                      primary={
                        item.label.toLowerCase() !== 'evidence' && (
                          <Typography variant="body1">{`${item.label}: ${item.value}`}</Typography>
                        )
                      }
                      secondary={
                        <Typography variant="body2" component="div">
                          {typeof item.details === 'string'
                            ? renderWithClickableTimestamps(
                                item.details,
                                handleOpenDialoguePreviewModal
                              )
                            : item.details}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        ))}
        {content.summary && (
          <Box mt={2}>
            <Typography variant="subtitle1">{content.summary.label}</Typography>
            <Typography variant="body1">{content.summary.value}</Typography>
          </Box>
        )}
        {content.additionalInfo.map((info, index) => (
          <Box key={index} mt={2}>
            <Typography variant="subtitle1">{info.label}</Typography>
            <Typography variant="body1">{info.value}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  function formatReportContent(content) {
    // If the content doesn't contain any bullet points, return it directly
    if (!content.split('\n').some((line) => line.trim().startsWith('-'))) {
      return (
        <Typography variant="body2" color="text.secondary">
          {renderWithClickableTimestamps(
            content,
            handleOpenDialoguePreviewModal
          )}
        </Typography>
      );
    }

    // Split the content by new lines to identify categories and items
    const sections = content.split('\n').filter((line) => line.trim() !== '');

    // Initialize an array to hold JSX elements and an object to hold the current category
    const formattedContent = [];
    let currentCategory = { title: '', items: [] };

    sections.forEach((section, index) => {
      if (!section.trim().startsWith('-')) {
        if (currentCategory.items.length > 0) {
          formattedContent.push(currentCategory);
        }
        let title = section.trim();
        title = title.replace(/[#*]/g, '').trim();

        // Extract timestamp if present (e.g., "(0m:00s - 2m:00s)")
        const timeMatch = title.match(/\(([\d\w:]+\s*-\s*[\d\w:]+|[\d\w:]+)\)/);
        const titleWithoutTime = timeMatch
          ? title.replace(timeMatch[0], '').trim()
          : title;

        // Check if colon is part of a timestamp pattern (e.g., "5m:07s" or "12:30")
        const hasTimestampColon = /\d+[:]\d+/.test(titleWithoutTime);

        // If colon is part of timestamp, use full title; otherwise split on first colon
        const finalTitle = hasTimestampColon
          ? titleWithoutTime
          : titleWithoutTime.split(':')[0];
        const subtitle = hasTimestampColon
          ? ''
          : titleWithoutTime.includes(':')
            ? titleWithoutTime.split(':').slice(1).join(':').trim()
            : '';

        currentCategory = {
          title: finalTitle,
          subtitle: subtitle,
          timestamp: timeMatch ? timeMatch[1] : null,
          items: [],
        };
      } else {
        const itemContent = section.trim().substring(1).trim();
        currentCategory.items.push(itemContent);
      }
      if (index === sections.length - 1 && currentCategory.items.length > 0) {
        formattedContent.push(currentCategory);
      }
    });

    const listJSX = formattedContent.map((category, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <TitleTypography component="div">
          {category.title}
          {category.timestamp && (
            <Typography
              component="span"
              variant="body2"
              sx={{ ml: 1, color: 'text.secondary' }}
            >
              {renderWithClickableTimestamps(
                `(${category.timestamp})`,
                handleOpenDialoguePreviewModal
              )}
            </Typography>
          )}
        </TitleTypography>
        {category.subtitle && (
          <SubTitleTypography>{category.subtitle}</SubTitleTypography>
        )}
        <ul style={{ margin: 0, paddingLeft: '20px' }}>
          {category.items.map((item, itemIndex) => (
            <li key={itemIndex}>
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                {renderWithClickableTimestamps(
                  item,
                  handleOpenDialoguePreviewModal
                )}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    ));

    return <div>{listJSX}</div>;
  }

  // Add new state for confirmation dialog
  const [confirmNewLinkOpen, setConfirmNewLinkOpen] = useState(false);
  const [pendingExpirationDays, setPendingExpirationDays] = useState(null);

  // Add handlers for the confirmation dialog
  const handleNewLinkConfirm = () => {
    if (pendingExpirationDays !== null) {
      handleShareInsightsReportConfirm(null, pendingExpirationDays).then(() => {
        setConfirmNewLinkOpen(false);
        setPendingExpirationDays(null);
      });
    }
  };

  const handleNewLinkCancel = () => {
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
  };

  return (
    <Container component="main" sx={{ px: 0 }}>
      <Card key={index} sx={{ mb: 2 }}>
        <CardContent>
          <Box key={index} sx={{ mb: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} sm={isMobile ? 8 : 6}>
                <Box display="flex" justifyContent="left">
                  <Typography
                    variant="h6"
                    color="text.primary"
                    className="fs-mask"
                    noWrap
                  >
                    {`${currentReport.sessionName}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={isMobile ? 4 : 6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={0.5}
                >
                  {currentReport.status === 'COMPLETED' && (
                    <>
                      {!isSharedView && (
                        <>
                          <ShareInsightsReportButton
                            report={currentReport}
                            onFailure={onFailure}
                            onShareStatusChange={handleShareStatusChange}
                            onReportUpdate={(updatedReport) => {
                              setCurrentReport(updatedReport);
                              handleReportUpdate(updatedReport);
                            }}
                          />
                          <WundaIconButton
                            onClick={
                              demoForAll
                                ? null
                                : () =>
                                    handleDeleteInsightsReportConfirm(
                                      currentReport._id
                                    )
                            }
                            tooltip={
                              demoForAll
                                ? 'Remove this demo report (disabled)'
                                : 'Delete'
                            }
                            size="small"
                            icon="eva:trash-2-outline"
                            disabled={demoForAll}
                          />
                        </>
                      )}
                      <WundaIconButton
                        onClick={() =>
                          openDownloadInsightsReportDialog(currentReport._id)
                        }
                        tooltip="Download"
                        size="small"
                        icon="eva:download-outline"
                      />
                    </>
                  )}
                  {currentReport.status === 'FAILED' && !isSharedView && (
                    <WundaIconButton
                      onClick={() =>
                        handleInsightsRetryConfirm(currentReport._id)
                      }
                      tooltip="Retry"
                      size="small"
                      icon="eva:refresh-outline"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EventIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  <Tooltip title="Generated at">
                    <Typography variant="body2" color="text.secondary">
                      {currentReport.createdAt
                        ? new Date(currentReport.createdAt).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: false,
                            }
                          )
                        : ''}
                    </Typography>
                  </Tooltip>
                </Box>
                {currentReport.nurturerName && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="fs-mask"
                  >
                    Coach: {currentReport.nurturerName}
                  </Typography>
                )}
                {currentReport.flourisherName && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="fs-mask"
                  >
                    Coachee: {currentReport.flourisherName}
                  </Typography>
                )}
              </Box>
              {currentReport.status !== 'COMPLETED' && (
                <Box display="flex" alignItems="center" gap={1}>
                  <StatusProgressLabel
                    key={`${currentReport._id}-${currentReport.status}-${currentReport.lastError ? 'err' : 'ok'}`}
                    status={currentReport.status}
                    url={`insights-report/status/${currentReport._id}`}
                    onStatusChange={handleNurturerReportStatusChange}
                    onReady={onDataRefresh}
                    pollingInterval={15}
                    showError={
                      currentReport.status === 'FAILED' &&
                      currentReport.lastError
                        ? currentReport.lastError
                        : null
                    }
                  />
                  {(currentReport.status === 'IN_PROGRESS' ||
                    currentReport.status === 'PENDING') &&
                    !isSharedView && (
                      <WundaIconButton
                        onClick={() =>
                          handleDeleteInsightsReportConfirm(currentReport._id)
                        }
                        tooltip="Cancel Report"
                        size="small"
                        icon="eva:close-circle-outline"
                      />
                    )}
                </Box>
              )}
            </Box>
            {currentReport.status === 'COMPLETED' &&
              currentReport.sections.map((section, sectionIndex) => (
                <Box key={sectionIndex} sx={{ mt: 2 }}>
                  <Typography variant="h4" color="text.primary">
                    {section.heading}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                    className="fs-mask"
                  >
                    {currentReport.templateFormat === 'json'
                      ? formatReportContentJSON(section.jsonContent)
                      : formatReportContent(section.content)}
                  </Typography>
                </Box>
              ))}
            {currentReport.status === 'COMPLETED' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
                {!demoForAll && !isSharedView && (
                  <>
                    <Typography variant="body2">Rate these insights</Typography>
                    <Rating
                      name="hover-feedback"
                      value={ratingValue}
                      precision={1}
                      getLabelText={getRatingLabelText}
                      onChange={handleSaveRating}
                      onChangeActive={(event, newHover) => {
                        setRatingHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                      sx={{ color: theme.palette.primary.light }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ ml: 2 }}
                      color={theme.palette.primary.main}
                    >
                      {ratingValue !== null
                        ? ratingLabels[
                            ratingHover !== -1 ? ratingHover : ratingValue
                          ]
                        : ratingLabels[ratingValue]}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <DownloadInsightsFormatDialog
        open={showDownloadInsightsReportModal}
        isLoading={downloadInsightsReportLoading}
        onCancel={handleDownloadInsightsReportCancel}
        onConfirm={handleDownloadInsightsReportConfirm}
        onSuccess={(message) => onSuccess('download', message)}
      />

      <PromptDialog
        open={openInsightsDeleteDialog}
        title="Confirm Deletion"
        message="Are you sure you want to delete this insights report?"
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onCancel={handleDeleteInsightsReportCancel}
        onConfirm={handleDeleteInsightsReportConfirmDialog}
      />

      <PromptDialog
        open={openInsightsRetryDialog}
        title="Confirm Retry"
        message="Are you sure you want to retry generating this report?"
        cancelButtonLabel="Never mind"
        confirmButtonLabel="Yes"
        onCancel={handleInsightsRetryCancel}
        onConfirm={handleInsightsRetryConfirmDialog}
      />

      <WundaTranscriptPreview
        open={dialoguePreviewOpen}
        onClose={handleCloseDialoguePreviewModal}
        onGoToTranscript={() => {
          handleCloseDialoguePreviewModal();
          onTimeSelect(selectedDialogue);
        }}
        selectedDialogue={selectedDialogue}
        roleDialogue={roleDialogue}
        onTimeSelect={onTimeSelect}
      />

      {/* Add confirmation dialog */}
      <Dialog open={confirmNewLinkOpen} onClose={handleNewLinkCancel}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to generate a new link? The current share link
            will become unusable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <WundaButton
            onClick={handleNewLinkCancel}
            color="primary"
            variant="outlined"
          >
            No
          </WundaButton>
          <WundaButton
            onClick={handleNewLinkConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Yes
          </WundaButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

InsightsReportCard.propTypes = {
  index: PropTypes.number,
  report: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    shareToken: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        token: PropTypes.string,
        expiresAt: PropTypes.string,
        chatEnabled: PropTypes.bool,
        isPermanent: PropTypes.bool,
      }),
    ]),
    // ... other props
  }).isRequired,
  roleDialogue: PropTypes.array,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onDataRefresh: PropTypes.func.isRequired,
  handleShareStatusChange: PropTypes.func,
  handleReportUpdate: PropTypes.func,
  demoForAll: PropTypes.bool,
  isSharedView: PropTypes.bool,
  onTimeSelect: PropTypes.func,
};

export default InsightsReportCard;
