import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Box, Typography, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WundaButton from '../../../components/wundamental/WundaButton';
import GenericSnackbar from '../../../components/snackbar/GenericSnackbar';
import Iconify from '../../../components/iconify/Iconify';
import { apiRequest } from '../../../api/api';

const ConsentSignForm = ({ consentData }) => {
  const theme = useTheme();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [providerName, setProviderName] = useState(
    consentData?.signed && consentData?.providerName
      ? consentData?.providerName
      : ''
  );
  const [readyToSign, setReadyToSign] = useState(false);
  const [isSigned, setIsSigned] = useState(consentData?.signed || false);
  const [recallName, setRecallName] = useState('');
  const [signedAt, setSignedAt] = useState(
    consentData?.signedAt
      ? new Date(consentData?.signedAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
      : null
  );
  const isRecallUrl =
    location.pathname === `/consent/sign/${consentData.consentUid}`;

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleSigning = async (event) => {
    event.preventDefault();
    try {
      const response = await apiRequest(
        'POST',
        `/consent/sign/${consentData.consentUid}`,
        {
          data: { providerName },
        }
      );
      showSuccess(
        `Consent signed successfully! You may leave this page and use the original link any time to view or recall your consent.`
      );
      setIsSigned(true);
      setSignedAt(
        new Date(response.signedAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
      );
    } catch (error) {
      setErrorMessage(
        `Error signing consent. ${error.response?.data?.message || error.message}`
      );
    }
  };

  const handleRecall = async (event) => {
    event.preventDefault();
    if (recallName !== providerName) {
      setErrorMessage(
        'The name entered does not match the name used to sign the consent.'
      );
      return;
    }
    try {
      await apiRequest('POST', `/consent/recall/${consentData.consentUid}`, {
        data: { providerName: recallName },
      });
      showSuccess('Consent recalled successfully!');
      setIsSigned(false);
      setSignedAt(null);
      setRecallName('');
      setProviderName(
        consentData?.signed && consentData?.providerName
          ? consentData?.providerName
          : ''
      );
    } catch (error) {
      setErrorMessage(
        `Error recalling consent. ${error.response?.data?.message || error.message}`
      );
    }
  };

  useEffect(() => {
    if (providerName === consentData.providerName) {
      setReadyToSign(true);
    } else {
      setReadyToSign(false);
    }
  }, [providerName, consentData.providerName]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Box display="flex" alignItems="flex-start" sx={{ mt: 1 }}>
          <Iconify
            icon={'mdi:sign'}
            width={50}
            height={50}
            color={theme.palette.text.primary}
          />
          {isSigned ? (
            <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
              <Typography variant="caption" component="h2" gutterBottom>
                Signed on {signedAt}
              </Typography>
              <Typography variant="subtitle2" component="h2" gutterBottom>
                {consentData.providerName}
              </Typography>
            </Box>
          ) : (
            <Typography
              variant="caption"
              component="h2"
              gutterBottom
              sx={{ ml: 2 }}
            >
              To sign this consent, please type your full name exactly as it
              appears here: "<b>{consentData.providerName}</b>". Remember, it's
              case sensitive. Once you've done that, click the "Sign Consent"
              button.
            </Typography>
          )}
        </Box>
        {!isSigned && (
          <Box
            component="form"
            onSubmit={handleSigning}
            sx={{ p: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="providerName"
              label="Your Full Name to Sign"
              name="providerName"
              value={providerName}
              onChange={(e) => setProviderName(e.target.value)}
              autoComplete="name"
              InputProps={{
                readOnly: isSigned,
              }}
            />
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {!isSigned && (
              <WundaButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 1 }}
                disabled={!readyToSign}
              >
                Sign Consent
              </WundaButton>
            )}
          </Box>
        )}
        {isSigned && isRecallUrl && (
          <>
            <Typography
              variant="caption"
              component="h2"
              gutterBottom
              sx={{ mt: 2, mb: 0 }}
            >
              Want to recall your consent? Enter your full name exactly as it
              appears here: "<b>{consentData.providerName}</b>".
            </Typography>
            <Box
              component="form"
              onSubmit={handleRecall}
              sx={{ p: 0, width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="recallName"
                label="Your Full Name to Recall"
                name="recallName"
                value={recallName}
                onChange={(e) => setRecallName(e.target.value)}
                autoComplete="name"
              />
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <WundaButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 1 }}
              >
                Recall Consent
              </WundaButton>
            </Box>
          </>
        )}
      </Box>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

ConsentSignForm.propTypes = {
  consentData: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    consentUid: PropTypes.string.isRequired,
    signed: PropTypes.bool,
    signedAt: PropTypes.string,
    recalled: PropTypes.bool,
    recalledAt: PropTypes.string,
    // add other properties of consentData here as needed
  }).isRequired,
};

export default ConsentSignForm;
