import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Alert,
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { apiRequest } from '../../api/api';
import InsightsReportCard from './InsightsReportCard';
import InsightsIcon from '@mui/icons-material/Insights';
import SessionHeader from './SessionHeader';
import useChat from '../../hooks/useChat';
import Chat from '../chat/Chat';
import PropTypes from 'prop-types';
import WundaAudioPlayer from './WundaAudioPlayer';
import WundaTranscript from './WundaTranscript';
import GenericSnackbar from '../snackbar/GenericSnackbar';

const SharedInsightsReportView = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openCard, setOpenCard] = useState(null);
  const [showListenCard, setShowListenCard] = useState(false);
  const [showTranscriptCard, setShowTranscriptCard] = useState(false);
  const [showInsightsCard, setShowInsightsCard] = useState(false);
  const [showChatCard, setShowChatCard] = useState(false);
  const {
    messages,
    isLoading: chatIsLoading,
    sendMessage,
    retryLastMessage,
    stopResponse,
  } = useChat(report?._id, token);
  const chatSectionRef = useRef(null);
  const snackbarRef = useRef();

  const handleOpenListenCard = useCallback(() => {
    setOpenCard('recording');
    window.location.hash = 'audio';
    setShowListenCard(true);
    setShowTranscriptCard(false);
    setShowInsightsCard(false);
    setShowChatCard(false);
  }, []);

  const handleOpenTranscriptCard = useCallback(() => {
    setOpenCard('transcript');
    window.location.hash = 'transcript';
    setShowListenCard(false);
    setShowTranscriptCard(true);
    setShowInsightsCard(false);
    setShowChatCard(false);
  }, []);

  const handleOpenInsightsCard = useCallback(() => {
    setOpenCard('insights');
    window.location.hash = 'insights';
    setShowListenCard(false);
    setShowTranscriptCard(false);
    setShowInsightsCard(true);
    setShowChatCard(false);
  }, []);

  const handleOpenChatCard = useCallback(() => {
    setOpenCard('chat');
    window.location.hash = 'chat';
    setShowListenCard(false);
    setShowTranscriptCard(false);
    setShowInsightsCard(false);
    setShowChatCard(true);
    setTimeout(() => {
      chatSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, []);

  const showSuccess = (message) => {
    snackbarRef.current?.showSnackbar({
      message,
      severity: 'success',
    });
  };

  useEffect(() => {
    const fetchSharedReport = async () => {
      try {
        const response = await apiRequest(
          'GET',
          `/insights-report/shared/${token}`
        );
        if (response && response.insightsReports?.length > 0) {
          setReport(response.insightsReports[0]);
          setSessionData(response);
        } else {
          throw new Error('No report data received');
        }
      } catch (error) {
        console.error('Error fetching shared report:', error);
        setError(error.message || 'Failed to load shared report');
        if (error.response?.status === 404) {
          navigate('/404');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchSharedReport();
    }
  }, [token, navigate]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (!hash && !openCard) {
        handleOpenInsightsCard();
      } else if (hash) {
        switch (hash) {
          case 'audio':
            handleOpenListenCard();
            break;
          case 'transcript':
            handleOpenTranscriptCard();
            break;
          case 'insights':
            handleOpenInsightsCard();
            break;
          case 'chat':
            handleOpenChatCard();
            break;
          default:
            handleOpenInsightsCard();
        }
      }
    };

    // Handle initial hash
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [
    handleOpenListenCard,
    handleOpenTranscriptCard,
    handleOpenInsightsCard,
    handleOpenChatCard,
    openCard,
  ]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 5 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!sessionData || !report) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h6">No session data available</Typography>
      </Box>
    );
  }

  return (
    <Container component="main">
      <SessionHeader
        sessionData={sessionData}
        isSharedView={true}
        showShareControls={false}
        handleOpenListenCard={handleOpenListenCard}
        handleOpenTranscriptCard={handleOpenTranscriptCard}
        handleOpenInsightsCard={handleOpenInsightsCard}
        handleOpenChatCard={handleOpenChatCard}
        openCard={openCard}
        language={sessionData.language}
        showCards={true}
      />

      {showListenCard && sessionData.audioFile && (
        <WundaAudioPlayer
          audioFile={sessionData.audioFile}
          isSharedView={true}
          onSuccess={() => {}}
          onError={(msg) => setError(msg)}
          sessionId={sessionData._id}
        />
      )}

      {showTranscriptCard &&
        sessionData.transcript &&
        sessionData.shareToken?.transcriptEnabled && (
          <WundaTranscript
            transcript={sessionData.transcript}
            sessionData={sessionData}
            isSharedView={true}
            sessionName={sessionData.name}
            onSuccess={(msg) => showSuccess(msg.message || msg)}
            onError={(msg) => setError(msg)}
          />
        )}

      {showInsightsCard && (
        <Card sx={{ mt: 2 }}>
          <Box sx={{ p: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <InsightsIcon sx={{ fontSize: 40, mr: 2 }} />
              <Typography variant="h6">Insights Report</Typography>
            </Box>
            {report && (
              <InsightsReportCard
                report={report}
                roleDialogue={sessionData.transcript?.roleDialogue || []}
                isSharedView={true}
                showSpeakingRatio={true}
                onSuccess={() => {}}
                onFailure={() => {}}
                onDataRefresh={() => {}}
              />
            )}
          </Box>
        </Card>
      )}

      {showChatCard && report?.shareToken?.chatEnabled && (
        <Card sx={{ mt: 3 }} ref={chatSectionRef}>
          <CardContent
            sx={{
              height: '600px',
              p: { xs: 1, sm: 2 },
              '& .MuiInputBase-root': {
                mx: { xs: 0, sm: 1 },
              },
              '& .message-content': {
                maxWidth: '100%',
                overflowX: 'auto',
              },
              '& pre': {
                maxWidth: '100%',
                overflowX: 'auto',
              },
            }}
          >
            <Chat
              sessionId={report._id}
              messages={messages}
              isLoading={chatIsLoading}
              sendMessage={sendMessage}
              retryLastMessage={retryLastMessage}
              stopResponse={stopResponse}
              roleDialogue={sessionData?.transcript?.roleDialogue || []}
              onTimeSelect={() => {}}
              preventAutoScroll={true}
            />
          </CardContent>
        </Card>
      )}

      <GenericSnackbar ref={snackbarRef} />
    </Container>
  );
};

SharedInsightsReportView.propTypes = {
  sessionData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    audioFile: PropTypes.object,
    transcript: PropTypes.object,
    shareToken: PropTypes.shape({
      token: PropTypes.string,
      expiresAt: PropTypes.string,
      chatEnabled: PropTypes.bool,
      audioEnabled: PropTypes.bool,
      transcriptEnabled: PropTypes.bool,
    }),
    insightsReports: PropTypes.arrayOf(PropTypes.object),
  }),
  report: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    shareToken: PropTypes.shape({
      token: PropTypes.string,
      expiresAt: PropTypes.string,
      chatEnabled: PropTypes.bool,
      audioEnabled: PropTypes.bool,
      transcriptEnabled: PropTypes.bool,
    }),
  }),
};

export default SharedInsightsReportView;
