import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Button,
  Drawer,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Divider,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

ThemeFilter.propTypes = {
  themes: PropTypes.array,
  selectedTheme: PropTypes.string,
  onThemeChange: PropTypes.func,
};

export default function ThemeFilter({ themes, selectedTheme, onThemeChange }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    onThemeChange(event.target.value);
    handleClose();
  };

  return (
    <StyledRoot>
      <Typography
        variant="subtitle2"
        component={Button}
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={handleOpen}
        sx={{ textTransform: 'none' }}
      >
        Filter by Theme
      </Typography>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filter by Theme
          </Typography>
          <IconButton onClick={handleClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <RadioGroup value={selectedTheme} onChange={handleChange}>
              <FormControlLabel
                value=""
                control={<Radio />}
                label={<Typography variant="body2">All Themes</Typography>}
              />
              {themes.map((theme) => (
                <FormControlLabel
                  key={theme._id}
                  value={theme._id}
                  control={<Radio />}
                  label={<Typography variant="body2">{theme.name}</Typography>}
                />
              ))}
            </RadioGroup>
          </Stack>
        </Scrollbar>
      </Drawer>
    </StyledRoot>
  );
}
