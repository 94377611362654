import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import StopIcon from '@mui/icons-material/Stop';
import ReactMarkdown from 'react-markdown';
import { renderWithClickableTimestamps } from '../../utils/timestampUtils';

const ChatMessage = ({
  message,
  isAI,
  onRetry = undefined,
  messageIndex,
  onTimeClick,
}) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(message.content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const renderChildren = (children) => {
    if (!children) return '';
    if (typeof children === 'string') return children;
    if (Array.isArray(children)) {
      return children
        .map((child) =>
          typeof child === 'string'
            ? child
            : child?.props?.children
              ? renderChildren(child.props.children)
              : ''
        )
        .join('');
    }
    return children?.props?.children
      ? renderChildren(children.props.children)
      : '';
  };

  // Custom components for markdown rendering
  const components = {
    p: ({ children }) => (
      <Typography variant="body2" component="div" gutterBottom>
        {renderWithClickableTimestamps(renderChildren(children), onTimeClick)}
      </Typography>
    ),
    li: ({ children }) => (
      <Typography variant="body2" component="li">
        {renderWithClickableTimestamps(renderChildren(children), onTimeClick)}
      </Typography>
    ),
    text: ({ children }) =>
      renderWithClickableTimestamps(renderChildren(children), onTimeClick),
  };

  const messageStyle = {
    p: 2,
    backgroundColor: message.isError
      ? theme.palette.error.light
      : isAI
        ? 'grey.50'
        : 'primary.light',
    borderRadius: '12px',
    borderTopLeftRadius: isAI ? '4px' : '12px',
    borderTopRightRadius: isAI ? '12px' : '4px',
    color: message.isError
      ? theme.palette.error.contrastText
      : isAI
        ? 'text.primary'
        : 'primary.contrastText',
    maxWidth: '95%',
    wordBreak: 'break-word',
    position: 'relative',
    '&:hover .message-actions': {
      opacity: 1,
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isAI ? 'flex-start' : 'flex-end',
        mb: 2,
      }}
    >
      <Box sx={messageStyle}>
        {isAI ? (
          <Box
            sx={{
              p: 1,
              position: 'relative',
            }}
          >
            <ReactMarkdown components={components}>
              {message.content}
            </ReactMarkdown>
            {message.stopped && (
              <Tooltip title="Response stopped by user" placement="right">
                <StopIcon
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: -24,
                    color: 'error.main',
                    fontSize: 16,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: 'primary.contrastText',
              wordBreak: 'break-word',
            }}
          >
            {renderWithClickableTimestamps(message.content, onTimeClick)}
          </Typography>
        )}

        {/* Message Actions */}
        <Box
          className="message-actions"
          sx={{
            position: 'absolute',
            top: -32,
            right: isAI ? 0 : 'auto',
            left: isAI ? 'auto' : 0,
            display: 'flex',
            gap: 0.5,
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
            backgroundColor: 'background.paper',
            borderRadius: '6px',
            boxShadow: 1,
            p: 0.5,
          }}
        >
          {isAI && !message.isError && (
            <>
              {onRetry && (
                <IconButton
                  size="small"
                  onClick={() => onRetry(messageIndex)}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'primary.main',
                      backgroundColor: 'action.hover',
                    },
                  }}
                  title="Regenerate response"
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={handleCopy}
                sx={{
                  color: copied ? 'success.main' : 'text.secondary',
                  '&:hover': {
                    color: copied ? 'success.main' : 'primary.main',
                    backgroundColor: 'action.hover',
                  },
                }}
                title="Copy message"
              >
                {copied ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <ContentCopyIcon fontSize="small" />
                )}
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string.isRequired,
    stopped: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
  isAI: PropTypes.bool.isRequired,
  onRetry: PropTypes.func,
  messageIndex: PropTypes.number.isRequired,
  onTimeClick: PropTypes.func,
};

export default ChatMessage;
