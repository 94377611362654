import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Container, Box } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WundaButton from '../components/wundamental/WundaButton';
import { healthcheck } from '../api/api';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function ConnectionNotAvailable() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleRetry = async () => {
    setIsLoading(true);
    try {
      const response = await healthcheck();
      if (response) {
        navigate('/');
      } else {
        // Refresh current page
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Offline | Wundamental</title>
      </Helmet>

      <Container>
        <StyledContent
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" paragraph>
            Oops, we have lost the connection!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Maybe it's on a coffee break, or perhaps it's just playing hide and
            seek. Let's wait for it to come back!
          </Typography>

          <Box
            component={WifiOffIcon}
            sx={{ fontSize: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            color={theme.palette.secondary.main}
          />

          <WundaButton
            onClick={handleRetry}
            sx={{ px: 4 }}
            size="large"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? 'Retrying...' : 'Retry'}
          </WundaButton>
        </StyledContent>
      </Container>
    </>
  );
}
