import { useEffect, useContext, useCallback, useState, useRef } from 'react';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Error as ErrorIcon } from '@mui/icons-material';
import { AuthContext } from '../../providers/AuthProvider';
import GenericLoader from '../../components/loader/GenericLoader';
import WundaButton from '../../components/wundamental/WundaButton';
import { getLocalStorageItem } from '../../utils';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function AuthCallback() {
  const navigate = useNavigate();
  const { forceLogout, resetAuthInProgress, handleUserSignup } =
    useContext(AuthContext);
  const navigateRef = useRef(navigate);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signInHandled, setSignInHandled] = useState(false);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const handleSignIn = useCallback(async () => {
    if (signInHandled) return;
    setLoading(true);
    setSignInHandled(true);
    const auth = getAuth();

    if (!isSignInWithEmailLink(auth, window.location.href)) {
      setLoading(false);
      return;
    }

    let email = getLocalStorageItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    const name = getLocalStorageItem('fullNameForSignIn');

    try {
      localStorage.setItem('emailAuthInProgress', 'true');
      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );
      const { user } = result;
      if (user) {
        const userData = {
          email: user.email,
          displayName: name,
          uid: user.uid,
          authMethod: 'emailLink',
        };
        try {
          await handleUserSignup(userData);
          window.localStorage.removeItem('emailForSignIn');
          window.localStorage.removeItem('fullNameForSignIn');
          window.localStorage.removeItem('referralCode');
        } catch (error) {
          console.error('Error handling user signup:', error);
          forceLogout();
        }

        navigateRef.current('/home');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      switch (error.code) {
        case 'auth/invalid-action-code':
          setError(
            'The sign-in link is invalid. Please check if you used the correct sign-in link.'
          );
          break;
        case 'auth/user-disabled':
          setError('Your account has been disabled. Please contact support.');
          break;
        case 'auth/user-not-found':
          setError(
            'No account found with this email. Please check your email or sign up.'
          );
          break;
        case 'auth/wrong-password':
          setError('Invalid password. Please try again.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email format. Please check your email.');
          break;
        case 'auth/email-already-in-use':
          setError(
            'This email is already in use. Please use a different email.'
          );
          break;
        case 'auth/weak-password':
          setError(
            'Your password is too weak. Please choose a stronger password.'
          );
          break;
        default:
          setError(
            `An error occurred while signing in. Please try again. ${error.message}`
          );
      }
      // Sign out the user if an error occurred, and sign-in process didn't complete entirely
      forceLogout();
    } finally {
      resetAuthInProgress();
      setLoading(false);
    }
  }, [signInHandled, forceLogout, resetAuthInProgress, handleUserSignup]);

  useEffect(() => {
    handleSignIn();
  }, [handleSignIn]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <GenericLoader />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Container>
          <StyledContent
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" paragraph>
              Oops, something went wrong!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>{error}</Typography>

            <Box
              component={ErrorIcon}
              sx={{ fontSize: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />

            <WundaButton
              onClick={() => navigate('/login')}
              size="large"
              variant="contained"
            >
              Go to login page
            </WundaButton>
          </StyledContent>
        </Container>
      </>
    );
  }

  // Return null or a default state when there's no error and not loading
  return null;
}
