import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import WundaButton from '../wundamental/WundaButton';

const FileUploadStyled = ({
  onFileAccepted,
  labelText,
  acceptedTypes = {},
  uploadedFile,
  setUploadedFile,
}) => {
  const fileInputRef = useRef();
  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileAccepted(acceptedFiles[0]);
      setUploadedFile(acceptedFiles[0]);
      fileInputRef.current.value = '';
    },
    [onFileAccepted, setUploadedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    multiple: false,
  });

  const handleButtonClick = (event) => {
    event.stopPropagation();
    fileInputRef.current.value = '';
    document.getElementById('fileUploadInput').click();
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();
    setUploadedFile(null);
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <input {...getInputProps()} id="fileUploadInput" ref={fileInputRef} />
      <CloudUploadIcon sx={{ fontSize: '48px', color: '#ccc' }} />
      <Typography>
        {isDragActive
          ? 'Drop the file here...'
          : labelText ||
            'Drag and drop the file here, or click to select a file'}
      </Typography>
      <WundaButton
        variant="contained"
        sx={{ mt: 2, px: 4 }}
        onClick={handleButtonClick}
      >
        Start
      </WundaButton>
      {uploadedFile && (
        <Box sx={{ mt: 2 }}>
          <Typography className="fs-mask">
            Uploaded file: {uploadedFile.name}
          </Typography>
          <WundaButton
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={handleRemoveClick}
          >
            Remove
          </WundaButton>
        </Box>
      )}
    </Box>
  );
};

FileUploadStyled.propTypes = {
  onFileAccepted: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  acceptedTypes: PropTypes.object,
  uploadedFile: PropTypes.object,
  setUploadedFile: PropTypes.func.isRequired,
};

export default FileUploadStyled;
