import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { logEvent } from 'firebase/analytics';
import { Link, Stack, TextField, Alert, Typography, Box } from '@mui/material';
import { sendSignInLinkToEmail } from 'firebase/auth';
import PropTypes from 'prop-types';
import { analytics, auth } from '../../firebase/firebase';
import Iconify from '../../components/iconify';
import WundaButton from '../../components/wundamental/WundaButton';
import TermsOfServiceCheckbox from '../../components/wundamental/TermsOfService';
import {
  AuthContext,
  signInWithGoogleRedirect,
} from '../../providers/AuthProvider';

import { logMessage } from '../../utils/logger';

export default function SignupForm({ onFormSubmit, userData, setLoading }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { resetAuthInProgress, forceLogout, redirectResult, handleUserSignup } =
    useContext(AuthContext);
  const [error, setError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState(userData?.email || '');
  const [fullName, setFullName] = useState(userData?.fullName || '');
  const [firebaseUid, setFirebaseUid] = useState(userData?.firebaseUid || null);
  const [success, setSuccess] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [consented, setConsented] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isDefaultView, setIsDefaultView] = useState(true);
  const [isEmailSignup, setIsEmailSignup] = useState(false);
  const [isGoogleSignup, setIsGoogleSignup] = useState(false);

  const handleErrors = (error) => {
    console.error('Error:', error);
    setError(error.response?.data?.message || error.message);
  };

  useEffect(() => {
    const signupUser = async () => {
      if (redirectResult && redirectResult.user && !userData.email) {
        try {
          setLoading(true);
          await handleUserSignup(redirectResult.user);
          logEvent(analytics, 'sign_up', { method: 'google' });
          logMessage(
            'DEBUG',
            'handleSignup(). Successfully created new user with Google account.'
          );
          navigate('/home');
        } catch (error) {
          await forceLogout();
          handleErrors(error);
        } finally {
          setLoading(false);
        }
      }
      resetAuthInProgress();
    };

    signupUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    navigate('/home', { replace: true });
  };

  const handleEmailSignupClick = (event) => {
    event.preventDefault();
    setIsDefaultView(false);
    setIsEmailSignup(true);
    setIsGoogleSignup(false);
  };

  const handleGoogleSignupClick = (event) => {
    event.preventDefault();
    setIsDefaultView(true);
    setIsEmailSignup(false);
  };

  const isFormValid = () => {
    if (isEmailSignup && !isEmailValid) {
      setError('Please enter a valid email');
      return false;
    }

    if (isEmailSignup && !email) {
      setError('Email is required');
      return false;
    }

    if (isEmailSignup && !fullName) {
      setError('Full Name is required');
      return false;
    }

    if (!acceptedTerms) {
      setError('Please accept the terms and privacy policy');
      return false;
    }

    if (!consented) {
      setError(
        'Please confirm that all participants have consented to your recordings'
      );
      return false;
    }

    return true;
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!isFormValid()) {
      return;
    }

    setLoading(true);
    let _googleUser;
    let _isGoogleSignup = isGoogleSignup;
    let _fullName = fullName;
    let _email = email;
    let _firebaseUid = firebaseUid;
    const _referralCode = userData.referralCode;

    try {
      if (!_isGoogleSignup && !isEmailSignup) {
        _isGoogleSignup = true;
        if (!redirectResult || !redirectResult.user) {
          localStorage.setItem('googleAuthInProgress', 'true');
          if (_referralCode) {
            localStorage.setItem('referralCode', _referralCode);
          }
          await signInWithGoogleRedirect();
          setLoading(false);
          return;
        }
        _googleUser = redirectResult.user;
        _fullName = _googleUser.displayName;
        _email = _googleUser.email;
        _firebaseUid = _googleUser.uid;
        setFirebaseUid(_firebaseUid);
        setFullName(_fullName);
        setEmail(_email);
        setIsGoogleSignup(true);
      }
    } catch (error) {
      handleErrors(error);
      return;
    } finally {
      setLoading(false);
    }

    if (!_email || !_fullName) {
      setLoading(false);
      return; // Don't submit the form if the required fields are not filled
    }

    if (_isGoogleSignup) {
      if (!_googleUser && !_firebaseUid) {
        setError('Error signing up with Google account. Please try again.');
        return;
      }
      try {
        localStorage.setItem('googleAuthInProgress', 'true');
        await handleUserSignup(_googleUser);
      } catch (error) {
        handleErrors(error);
        return;
      } finally {
        setLoading(false);
      }
    }

    if (isEmailSignup) {
      try {
        localStorage.setItem('emailAuthInProgress', 'true');
        const actionCodeSettings = {
          url: `${window.location.origin}/auth/callback/`,
          handleCodeInApp: true,
        };
        // Send Firebase sign in link
        sendSignInLinkToEmail(auth, _email, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem('emailForSignIn', _email);
            window.localStorage.setItem('fullNameForSignIn', _fullName);
            window.localStorage.setItem('referralCode', _referralCode);
          })
          .catch((error) => {
            handleErrors(error);
          });

        logMessage(
          'DEBUG',
          'handleSignup(). Sent user signup link successfully.'
        );
        setSuccess(true);
        logEvent(analytics, 'sign_up', { method: 'email' });
        onFormSubmit(); // Call the onFormSubmit function from the parent component
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput)); // Validate email on change
  };

  return (
    <>
      {success ? (
        <>
          <Typography variant="h6" gutterBottom>
            Check your inbox for the login link.
          </Typography>
          <Typography variant="body2">
            We have sent an email to your registered email address with a link
            to sign in.
          </Typography>
        </>
      ) : (
        <form onSubmit={handleSignup}>
          {error && <Alert severity="error">{error}</Alert>}
          {/* <Alert severity="info">{`isGoogle: ${isGoogleSignup}, isEmail: ${isEmailSignup}, isDefault: ${isDefaultView}\n|\nAccount: ${userData.fullName}`}</Alert> */}
          <Stack spacing={3} sx={{ mb: 3, mt: 3 }}>
            {isEmailSignup && (
              <>
                <TextField
                  required
                  error={formSubmitted && !fullName}
                  helperText={
                    formSubmitted && !fullName ? 'Full Name is required' : ''
                  }
                  name="name"
                  value={fullName}
                  label="Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  autoFocus
                />
                <TextField
                  required
                  error={!isEmailValid}
                  helperText={
                    formSubmitted &&
                    (!isEmailValid
                      ? 'Please enter a valid email'
                      : !email
                        ? 'Email is required'
                        : '')
                  }
                  name="email"
                  value={email}
                  label="Email address"
                  type="email"
                  onChange={handleEmailChange}
                  onBlur={handleEmailChange}
                />
              </>
            )}
            {userData?.referralCode && (
              <TextField
                disabled
                name="referralCode"
                value={userData.referralCode}
                label="Referral Code"
                helperText="You are signing up using a referral link."
              />
            )}
            <TermsOfServiceCheckbox
              onAcceptanceChange={(accepted) => setAcceptedTerms(accepted)}
              onConsentChange={(consented) => setConsented(consented)}
              accepted={acceptedTerms}
              consented={consented}
              error={formSubmitted && !acceptedTerms}
            />
          </Stack>
          {isDefaultView && (
            <>
              <Box
                mt={2}
                mb={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <WundaButton
                  startIcon={<Iconify icon="logos:google-icon" />}
                  variant="contained"
                  color="primary"
                  onClick={handleSignup}
                  size="large"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: theme.palette.text.primary,
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  Continue with Google
                </WundaButton>
              </Box>
              <Box mt={1} display="flex" justifyContent="center">
                <Typography variant="body1" align="center">
                  or
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <WundaButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={handleEmailSignupClick}
                >
                  Sign Up with Email
                </WundaButton>
              </Box>
            </>
          )}
          {isEmailSignup && !isDefaultView && (
            <>
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <WundaButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={handleSignup}
                >
                  Sign Up
                </WundaButton>
              </Box>
              <Box my={2}>
                <Typography variant="body1" align="center">
                  or
                </Typography>
              </Box>
            </>
          )}
          {isEmailSignup && !isDefaultView && (
            <>
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <WundaButton
                  startIcon={<Iconify icon="logos:google-icon" />}
                  variant="contained"
                  color="primary"
                  onClick={handleGoogleSignupClick}
                  size="large"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: theme.palette.text.primary,
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  Sign Up with Google
                </WundaButton>
              </Box>
            </>
          )}
          {isGoogleSignup && !isDefaultView && (
            <>
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <WundaButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={handleEmailSignupClick}
                >
                  Sign Up with Email
                </WundaButton>
              </Box>
            </>
          )}
          {!isGoogleSignup && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 2 }}
            >
              <Link
                variant="subtitle2"
                component="button"
                onClick={handleClick}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Already have an account? Log in
              </Link>
            </Stack>
          )}
        </form>
      )}
    </>
  );
}

SignupForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    firebaseUid: PropTypes.string,
    referralCode: PropTypes.string,
  }),
  setLoading: PropTypes.func.isRequired,
};
