import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import WundaButton from '../../../components/wundamental/WundaButton';
import GenericSnackbar from '../../../components/snackbar/GenericSnackbar';
import { apiRequest } from '../../../api/api';

const ConsentCard = ({ consentData, isSigning = false }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const handleShareEmail = async () => {
    try {
      setShareDialogOpen(false);
      await apiRequest('POST', `/consent/share/${consentData._id}`);
      showSuccess(
        `Successfully shared the consent form with ${consentData.providerEmail}!`
      );
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Error sharing the consent form! ${error.response?.data?.message || error.message}`
      );
    }
  };

  const handleShareClickOpen = () => {
    setShareDialogOpen(true);
  };

  const handleShareClose = () => {
    setShareDialogOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(consentData.consentLink);
    showSuccess('Link copied to clipboard');
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  return (
    <>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%' },
          mx: 'auto',
          display: 'flex',
        }}
      >
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Card>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', // Align the text to the left
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="subtitle2">
                  Consent requested by <b>{consentData.requesterName}</b>
                </Typography>
                <Typography variant="body1">
                  {new Date(consentData.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </Typography>
              </Box>
              <Typography variant="body1" mt={2}>
                Dear <b>{consentData.providerName}</b>,
              </Typography>
              <Typography variant="body1" mt={2}>
                As part of our commitment to providing you with the highest
                quality services, we continuously seek innovative ways to
                enhance your experience. To this end, we are introducing an
                advanced feature that involves a deep analysis of session
                recordings. The purpose of this initiative is to deepen your
                insights from the session, resulting in more transformative
                outcomes for you. It also allows us to tailor our support to
                your specific needs, ultimately contributing to your personal
                and professional growth in the most effective manner possible.
              </Typography>
              <Typography variant="body1">
                It entails, with your consent, the recording of our future
                sessions, anonymization of these recordings, and subsequent
                analysis using Artificial Intelligence (AI) technologies in
                partnership with Wundamental.ai.
              </Typography>
              <Typography variant="h6" mt={2}>
                1. Purpose of Recording and AI Analysis:
              </Typography>
              <Typography variant="body1">
                The recordings of our sessions will be used to:
              </Typography>
              <ul>
                <Box pl={4}>
                  <li>
                    <Typography variant="body1">
                      Analyze communication patterns, language, and emotional
                      cues.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Generate insights that will help both participant and
                      facilitator gain deeper understanding of the challenges
                      and opportunities at hand. This collaborative approach
                      enhances the effectiveness of our sessions, providing
                      significant benefits for everyone involved.
                    </Typography>
                  </li>
                </Box>
              </ul>
              <Typography variant="h6" mt={2}>
                2. Anonymization and Data Protection:
              </Typography>
              <Typography variant="body1">
                All recordings will be anonymized, removing any personal
                identifiers before analysis. We adhere to strict data protection
                policies to ensure the confidentiality and security of your
                information.
              </Typography>
              <Typography variant="h6" mt={2}>
                3. Consent for Recording and Analysis:
              </Typography>
              <Typography variant="body1">
                By signing this consent form, you authorize{' '}
                {consentData.requesterName} to record the sessions, anonymize
                these recordings with the Wundamental.ai platform, and perform
                AI analysis for the purposes outlined above. You understand
                that: Your participation is entirely voluntary. You have the
                right to withdraw your consent at any time without affecting
                your future experiences. All recordings will be stored securely,
                and access will be strictly limited to authorized personnel
                only.
              </Typography>
              <Typography variant="h6" mt={2}>
                4. Withdrawal of Consent:
              </Typography>
              <Typography variant="body1">
                You may withdraw your consent at any time by providing written
                notice to the facilitator or the organization. Upon receipt of
                such notice, no further recordings will be made, and any
                existing recordings will not be used for AI analysis. You may
                request the deletion of all your data from our databases. Upon
                receipt of such notice, all your personal data will be deleted,
                without the possibility to retrieve it in the future.
              </Typography>
              <Typography variant="h6" mt={2}>
                5. Benefits to You:
              </Typography>
              <Typography variant="body1">
                The use of AI analysis is intended to enhance the effectiveness
                of our sessions by providing deeper insights and a more
                personalized strategy tailored to each individual's unique
                journey. This approach aims to accelerate the achievement of
                goals and maximize the potential of all participants.
              </Typography>
              <Typography variant="h6" mt={2}>
                6. Questions/Concerns:
              </Typography>
              <Typography variant="body1">
                If you have any questions or concerns about the recording
                process, data protection practices, or your rights, please do
                not hesitate to contact us at{' '}
                <a href={`mailto:${consentData.contactInfo}`}>
                  {consentData.contactInfo}
                </a>
                .
              </Typography>
            </Box>
            {!isSigning && !consentData.signed && (
              <>
                <Box sx={{ justifyContent: 'center', mt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    mt={3}
                    sx={{ textAlign: 'center' }}
                  >
                    Invite the consent provider to sign this contract
                  </Typography>
                  {consentData.consentLink && (
                    <Box
                      component="form"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <TextField
                        sx={{
                          maxWidth: '600px',
                        }}
                        fullWidth
                        value={consentData.consentLink}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={copyToClipboard}>
                              <ContentCopyIcon />
                            </IconButton>
                          ),
                          style: { fontSize: '0.8rem' },
                        }}
                        helperText="Copy this link and share it with consent provider so they can sign this consent contract."
                        FormHelperTextProps={{ style: { marginTop: '4px' } }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center', // Center the button
                    mt: 2,
                  }}
                >
                  <WundaButton
                    variant="contained"
                    onClick={handleShareClickOpen}
                  >
                    Send by email
                  </WundaButton>
                </Box>

                <Dialog open={shareDialogOpen} onClose={handleShareClose}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to send an email with invite to sign
                      this contract to the consent provider{' '}
                      {consentData.providerName} ({consentData.providerEmail})?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <WundaButton
                      onClick={handleShareClose}
                      variant="outlined"
                      color="primary"
                    >
                      No
                    </WundaButton>
                    <WundaButton
                      onClick={handleShareEmail}
                      variant="contained"
                      color="primary"
                      autoFocus
                    >
                      Yes
                    </WundaButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </CardContent>
        </Card>
      </Box>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

ConsentCard.propTypes = {
  consentData: PropTypes.object.isRequired,
  isSigning: PropTypes.bool,
};

export default ConsentCard;
