import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  Divider,
  Collapse,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../../providers/AuthProvider';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { isFeatureEnabled, currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const filteredData = data.filter((item) => {
    // Check for feature flag
    if (item.feature && !isFeatureEnabled(item.feature)) {
      return false;
    }

    // Check for roles
    if (
      item.roles &&
      (!currentUser?.DBuser?.roles ||
        !item.roles.some((role) => currentUser.DBuser.roles.includes(role)))
    ) {
      return false;
    }

    return true;
  });

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {filteredData.map((item, index) => (
          <React.Fragment key={item.title}>
            {item.children ? (
              <>
                <StyledNavItem
                  onClick={() => setOpen((prev) => !prev)}
                  sx={{
                    '&:hover': {
                      color: 'text.primary',
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      typography: 'body2',
                      sx: { textTransform: 'capitalize' },
                    }}
                  />
                  <ExpandMoreIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      transition: 'transform 0.2s',
                    }}
                  />
                </StyledNavItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div">
                    {item.children
                      .filter((child) => {
                        if (child.feature && !isFeatureEnabled(child.feature))
                          return false;
                        if (
                          child.roles &&
                          (!currentUser?.DBuser?.roles ||
                            !child.roles.some((role) =>
                              currentUser.DBuser.roles.includes(role)
                            ))
                        )
                          return false;
                        return true;
                      })
                      .map((child, childIndex) => (
                        <StyledNavItem
                          key={`${index}-${childIndex}`}
                          component={RouterLink}
                          to={child.path}
                          sx={{
                            pl: 4,
                            '&.active': {
                              color: 'text.primary',
                              bgcolor: 'action.selected',
                              fontWeight: 'fontWeightBold',
                            },
                          }}
                        >
                          <StyledNavItemIcon>{child.icon}</StyledNavItemIcon>
                          <ListItemText
                            primary={child.title}
                            primaryTypographyProps={{
                              typography: 'body2',
                              sx: { textTransform: 'capitalize' },
                            }}
                          />
                        </StyledNavItem>
                      ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <NavItem item={item} />
            )}
            {item.dividerAfter && <Divider sx={{ mb: 2 }} />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  isChild: PropTypes.bool,
};

function NavItem({ item, isChild }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        pl: isChild ? 4 : 0, // Add left padding if it's a child item
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={
          isChild ? <Typography variant="body2">{title}</Typography> : title
        }
      />

      {info && info}
    </StyledNavItem>
  );
}
