import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  Grid,
  Button,
  Box,
  Stack,
  Tooltip,
} from '@mui/material';
import Iconify from '../../components/iconify';
import GenericSnackbar from '../../components/snackbar/GenericSnackbar';
import themeService from '../../services/themeService';

export default function ThemesPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [themes, setThemes] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    if (state?.snackbar) {
      setSnackbar(state.snackbar);
      navigate(location.pathname, { replace: true });
    }
  }, [state, navigate]);

  useEffect(() => {
    fetchThemes();
  }, []);

  const fetchThemes = async () => {
    try {
      const fetchedThemes = await themeService.getAllThemes();
      setThemes(fetchedThemes);
    } catch (error) {
      console.error('Error fetching themes:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch themes',
        severity: 'error',
      });
    }
  };

  const handleCreateTheme = () => {
    navigate('/home/wundamental/themes/new');
  };

  const handleEditTheme = (theme) => {
    navigate(`/home/wundamental/themes/${theme._id}`);
  };

  const handleThemeClick = (theme) => {
    handleEditTheme(theme);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Themes</Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleCreateTheme}
        >
          New Theme
        </Button>
      </Stack>

      <Grid container spacing={3}>
        {themes.map((theme) => (
          <Grid key={theme._id} item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 3,
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: (theme) => theme.shadows[10],
                },
              }}
              onClick={() => handleThemeClick(theme)}
            >
              <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                  <Tooltip
                    title={
                      theme.public
                        ? 'Public theme - visible to all users'
                        : 'Private theme - restricted to selected users'
                    }
                  >
                    <Iconify
                      icon={theme.public ? 'mdi:earth' : 'mdi:lock'}
                      width={16}
                      height={16}
                      sx={{ color: 'text.secondary' }}
                    />
                  </Tooltip>
                </Box>
                <Stack spacing={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {theme.icon && <Iconify icon={theme.icon} width={24} />}
                    <Typography variant="h6">{theme.name}</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {theme.description}
                  </Typography>
                  {theme.parentThemeId && (
                    <Typography variant="body2" color="text.secondary">
                      Parent Theme:{' '}
                      {themes.find((t) => t._id === theme.parentThemeId)
                        ?.name || 'Unknown'}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
}
