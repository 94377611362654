import { apiRequest } from '../api/api';

export const generateShareToken = async ({
  sessionId,
  expiresInDays,
  chatEnabled,
  audioEnabled,
  transcriptEnabled,
}) => {
  try {
    const response = await apiRequest('POST', '/session/share', {
      data: {
        sessionId,
        expiresInDays,
        chatEnabled,
        audioEnabled,
        transcriptEnabled,
      },
    });

    if (!response.shareToken) {
      throw new Error('No share token received from server');
    }

    return response;
  } catch (error) {
    throw new Error(`Failed to generate share token: ${error.message}`);
  }
};

export const verifyShareToken = async (token) => {
  try {
    const response = await apiRequest('GET', `/session/shared/${token}`);
    return response;
  } catch (error) {
    throw new Error('Invalid or expired share token');
  }
};

export const getShareLink = (token) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/session/shared/${token}`;
};

export const deleteShareToken = async (sessionId) => {
  try {
    await apiRequest('DELETE', `/session/${sessionId}/share`);
  } catch (error) {
    throw new Error(`Failed to disable session sharing: ${error.message}`);
  }
};
