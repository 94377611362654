import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Card, Typography, CardHeader, CardContent, Box } from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from '@mui/lab';
import { apiRequest } from '../../../api/api';

// ----------------------------------------------------------------------

ConsentList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function ConsentList({ title, subheader, ...other }) {
  const [consents, setConsents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchConsents = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const data = await apiRequest('GET', '/consent/list');
      setConsents(data);
    } catch (error) {
      setErrorMessage(
        `Failed to fetch consents. ${error.response?.data?.message || error}`
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConsents();
  }, [fetchConsents]);

  if (errorMessage) {
    return <Typography color="error">{errorMessage}</Typography>;
  }

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {consents.map((consent, index) => (
            <ConsentItem
              key={consent._id}
              consent={consent}
              isLast={index === consents.length - 1}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

ConsentItem.propTypes = {
  isLast: PropTypes.bool,
  consent: PropTypes.shape({
    _id: PropTypes.string,
    providerName: PropTypes.string,
    providerEmail: PropTypes.string,
    createdAt: PropTypes.string,
    signed: PropTypes.bool,
    signedAt: PropTypes.string,
    recalled: PropTypes.bool,
    recalledAt: PropTypes.string,
  }),
};

function ConsentItem({ consent, isLast }) {
  const {
    _id,
    providerName,
    providerEmail,
    createdAt,
    signed,
    signedAt,
    recalled,
    recalledAt,
  } = consent;
  const createdAtDate = new Date(createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const signedAtDate = signedAt
    ? new Date(signedAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : null;
  const recalledAtDate = recalledAt
    ? new Date(recalledAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : null;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={signed ? (recalledAt ? 'secondary' : 'primary') : 'error'}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Link to={`/home/consent/${_id}`}>
            <Typography variant="subtitle2">{providerName}</Typography>
          </Link>
          <Typography variant="body2">{providerEmail}</Typography>
          <Typography variant="caption">{createdAtDate}</Typography>
        </Box>
        {recalled && recalledAt ? (
          <Typography variant="caption">Recalled: {recalledAtDate}</Typography>
        ) : signed ? (
          <Typography variant="caption">Signed: {signedAtDate}</Typography>
        ) : (
          <Typography variant="caption">Not signed</Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}
