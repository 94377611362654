import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logEvent } from 'firebase/analytics';
import {
  Box,
  Container,
  Typography,
  Paper,
  Stack,
  Avatar,
  Grid,
  TextField,
  IconButton,
  Link,
} from '@mui/material';
import {
  Email as EmailIcon,
  Link as LinkIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { AuthContext } from '../providers/AuthProvider';
import { analytics } from '../firebase/firebase';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import ErrorMessage from '../components/alert/ErrorMessage';
import GenericLoader from '../components/loader/GenericLoader';
import WundaShareToEmailDialog from '../components/wundamental/WundaShareToEmailDialog';
import WundaButton from '../components/wundamental/WundaButton';
import { apiRequest } from '../api/api';

const StepIcon = ({ number }) => (
  <Avatar sx={{ bgcolor: 'primary.main', color: 'white', mb: 2 }}>
    {number}
  </Avatar>
);

StepIcon.propTypes = {
  number: PropTypes.number.isRequired,
};

const ReferralPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showShareToEmailModal, setShowShareToEmailModal] = useState(false);
  const [shareToEmailLoading, setShareToEmailLoading] = React.useState(false);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [showLink, setShowLink] = useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // Get the referral link from sessionStorage when the component mounts
  useEffect(() => {
    const storedReferralLink = sessionStorage.getItem('referralLink');
    if (storedReferralLink) {
      setReferralLink(storedReferralLink);
      setLinkGenerated(true);
      setShowLink(true);
    }
  }, []);

  const generateLink = async () => {
    let _referralCode = referralCode;
    let _referralLink = referralLink;
    try {
      const response = await apiRequest('POST', `/referral`);
      _referralCode = response.referralCode;
      _referralLink = response.referralUrl;
      if (_referralCode && _referralLink) {
        setReferralCode(_referralCode);
        setReferralLink(_referralLink);
        // Store the referral link in sessionStorage
        sessionStorage.setItem('referralLink', _referralLink);
      } else {
        throw new Error('Error generating referral link');
      }
    } catch (error) {
      setErrorMessage(`Error generating referral link. ${error.message}`);
    }
  };

  const handleGenerateLink = async () => {
    setLoader(true);
    await generateLink();
    logEvent(analytics, 'generate_referral_link');
    setLinkGenerated(true);
    setShowLink(true);
    setLoader(false);
  };

  const sendToFriend = async (email, subject, body) => {
    try {
      const response = await apiRequest('POST', `/referral/send`, {
        data: { email, subject, body },
      });
      if (response.message) {
        showSuccess(response.message);
      } else {
        throw new Error('Error sending referral to email');
      }
    } catch (error) {
      setErrorMessage(
        `Error sending referral to email. ${error.response?.data?.message || error.message}`
      );
    }
  };

  const openShareToFriendDialog = () => {
    setShowShareToEmailModal(true);
  };

  const handleShareToFriendConfirm = async (email, subject, body) => {
    setShareToEmailLoading(true);
    await sendToFriend(email, subject, body);
    logEvent(analytics, 'send_referral');
    setShowShareToEmailModal(false);
    setShareToEmailLoading(false);
  };

  const handleShareToFriendCancel = () => {
    setShowShareToEmailModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    showSuccess('Link copied to clipboard');
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  return (
    <Container>
      <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
      <Box my={4}>
        {loader && <GenericLoader />}
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h6" component="h6" gutterBottom>
            GIVE 3 SESSIONS, GET 10€
          </Typography>
          <Typography variant="h3" component="h3" sx={{ mb: 3 }}>
            SHARE INSIGHTS, REFER WUNDAMENTAL
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <StepIcon number={1} />
              <Typography variant="h6" component="h3">
                Share your link
              </Typography>
              <Typography paragraph>
                Share your unique referral link with an individual, a group or
                on social media.
              </Typography>
              <Stack direction="column" spacing={2} sx={{ my: 4 }}>
                <WundaButton
                  variant="contained"
                  startIcon={<EmailIcon />}
                  onClick={openShareToFriendDialog}
                >
                  Share via Email
                </WundaButton>
                <Stack direction="column" spacing={2}>
                  <WundaButton
                    variant="contained"
                    startIcon={<LinkIcon />}
                    onClick={handleGenerateLink}
                    disabled={linkGenerated}
                  >
                    Share by Link
                  </WundaButton>
                  {showLink && (
                    <Box
                      component="form"
                      sx={{
                        mt: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <TextField
                        fullWidth
                        value={referralLink}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={copyToClipboard}>
                              <ContentCopyIcon />
                            </IconButton>
                          ),
                          style: { fontSize: '0.8rem' },
                        }}
                        helperText="Copy this link and share it with your friends to invite them."
                        FormHelperTextProps={{ style: { marginTop: '4px' } }}
                      />
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StepIcon number={2} />
              <Typography variant="h6" component="h3">
                Grow others
              </Typography>
              <Typography paragraph>
                Your link unlocks 3 session free trial of Wundamental to a
                newcomer.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StepIcon number={3} />
              <Typography variant="h6" component="h3">
                Earn credit
              </Typography>
              <Typography paragraph>
                Get a 5€ credit for every sign up with your link. Get extra 5€
                credit when they subscribe.
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="caption" display="block" sx={{ mt: 4 }}>
            By accepting this offer you agree to the{' '}
            <Link
              href="https://wundamental.ai/terms-of-service"
              target="_blank"
              rel="noopener"
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              href="https://wundamental.ai/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Paper>
      </Box>

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />

      <WundaShareToEmailDialog
        dialogProps={{
          title: 'Send to e-mail',
          bodyText: `Enter your friend's email address to share the joy of using Wundamental. Let them know how it's been beneficial for you!`,
          subjectField: true,
          subjectFieldPlaceholder: `You've Been Invited to Join Wundamental by ${currentUser?.DBuser?.firstName}!`,
          bodyField: true,
          bodyFieldPlaceholder:
            "Hey, I've been using Wundamental and I think you might like it too. Enjoy!",
          confirmPromptText:
            'Are you sure you want to send referral to {email}?',
        }}
        open={showShareToEmailModal}
        isLoading={shareToEmailLoading}
        onCancel={handleShareToFriendCancel}
        onConfirm={handleShareToFriendConfirm}
      />
    </Container>
  );
};

export default ReferralPage;
