import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
import GenericLoader from '../components/loader/GenericLoader';

export default function AcceptInvitePage() {
  const { workspaceId, userId } = useParams();
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useContext(AuthContext);

  useEffect(() => {
    const handleInviteRedirect = async () => {
      try {
        // If user is signed in
        if (currentUser?.DBuser) {
          await refreshUser();
          navigate('/home/sessions');
          return;
        }

        // If userId is provided (existing user)
        if (userId) {
          // Store invite data in sessionStorage
          sessionStorage.setItem(
            'pendingInvite',
            JSON.stringify({ workspaceId, userId })
          );
          navigate('/login');
          return;
        }

        // New user case
        sessionStorage.setItem(
          'pendingInvite',
          JSON.stringify({ workspaceId })
        );
        navigate('/signup');
      } catch (error) {
        console.error('Error handling invite:', error);
        navigate('/login');
      }
    };

    handleInviteRedirect();
  }, [currentUser, navigate, refreshUser, userId, workspaceId]);

  return <GenericLoader />;
}
