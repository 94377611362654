import { apiRequest } from '../api/api';

export const navigateToBilling = async (userId, setError) => {
  try {
    const isTestMode =
      process.env.REACT_APP_STRIPE_LIVE_MODE_ENABLED !== 'true';
    const payload = {
      userId,
      isTestMode,
    };

    const response = await apiRequest(
      'POST',
      '/payment/create-portal-session',
      { data: payload }
    );
    window.location = response.url;
  } catch (error) {
    if (setError) {
      setError({
        open: true,
        message: 'Failed to navigate to billing. Please try again.',
        severity: 'error',
      });
    }
    console.error('Error navigating to billing:', error);
  }
};
