import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Grid,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Chip,
  Box,
  Stack,
  Card,
  CardContent,
  Link,
  Alert,
} from '@mui/material';
import { Icon } from '@iconify/react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material/styles';
import themeService from '../../services/themeService';
import templateService from '../../services/templateService';
import WundaButton from '../../components/wundamental/WundaButton';
import PromptDialog from '../../components/dialog/PromptDialog';
import TextEditorDialog from '../../components/common/TextEditorDialog';
import WundaAutoComplete from '../../components/wundamental/WundaAutoComplete';
import GenericSnackbar from '../../components/snackbar/GenericSnackbar';

export default function ThemePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const muiTheme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    nurturerRole: '',
    flourisherRole: '',
    icon: '',
    systemPrompt: '',
    speakerRolePrompt: '',
    public: false,
  });
  const [availableThemes, setAvailableThemes] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedRelatedThemes, setSelectedRelatedThemes] = useState([]);
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [errors, setErrors] = useState({});
  const [iconError, setIconError] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSpeakerRolePromptEditor, setOpenSpeakerRolePromptEditor] =
    useState(false);
  const [openSystemPromptEditor, setOpenSystemPromptEditor] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [error, setError] = useState('');
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    useState(false);

  const getErrorMessage = (error) => {
    if (error.response?.data?.error) {
      return error.response.data.error;
    }
    return error.message || 'An unexpected error occurred';
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
    setTimeout(handleSnackbarClose, 6000);
  };

  const showError = (message) => {
    setError(message);
  };

  const resetError = () => {
    setError('');
  };

  useEffect(() => {
    const fetchData = async () => {
      resetError();
      try {
        const [templatesResponse, themesResponse] = await Promise.all([
          templateService.getAllTemplates(),
          themeService.getAllThemes(),
        ]);

        setAvailableTemplates(templatesResponse || []);
        setAvailableThemes((themesResponse || []).filter((t) => t._id !== id));

        if (id !== 'new') {
          const theme = await themeService.getThemeById(id);
          if (theme) {
            setFormData({
              name: theme.name || '',
              description: theme.description || '',
              icon: theme.icon || '',
              nurturerRole: theme.nurturerRole || '',
              flourisherRole: theme.flourisherRole || '',
              systemPrompt: theme.systemPrompt || '',
              speakerRolePrompt: theme.speakerRolePrompt || '',
              public: theme.public || false,
            });

            const themeTemplates = theme.templates || [];
            const initialTemplates = templatesResponse.filter((template) =>
              themeTemplates.includes(template._id)
            );
            setSelectedTemplates(initialTemplates);

            const defaultTemp = theme.defaultTemplateID
              ? templatesResponse.find((t) => t._id === theme.defaultTemplateID)
              : null;
            setDefaultTemplate(defaultTemp);

            const relatedThemeIds = theme.relatedThemes || [];
            const initialThemes = themesResponse.filter(
              (t) => relatedThemeIds.includes(t._id) && t._id !== id
            );
            setSelectedRelatedThemes(initialThemes);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        showError(getErrorMessage(error));
      }
    };

    fetchData();
    setIconError(false);
    setErrors({});
    setHasChanges(false);
  }, [id]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setHasChanges(true);
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
    if (name === 'icon') {
      setIconError(false);
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
    setHasChanges(true);
  };

  const handleSubmit = async () => {
    resetError();
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.description) errors.description = 'Description is required';
    if (!formData.icon) errors.icon = 'Icon is required';
    if (!formData.nurturerRole)
      errors.nurturerRole = 'Nurturer role is required';
    if (!formData.flourisherRole)
      errors.flourisherRole = 'Flourisher role is required';
    if (!formData.systemPrompt)
      errors.systemPrompt = 'System prompt is required';
    if (!formData.speakerRolePrompt)
      errors.speakerRolePrompt = 'Speaker role prompt is required';

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const themeData = {
      ...formData,
      templates: selectedTemplates.map((t) => t._id),
      relatedThemes: selectedRelatedThemes.map((t) => t._id),
      defaultTemplateID: defaultTemplate?._id || null,
    };

    try {
      if (id === 'new') {
        await themeService.createTheme(themeData);
        navigate('/home/wundamental/themes', {
          state: {
            snackbar: {
              open: true,
              message: `Theme "${themeData.name}" created successfully`,
              severity: 'success',
            },
          },
        });
      } else {
        await themeService.updateTheme(id, themeData);
        showSuccess(`Theme "${formData.name}" updated successfully`);
        setHasChanges(false);
      }
    } catch (error) {
      console.error('Error saving theme:', error);
      showError(getErrorMessage(error));
    }
  };

  const handleIconError = () => {
    setIconError(true);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    resetError();
    try {
      await themeService.deleteTheme(id);
      navigate('/home/wundamental/themes', {
        state: {
          snackbar: {
            open: true,
            message: `Theme "${formData.name}" deleted successfully`,
            severity: 'success',
          },
        },
      });
    } catch (error) {
      console.error('Error deleting theme:', error);
      showError(getErrorMessage(error));
      setOpenDeleteDialog(false);
    }
  };

  const handleTemplateClick = (templateId) => {
    navigate(`/home/wundamental/templates/${templateId}`);
  };

  const handleBackClick = () => {
    if (hasChanges) {
      setOpenUnsavedChangesDialog(true);
    } else {
      navigate('/home/wundamental/themes');
    }
  };

  const handleDiscardChanges = () => {
    setOpenUnsavedChangesDialog(false);
    navigate('/home/wundamental/themes');
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 5 }}>
        <Stack direction="row" alignItems="center">
          <Link
            component="button"
            variant="subtitle2"
            onClick={handleBackClick}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <NavigateNextIcon sx={{ transform: 'rotate(180deg)' }} />
            Back to Themes
          </Link>
        </Stack>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {snackbar.open && (
        <Alert
          severity={snackbar.severity}
          sx={{ mb: 2 }}
          onClose={handleSnackbarClose}
        >
          {snackbar.message}
        </Alert>
      )}

      <Card>
        <CardContent sx={{ pt: 3, pb: 3 }}>
          <Grid container spacing={2}>
            {formData.icon && (
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  icon={formData.icon}
                  width={64}
                  height={64}
                  onError={handleIconError}
                />
              </Grid>
            )}
            <Grid item xs={formData.icon ? 10 : 12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Iconify Key"
                    name="icon"
                    value={formData.icon}
                    onChange={(e) => handleChange('icon', e.target.value)}
                    error={!!errors.icon || iconError}
                    helperText={
                      errors.icon ||
                      (iconError ? 'Invalid Iconify icon key' : '')
                    }
                    required
                    placeholder="e.g., mdi:theme-light-dark"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={(e) => handleChange('description', e.target.value)}
                multiline
                rows={3}
                error={!!errors.description}
                helperText={errors.description}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nurturer Role"
                name="nurturerRole"
                value={formData.nurturerRole}
                onChange={(e) => handleChange('nurturerRole', e.target.value)}
                error={!!errors.nurturerRole}
                helperText={errors.nurturerRole}
                required
                placeholder="e.g., mentor"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Flourisher Role"
                name="flourisherRole"
                value={formData.flourisherRole}
                onChange={(e) => handleChange('flourisherRole', e.target.value)}
                error={!!errors.flourisherRole}
                helperText={errors.flourisherRole}
                required
                placeholder="e.g., mentee"
              />
            </Grid>
            <Grid item xs={12}>
              <WundaAutoComplete
                value={defaultTemplate}
                onChange={(event, newValue) => {
                  setDefaultTemplate(newValue);
                  setHasChanges(true);
                }}
                options={availableTemplates}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                label="Default Template"
                placeholder="Select default template"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="System Prompt"
                name="systemPrompt"
                value={formData.systemPrompt}
                onChange={(e) => handleChange('systemPrompt', e.target.value)}
                onClick={() => setOpenSystemPromptEditor(true)}
                error={!!errors.systemPrompt}
                helperText={errors.systemPrompt}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Speaker Role Prompt"
                name="speakerRolePrompt"
                value={formData.speakerRolePrompt}
                onChange={(e) =>
                  handleChange('speakerRolePrompt', e.target.value)
                }
                onClick={() => setOpenSpeakerRolePromptEditor(true)}
                error={!!errors.speakerRolePrompt}
                helperText={errors.speakerRolePrompt}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Associated Templates
              </Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                {selectedTemplates.length > 0 ? (
                  <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                    {selectedTemplates.map((template) => (
                      <Chip
                        key={template._id}
                        label={template.name}
                        onClick={() => handleTemplateClick(template._id)}
                        sx={{
                          backgroundColor: muiTheme.palette.primary.main,
                          color: muiTheme.palette.primary.contrastText,
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: muiTheme.palette.primary.dark,
                          },
                        }}
                      />
                    ))}
                  </Stack>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No templates associated with this theme
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Related Themes
              </Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <WundaAutoComplete
                  multiple
                  value={selectedRelatedThemes}
                  onChange={(event, newValue) => {
                    setSelectedRelatedThemes(newValue);
                    setHasChanges(true);
                  }}
                  options={availableThemes}
                  getOptionLabel={(option) => option.name}
                  label="Related Themes"
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        key={option._id}
                      />
                    ))
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.public}
                    onChange={handleSwitchChange}
                    name="public"
                  />
                }
                label="Public Theme"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
          {id !== 'new' && (
            <WundaButton
              onClick={handleDeleteClick}
              color="error"
              variant="outlined"
            >
              Delete Theme
            </WundaButton>
          )}
          <Box
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {hasChanges && (
                <Chip
                  label="Unsaved changes"
                  color="warning"
                  size="small"
                  sx={{ mr: 1 }}
                />
              )}
              <WundaButton
                onClick={handleSubmit}
                variant="contained"
                disabled={!hasChanges || Object.keys(errors).length > 0}
              >
                {id === 'new' ? 'Create Theme' : 'Save Theme'}
              </WundaButton>
            </Box>
          </Box>
        </Box>
      </Card>

      <PromptDialog
        open={openDeleteDialog}
        title="Delete Theme"
        message="Are you sure you want to delete this theme? This action cannot be undone."
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        confirmButtonLabel="Delete"
      />

      <TextEditorDialog
        open={openSpeakerRolePromptEditor}
        onClose={() => setOpenSpeakerRolePromptEditor(false)}
        onSave={(value) => {
          handleChange('speakerRolePrompt', value);
          setOpenSpeakerRolePromptEditor(false);
        }}
        title="Edit Speaker Role Prompt"
        initialValue={formData.speakerRolePrompt || ''}
      />

      <TextEditorDialog
        open={openSystemPromptEditor}
        onClose={() => setOpenSystemPromptEditor(false)}
        onSave={(value) => {
          handleChange('systemPrompt', value);
          setOpenSystemPromptEditor(false);
        }}
        title="Edit System Prompt"
        initialValue={formData.systemPrompt || ''}
      />

      <PromptDialog
        open={openUnsavedChangesDialog}
        title="Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to leave? Your changes will be lost."
        onCancel={() => setOpenUnsavedChangesDialog(false)}
        onConfirm={handleDiscardChanges}
        confirmButtonLabel="Discard Changes"
      />

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </Container>
  );
}
