import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Link,
  Box,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import { Icon as IconifyIcon } from '@iconify/react';
import templateService from '../../services/templateService';
import promptLabService from '../../services/promptLabService';
import { apiRequest } from '../../api/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WundaButton from '../../components/wundamental/WundaButton';
import GenericSnackbar from '../../components/snackbar/GenericSnackbar';

export default function WundaPromptLab() {
  const location = useLocation();
  const navigate = useNavigate();
  const { templateId, templateSectionId } = useParams();
  const [sessions, setSessions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templateSections, setTemplateSections] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [userPrompt, setUserPrompt] = useState('');
  const [temperature, setTemperature] = useState(0.5);
  const [maxTokens, setMaxTokens] = useState(200);
  const [aiResponse, setAiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [originalPath] = useState(
    location.state?.returnTo || '/home/wundamental/templates'
  );

  const handleBack = () => {
    navigate(originalPath);
  };

  // Load sessions
  useEffect(() => {
    const loadSessions = async () => {
      try {
        const response = await apiRequest('GET', '/session/list');
        if (!response || !Array.isArray(response)) {
          console.error('Invalid sessions response:', response);
          setSnackbar({
            open: true,
            message: 'Failed to load sessions: Invalid response',
            severity: 'error',
          });
          return;
        }
        setSessions(response);
      } catch (error) {
        console.error('Failed to load sessions:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load sessions',
          severity: 'error',
        });
      }
    };
    loadSessions();
  }, []);

  // Load templates
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const response = await templateService.getAllTemplates();

        if (!response || !Array.isArray(response)) {
          console.error('Invalid template response:', response);
          setSnackbar({
            open: true,
            message: 'Failed to load templates: Invalid response',
            severity: 'error',
          });
          return;
        }

        setTemplates(response);

        if (templateId) {
          const template = response.find(
            (t) => String(t?._id) === String(templateId)
          );
          if (template) {
            setSelectedTemplate(template);
          }
        }
      } catch (error) {
        console.error('Failed to load templates:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load templates',
          severity: 'error',
        });
      }
    };
    loadTemplates();
  }, [templateId]);

  // Load sections when template changes
  useEffect(() => {
    const loadSections = async () => {
      if (!selectedTemplate) {
        setTemplateSections([]);
        setSelectedSection(null);
        return;
      }

      try {
        const templateData = await templateService.getTemplateById(
          selectedTemplate._id
        );
        const sections = templateData?.templateSections || [];
        const validSections = sections.filter(
          (section) => section && section._id && section.heading
        );
        setTemplateSections(validSections);

        // Only set section on first load or when URL params change
        if (templateSectionId) {
          const section = validSections.find(
            (s) => String(s?._id) === String(templateSectionId)
          );
          if (section) {
            setSelectedSection(section);
            setUserPrompt(section.userPrompt || '');
            setTemperature(section.temperature || 0.2);
            setMaxTokens(section.max_tokens || 200);
          }
        } else if (
          !templateSectionId &&
          validSections.length > 0 &&
          !selectedSection
        ) {
          // Only select first section on initial load when no section is specified
          const firstSection = validSections[0];
          setSelectedSection(firstSection);
          setUserPrompt(firstSection.userPrompt || '');
          setTemperature(firstSection.temperature || 0.2);
          setMaxTokens(firstSection.max_tokens || 200);
        }
      } catch (error) {
        console.error('Failed to load template sections:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load template sections',
          severity: 'error',
        });
      }
    };

    loadSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, templateSectionId]);

  const handleTemplateChange = (newTemplate) => {
    setSelectedTemplate(newTemplate);
    setSelectedSection(null);
    if (newTemplate) {
      navigate(`/home/wundamental/prompt-lab/${newTemplate._id}`);
    } else {
      navigate('/home/wundamental/prompt-lab');
    }
  };

  const handleSectionChange = (newSection) => {
    setSelectedSection(newSection);
    if (selectedTemplate && newSection) {
      navigate(
        `/home/wundamental/prompt-lab/${selectedTemplate._id}/${newSection._id}`
      );
      setUserPrompt(newSection.userPrompt || '');
      setTemperature(newSection.temperature || 0.2);
      setMaxTokens(newSection.max_tokens || 200);
    } else if (selectedTemplate) {
      navigate(`/home/wundamental/prompt-lab/${selectedTemplate._id}`);
    }
  };

  const handleTestPrompt = async () => {
    if (
      !selectedSession ||
      !selectedTemplate ||
      !selectedSection ||
      !userPrompt.trim()
    ) {
      setSnackbar({
        open: true,
        message:
          'Please select session, template, section and enter prompt text',
        severity: 'warning',
      });
      return;
    }

    setIsLoading(true);
    setAiResponse('');

    try {
      const response = await promptLabService.testTemplateSection(
        selectedSession._id,
        selectedTemplate._id,
        selectedSection._id,
        userPrompt,
        {
          temperature,
          max_tokens: maxTokens,
        }
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value);
        // Split by newlines to handle multiple chunks
        const lines = text.split('\n');

        for (const line of lines) {
          if (line.trim().startsWith('data: ')) {
            const data = line.slice(5).trim(); // Remove 'data: ' prefix
            if (data === '[DONE]') break;

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                setAiResponse((prev) => prev + parsed.content);
              }
            } catch (e) {
              console.warn('Failed to parse streaming response:', e);
            }
          }
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error testing prompt: ' + error,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const showError = (message) => {
    setSnackbar({ open: true, message, severity: 'error' });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(userPrompt);
      showSuccess('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text:', err);
      showError('Failed to copy to clipboard');
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Link
            component="button"
            onClick={handleBack}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
              typography: 'body2',
              '&:hover': { color: 'primary.main' },
            }}
          >
            <IconifyIcon
              icon="eva:arrow-ios-back-fill"
              width={16}
              style={{ marginRight: 4 }}
            />
            Back
          </Link>
        </Box>
        <Typography variant="h4">WundaPrompt Lab</Typography>
      </Box>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                value={selectedSession}
                onChange={(event, newValue) => setSelectedSession(newValue)}
                options={sessions || []}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Select Session" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={selectedTemplate}
                onChange={(event, newValue) => handleTemplateChange(newValue)}
                options={templates || []}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Template" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={selectedSection}
                onChange={(event, newValue) => handleSectionChange(newValue)}
                options={templateSections || []}
                getOptionLabel={(option) => option?.heading || ''}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.heading}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Template Section"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {selectedSection && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Model"
                    value={selectedSection.model || ''}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Temperature"
                    value={temperature}
                    onChange={(e) => setTemperature(parseFloat(e.target.value))}
                    inputProps={{
                      min: 0,
                      max: 1,
                      step: 0.1,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Tokens"
                    value={maxTokens}
                    onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'text.secondary' }}
                  >
                    User Prompt
                  </Typography>
                  <IconButton onClick={handleCopy} size="small" sx={{ ml: 1 }}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  spellCheck={false}
                  InputProps={{
                    sx: {
                      height: '100%',
                      '& textarea': {
                        height: '100% !important',
                        overflowY: 'scroll !important',
                        resize: 'none',
                      },
                    },
                  }}
                  sx={{ height: '300px' }}
                  value={userPrompt}
                  onChange={(e) => setUserPrompt(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <WundaButton onClick={handleTestPrompt} disabled={isLoading}>
                Test Prompt
              </WundaButton>
            </Grid>

            <Grid item xs={12}>
              {aiResponse && (
                <Box
                  sx={{
                    p: 2,
                    minHeight: '200px',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    bgcolor: 'background.paper',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {aiResponse}
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <GenericSnackbar
        open={{
          open: snackbar.open,
          message: snackbar.message,
          severity: snackbar.severity,
        }}
        autoHideDuration={5000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      />
    </Container>
  );
}
