import { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { analytics } from '../../../firebase/firebase';
import { AuthContext } from '../../../providers/AuthProvider';
import Iconify from '../../../components/iconify';
import WundaButton from '../../../components/wundamental/WundaButton';

export default function CreditsBalance() {
  const { currentUser, advancedBalanceMode } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const transcriptionMinutes =
    currentUser?.DBuser?.balance?.transcriptionMinutes;
  const insightCredits = currentUser?.DBuser?.balance?.insightCredits;
  const sessionCredits = currentUser?.DBuser?.balance?.sessionCredits;
  const subscriptionCredits = currentUser?.DBuser?.balance?.subscriptionCredits;
  const hasInfiniteBalance = currentUser?.DBuser?.balance?.hasInfiniteBalance;
  const canBuyCredits = currentUser?.DBuser?.canBuyCredits;
  const isSubscriptionActive = currentUser?.DBuser?.subscription?.isActive;

  const generateDisplay = (creditType, creditValue) =>
    hasInfiniteBalance ||
    creditValue === null ||
    creditValue === Number.MAX_VALUE ? (
      <Stack direction="row" alignItems="center">
        <Iconify icon="mdi:infinity" color="black" size="1em" sx={{ mr: 1 }} />
        <Typography variant="body2" color="text.primary" component="div">
          {`${creditType}`}
        </Typography>
      </Stack>
    ) : (
      <Typography
        variant="body2"
        color="text.primary"
        component="div"
        sx={{ color: creditValue === 0 ? theme.palette.error.main : 'inherit' }}
      >
        <b>{`${creditValue || 0}`}</b> {`${creditType}`}
      </Typography>
    );

  const transcriptionMinutesDisplay = generateDisplay(
    'transcription minutes',
    transcriptionMinutes
  );
  const insightCreditsDisplay = generateDisplay(
    'insight credits',
    insightCredits
  );
  const sessionCreditsDisplay = generateDisplay(
    'sessions remaining',
    sessionCredits + subscriptionCredits || 0
  );

  const navigateToCheckout = () => {
    logEvent(analytics, 'buy_click', { component: 'header' });
    navigate('/home/subscribe');
  };

  return (
    <>
      <Tooltip
        title={
          !canBuyCredits
            ? 'Current balance. Reach out to workspace owner for more.'
            : 'Current balance'
        }
      >
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100%',
          }}
        >
          {advancedBalanceMode ? (
            // Advanced balance display
            <>
              <Iconify
                icon="material-symbols-light:account-balance-wallet"
                color={theme.palette.primary.main}
                width={40}
                height={40}
              />
              <Stack
                direction="column"
                spacing={0}
                sx={{
                  gap: 1,
                  ml: 1,
                  textAlign: 'left',
                }}
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="div"
                >
                  {transcriptionMinutesDisplay}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ mt: -1 }}
                  component="div"
                >
                  {insightCreditsDisplay}
                </Typography>
              </Stack>
            </>
          ) : (
            // New balance display as per wireframe
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 1 }}
            >
              <Box>
                <Link to="/home/subscribe" style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="div"
                  >
                    {sessionCreditsDisplay}
                  </Typography>
                </Link>
              </Box>
              {canBuyCredits &&
                !isSubscriptionActive &&
                !hasInfiniteBalance && (
                  <WundaButton
                    variant="outlined"
                    size="small"
                    onClick={navigateToCheckout}
                  >
                    Buy more
                  </WundaButton>
                )}
              {currentUser.displayName && (
                <Typography
                  variant="body2"
                  color="text.primary"
                  className="fs-mask"
                >
                  {`Hello, ${currentUser.displayName.split(' ')[0]}`}
                </Typography>
              )}
            </Stack>
          )}
        </Box>
      </Tooltip>
    </>
  );
}
