import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@mui/material';
import { sendSignInLinkToEmail, getAuth } from 'firebase/auth';

const EmailSigninConfirm = ({
  title,
  body,
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  const auth = getAuth();

  const handleResendEmail = async (event) => {
    event.preventDefault();
    const actionCodeSettings = {
      url: `${window.location.origin}/auth/callback/`,
      handleCodeInApp: true,
    };

    const email = window.localStorage.getItem('emailForSignIn');

    try {
      localStorage.setItem('emailAuthInProgress', 'true');
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      onSuccess();
    } catch (error) {
      console.error('Error:', error);
      onFailure(error);
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">{body}</Typography>
      <Link
        component="button"
        variant="caption"
        onClick={handleResendEmail}
        sx={{ mt: 4, fontSize: '0.75rem' }}
      >
        I didn't receive an email. Please resend.
      </Link>
    </>
  );
};

EmailSigninConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default EmailSigninConfirm;
