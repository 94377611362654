import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { verifyShareToken } from '../../services/sharedSession';
import SessionDetailPage from '../../pages/SessionDetailPage';
import { Container, Alert, CircularProgress, Box } from '@mui/material';

const SharedSessionView = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [tabPanelValue, setTabPanelValue] = useState(0);

  useEffect(() => {
    const verifyAndLoadSession = async () => {
      try {
        const response = await verifyShareToken(token);
        if (!response || !response._id) {
          throw new Error('Invalid session data received');
        }

        // Filter themes that have reports
        const themesWithReports = response.allowedThemes?.filter((theme) =>
          theme.templates.some((template) =>
            response.insightsReports?.some(
              (report) =>
                (!report.templateID || report.templateID === template._id) &&
                report.status === 'COMPLETED'
            )
          )
        );

        // Set initial theme if there are any themes with reports
        if (themesWithReports?.length > 0) {
          const initialTheme = themesWithReports[0];
          setSelectedTheme(initialTheme);
          setSessionData({
            ...response,
            allowedThemes: themesWithReports,
          });
        } else {
          setSessionData(response);
        }
      } catch (err) {
        setError(err.message || 'Failed to load shared session');
      } finally {
        setLoading(false);
      }
    };

    verifyAndLoadSession();
  }, [token]);

  const handleThemeChange = (theme) => {
    if (!theme) return;

    setSelectedTheme(theme);
    setTabPanelValue(0);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!sessionData) {
    return <Navigate to="/" replace />;
  }

  return (
    <SessionDetailPage
      sessionId={sessionData._id}
      isSharedView={true}
      sharedToken={token}
      sessionData={{
        ...sessionData,
        shareToken: sessionData.shareToken || {
          transcriptEnabled: true,
          audioEnabled: true,
        },
      }}
      sessionName={sessionData.name}
      selectedTheme={selectedTheme}
      onThemeChange={handleThemeChange}
      tabPanelValue={tabPanelValue}
      onTabChange={(_, newValue) => setTabPanelValue(newValue)}
    />
  );
};

export default SharedSessionView;
