import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { AuthContext } from '../../../providers/AuthProvider';
import GenericSnackbar from '../../../components/snackbar/GenericSnackbar';
import WundaForm from '../../../components/wundamental/WundaForm';
import { apiRequest } from '../../../api/api';

const ConsentForm = ({ showForm, setShowForm }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const fields = [
    {
      name: 'requesterName',
      label: 'Your organization or your full name',
      type: 'text',
      rules: { required: 'This field is required' },
    },
    {
      name: 'providerName',
      label: 'Consent provider full name',
      type: 'text',
      rules: { required: 'This field is required' },
    },
    {
      name: 'providerEmail',
      label: 'Consent provider email',
      type: 'email',
      rules: { required: 'This field is required' },
      className: 'fs-mask',
    },
  ];

  const defaultValues = {
    requesterName: userData.name || '',
    address: '',
    contactInfo: '',
    providerName: '',
    providerEmail: '',
  };

  const onSubmit = async (data) => {
    try {
      const response = await apiRequest('POST', `/consent`, {
        data: { ...data },
      });
      showSuccess(`Consent link created successfully!`);
      setShowForm(false);
      navigate(`/home/consent/${response.consent._id}`);
    } catch (error) {
      setErrorMessage(
        `Error generating consent link. ${error.response?.data?.message || error.message}`
      );
    }
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  return (
    <>
      {showForm && (
        <>
          <WundaForm
            title="Request Consent for Recording and AI Analysis"
            subTitle="This form enables you to generate a consent contract link for consenting party to sign."
            fields={fields}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            submitLabel="Create New Consent Contract"
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </>
      )}

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

ConsentForm.propTypes = {
  showForm: PropTypes.bool.isRequired,
  setShowForm: PropTypes.func.isRequired,
};

export default ConsentForm;
